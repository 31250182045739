<div class="setup card-container" *ngIf="!uiSrv.isTablet">
    <div class="card-header-wrapper">
        <!-- title -->
        <h3 class="card-title"></h3>
        <!-- tab buttons -->
        <div class="card-buttons">
            <kendo-buttongroup selection="single">
                <button *ngFor="let tab of tabs" kendoButton [toggleable]="true" [selected]="selectedTab == tab.id" (click)="selectedTab = tab.id  ">
                    {{ tab.label | label : uiSrv.langPack }}
                </button>
            </kendo-buttongroup>
        </div>
        <span></span>
    </div>
    <div class="table-container" *ngIf="selectedTab && selectedTab != 'log'" >
        <uc-table #table  [customButtons] = "tableCustomButtons[selectedTab] ? tableCustomButtons[selectedTab] : [] "
                          (customButtonClick) = "showDetail(null , $event)" (dataChange) = "onGridDataChanged()"
                          [toolbarButtons] ="{new : (selectedTab != 'map') && !(selectedTab == 'site' && data.length > 0), action: true}" 
                          [columnDefs] = "gridSettings[selectedTab]['columns']" [(data)]="data" [serverSidePagination] = "true"
                          [dataSourceApiEndpoint] = "gridSettings[selectedTab]['apiUrl']"    [functionId] = "gridSettings[selectedTab]['functionId']"
                          [disabledButtons] = "tableDisabledButtons" [isLoading] = "isTableLoading"  (dataStateChange)="loadData($event)"
                          [defaultState] = "gridSettings[selectedTab]['defaultState'] ? gridSettings[selectedTab]['defaultState'] : null"
                          (selectedDataChange)="tableDisabledButtons = {new : false , action : $event.length == 0}"
                          (actionClick) = "$event.actionId == 'delete' ? delete(): null "
                          (cellClick) = "$event?.column == 'edit' || $event?.column == 'floorplan3d' || $event?.column == 'robot3d' ? 
                                            showDetail($event , ($event?.column == 'floorplan3d' || $event?.column == 'robot3d') ?  $event?.column : null) : 
                                            null" 
                          (createClick) = "showDetail()" [useColumnFilter]='true'>
        </uc-table>
    </div>
    <!-- <div class="viewport-container"  *ngIf = "selectedTab == 'dashboard'">
        <uc-drawing-board #pixi [hideButton] = "{ all : true}"></uc-drawing-board>
    </div> -->
</div>

<!-- * * *  ^ PC VERSION ^   * * * -->


