<div *ngIf="mode == 'STANDARD'" [class] = "'iot label-3js '  + (threejsElRef?.parent?.btmMapPanel?.robotState?.robotCode == robotId? 'selected' : '') " >
    <div class="robot-header">
        <i [class]="'robot mdi status ' + ( robotState.status?.value && ARCS_STATUS_MAP[robotState.status?.value ]? (ARCS_STATUS_MAP[robotState.status?.value ]| cssClassName) : 'mdi-robot')"></i>
        <div class="header-content">
            <div class="robot-name"> {{robotState.robotName}} </div>
            <div class="state-row">
                <span class="alert">
                    <i *ngIf="robotState.obstacleDetected?.value || robotState.tiltActive?.value || robotState.estop?.value" class="icon mdi mdi-alert" 
                        [matTooltip] = "robotState.estop?.value ? uiSrv.commonAlertMessages.estopped : (
                        robotState.tiltActive?.value ?  uiSrv.commonAlertMessages.tiltDetected : uiSrv.commonAlertMessages.obstacleDetected
                        ) | label : uiSrv.langPack"></i>
                </span>

                <div class="battery" *ngIf="robotState.batteryRounded?.value">
                    <span class="percent">{{robotState.batteryRounded?.value}}%</span>
                    <i [class]="'iot-battery mdi mdi-battery' + ( robotState.batteryRounded?.value == 100 ? '' : ('-' + ( robotState.batteryRounded?.value < 10 ? '10' : ((robotState.batteryRounded?.value / 10) | roundDown) * 10))) "></i> 
                </div>   
            </div>

        </div>
    </div>
   

    <div *ngIf="robotState" class="iot-content">
        <div class="iot-row">
            <!-- <span class="status"  *ngIf="robotState.status?.value"> 
                <i [class]="'status mdi mdi-circle ' +  ( robotState.status?.value ? (ARCS_STATUS_MAP[robotState.status?.value ]| cssClassName) : '')  " ></i> 
                <span>{{ARCS_STATUS_MAP[robotState.status.value] | label : uiSrv.langPack}}</span>
            </span>
     -->
    
        </div>

        <!-- <div class="iot-row" [style.display] = "robotState.obstacleDetected?.value ||  robotState.tiltActive?.value || robotState.estop?.value ? 'none' : null">
            <span *ngIf="robotType == 'PATROL'" [class] ="'aqlevel ' +  (  robotState.topModule?.patrol?.airQualityOverall?.value ? (robotState.topModule?.patrol?.airQualityOverall?.value | cssClassName) : '')">
                <span>{{'IEQ' | label : uiSrv.langPack }} : </span> 
                <span>{{ robotState.topModule?.patrol?.airQualityOverall?.value | label : uiSrv.langPack }} </span>
            </span>
        
            <span *ngIf="robotType == 'DELIVERY' && robotState.topModule?.delivery?.totalContainersCount?.value">
                <span>{{((robotSubType == 'TRAY_DELIVERY' ? 'Trays': 'Cabinet') + ' Avail.') | label : uiSrv.langPack }} : </span> 
                <span>{{ robotState.topModule.delivery.availContainersCount.value | label : uiSrv.langPack }} / {{  robotState.topModule.delivery.totalContainersCount.value | label : uiSrv.langPack }}</span>
            </span>
        </div>  -->

        <div *ngIf="robotState.status?.value != 'UNKNOWN' && robotState.status?.value != 'CHARGING'  && robotState.destination?.value"  class="iot-row">         
            <span class="loading-dots">
                <i class="loading-dots--dot"></i>
                <i class="loading-dots--dot"></i>
                <i class="loading-dots--dot"></i>
            </span>

            <span class="destination">
                <i class="mdi mdi-map-marker"></i>
                <span>{{robotState.destination.value}}</span>
            </span>
        </div> 

        <!-- <div class="iot-row" *ngIf="robotState.obstacleDetected?.value || robotState.tiltActive?.value || robotState.estop?.value">
            <span class="warning">
                <i class="mdi mdi-alert"></i>
                <span>{{(robotState.estop?.value ? uiSrv.commonAlertMessages.estopped : (
                            robotState.tiltActive?.value ?  uiSrv.commonAlertMessages.tiltDetected : uiSrv.commonAlertMessages.obstacleDetected
                            )
                         ) | label : uiSrv.langPack}}
                </span>
            </span>
        </div> -->
    </div>
</div>

<div *ngIf="mode == 'MINI'" [class] = "'iot teardrop ' + ' mini-robot'" [style.border] = " color ? ('1px solid ' + color) : null" >
    <span [class]="uiSrv.robotTypeIconMap[robotType] ? uiSrv.robotTypeIconMap[robotType] : 'mdi mdi-robot-outline'" 
          [matTooltip] = "robotState.robotName" matTooltipPosition = "right"> 
    </span>
</div>

<div *ngIf="mode == 'ALERT'" [class] = "'iot teardrop red-glow ' + ' mini-robot'"  [style.border] = " color ? ('1px solid ' + color) : null">
    <span [class]="uiSrv.robotTypeIconMap[robotType] ? uiSrv.robotTypeIconMap[robotType] : 'mdi mdi-robot-outline'" 
          [matTooltip] = "robotState.robotName + ' : ' + ((robotState.estop?.value ? uiSrv.commonAlertMessages.estopped : (
                                                 robotState.tiltActive?.value ?  uiSrv.commonAlertMessages.tiltDetected : uiSrv.commonAlertMessages.obstacleDetected
                                                )
                                             ) | label : uiSrv.langPack)" 
          matTooltipPosition = "right"> 
    </span>
</div>