<div class="task-job">
    <kendo-dialog-titlebar *ngIf="!uiSrv.isTablet" (close)="onClose()">
        <div>{{ (isExecuteTemplate ? 'Execute Template' : (isTemplate ? 'Template' : 'Task')) | label: uiSrv.langPack}}</div>
    </kendo-dialog-titlebar>

    <div class="dialog-content flex-container" [class]="uiSrv.isTablet? 'tablet-container' : ''">
        <kendo-tabstrip #tabstrip *ngIf="mapObj.enable" (tabSelect)=" actionViewModeChanged( $event ) ;">
            <kendo-tabstrip-tab [title]="'Detail' | label : uiSrv.langPack " [selected]="true">
                <ng-template kendoTabContent>
                    <div class="flex-container">
                        <ng-container *ngTemplateOutlet="form"></ng-container>
                        <ng-container *ngTemplateOutlet="table"></ng-container>
                    </div>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab [title]="(isTemplate || !frmGrp.controls['taskId'].value? 'Map Preview' : 'Map View') | label : uiSrv.langPack">
                <ng-template kendoTabContent>
                    <ng-container *ngTemplateOutlet="map"></ng-container>
                </ng-template>
            </kendo-tabstrip-tab>
        </kendo-tabstrip>
        <ng-container *ngIf="!mapObj.enable">
            <ng-container *ngTemplateOutlet="form"></ng-container>
            <ng-container *ngTemplateOutlet="table"></ng-container>
        </ng-container>
        <div class="button-container">
            <button *ngIf="!uiSrv.isTablet || parent.selectedTab != 'add'" kendoButton (click)="onClose()"> {{ uiSrv.isTablet ? 'Back' : 'Cancel' | label : uiSrv.langPack}}</button>
            <button *ngIf="isExecuteTemplate || !readonly" kendoButton (click)="saveToDB()"> {{ ( isExecuteTemplate ? 'Execute' : 'Save' )| label : uiSrv.langPack}}</button>
        </div>
    </div>
</div>


<ng-template #form>
    <form [formGroup]="frmGrp" class="content">
        <div class="form-row">
            <uc-txtbox *ngIf="!isTemplate || isExecuteTemplate" lab="Task No" [frmGrp]="frmGrp" frmCtrl="taskId" [upper]="true" [disabled] = "parentRow"></uc-txtbox>
            <uc-txtbox *ngIf="isTemplate && !isExecuteTemplate" lab="Template Code" [frmGrp]="frmGrp" frmCtrl="missionId"  [upper]="true" [disabled] = "parentRow"></uc-txtbox>
            <uc-txtbox [lab]="isTemplate ? 'Template Description' : 'Task Description'" [frmGrp]="frmGrp" [frmCtrl]="'name'"></uc-txtbox>
            <uc-dropdown *ngIf="util.standaloneApp"  lab="Floor Plan"  [(value)] = "selectedFloorPlanCode" [disabled] = 'readonly'
                         (change) = "onFloorplanChange_SA($event)" [options] = "dropdownOptions.floorplans" [allowClear] = "false">
            </uc-dropdown>
            <uc-txtbox *ngIf="util.arcsApp && (!isTemplate || isExecuteTemplate)" lab="Job Reference ID" [frmGrp]="frmGrp" frmCtrl="jobId" [upper]="true" [disabled] = "parentRow && !isExecuteTemplate"></uc-txtbox>
            <uc-txtbox *ngIf="util.standaloneApp && ((isCreate && !isTemplate) || isExecuteTemplate)" lab="Recurrent" [frmGrp]="frmGrp2" frmCtrl="loop"
                        [numeric]="true" [numDecimals] = "0" numFormat="n0" [disabled] = 'readonly'>
            </uc-txtbox>
            <button *ngIf="util.standaloneApp" kendoButton class="import-btn" [disabled]="selectedFloorPlanCode == null" (click)="importModule.getMissions(); importModule.showPopup = true">
                <span class="mdi mdi-file-import"></span>
                 {{ 'Import' | label: uiSrv.langPack }}
            </button>
        </div>
        <div class="form-row" *ngIf="util.arcsApp">        
            <uc-dropdown #fpdropdown lab="Robot Type" [frmGrp]="frmGrp" frmCtrl="robotType" [disabled] = 'readonly' [translateOption]="true"
                         [options]="dropdownOptions.types" (change) = "refreshRobotOptions(); refreshMaxRobotCount()">
            </uc-dropdown>
            <uc-txtbox *ngIf="((isCreate && !isTemplate) || isExecuteTemplate) && frmGrp.controls['robotCode'].value == null && frmGrp.controls['robotType'].value !== null " lab="Robot Count" [frmGrp]="frmGrp" frmCtrl="robotCount"
                        [numeric]="true" [numDecimals] = "0" numFormat="n0" [disabled] = 'readonly' [numMax]="maxRobotCount" [numMin]="0">
            </uc-txtbox>
            <uc-dropdown #fpdropdown lab="Robot" [frmGrp]="frmGrp" frmCtrl="robotCode"  [disabled] = 'readonly' [showValue]="true"
                        [options]="dropdownOptions.robots" (change) = "refreshRobotType()">
            </uc-dropdown>
            <uc-dropdown *ngIf="!isCreate && !isTemplate" lab="Task Status" [frmGrp]="frmGrp" frmCtrl="state" [disabled] = "parentRow" [options]="dropdownOptions.taskState"></uc-dropdown>
        </div>
        <div class="form-row" *ngIf="util.arcsApp && !isCreate && !isTemplate && frmGrp.controls['state'].value == 'CANCELED' || frmGrp.controls['state'].value == 'FAILED'"> 
             <uc-dropdown lab="Aborted Reason" [frmGrp]="frmGrp" frmCtrl="reasonCode" [disabled] = 'readonly' [translateOption]="true" [options]="dropdownOptions.reason" customClass = "col-3" >
            </uc-dropdown>
            <uc-txtbox lab="Remarks" [frmGrp]="frmGrp" frmCtrl="reasonMessage" [disabled] = 'readonly' ></uc-txtbox>
        </div>
    </form>    
</ng-template>

<ng-template #table>
    <div class="list-container">
        <uc-listview #listview [columnDef]="jobListDef" [(data)]="jobListData" [(newRow)] = "actionNewRow" [customAddRow] = "true" [editable] = "!readonly"
                     (dataChange)="cleanseAllRows()" 
                     [rowDetailCfg] = "actionRowCfg"
                     (buttonClick)="$event.id == actionRowCfg.parentRowKey ? showActionDetailDialog($event) : null;
                                    $event.row == actionNewRow && $event.id == 'add' ? addRow() : null"
                     (valueChange)="$event.id == 'pointCode'? cleanseAllRows() : cleanseRow($event.row);
                                    $event.id == 'pointCode' &&  $event.newValue == null? $event.row['planCode'] = null : null;
                                    $event.id ==  'actionAlias'? refreshActionParameter($event.row , $event.oldValue) : null"
                     (focusChange)="cleanseAllRows() ;
                                    $event.id == 'actionAlias' ? refreshActionDropdown($event) :
                                    $event.id == 'pointCode' && !util.standaloneApp? refreshGridLocationOptions_ARCS($event) : 
                                    $event.id == 'navigationMode' ? refreshGridNavigationOption($event) :
                                    null ">
        </uc-listview>
    </div>                     

</ng-template>

<ng-template #map>
    <div class="floorplan-select-container">
        <kendo-stepper *ngIf="mapObj.steps.length > 1" [steps]="mapObj.steps" stepType="full" [(currentStep)]="mapObj.currentStep" 
                        [linear]="false" [style.width.px]="100 * mapObj.steps.length" 
                        (currentStepChange) = "mapObj.steps[$event]['planCode'] ? selectedFloorPlanCode = mapObj.steps[$event]['planCode'] : null;
                                               mapObj.steps[$event]['planCode'] ? refreshMapView() : null"></kendo-stepper>
        <uc-dropdown *ngIf="mapObj.steps.length < 2 || mapObj.currentStep ==  mapObj.steps.length - 1" lab="Floor Plan"  [(value)] = "selectedFloorPlanCode"  [disabled]="readonly"
                      (change)="util.standaloneApp? onFloorplanChange_SA($event) : refreshMapView() " [options] = "dropdownOptions.floorplans" [allowClear] = "false"></uc-dropdown>
    </div>

    <div [class]="uiSrv.isTablet? 'viewport-container-tablet' : 'viewport-container'">
        <uc-drawing-board #mapContainer [hideButton]="{all:true}" (selectedGraphicsChange)="$event?.type == 'waypoint' ? onMapPointSelected($event) : null ; pixiElRef.viewport.selectedGraphics = null" [readonly] = "true" >
        </uc-drawing-board>
    </div>
</ng-template>


<kendo-dialog *ngIf="importModule.showPopup" class="new-task-dialog" [title]="'Import From Existing Task Template' | label: uiSrv.langPack" (close)="importModule.showPopup = false" [minWidth]="250" [width]="450">
    <uc-dropdown lab = "Template" [(value)] = "importModule.selectedMissionId"  [options] = "importModule.missionsOptions" [allowClear]="false" [showValue]="true"></uc-dropdown>
    <div class="button-container">     
        <button [disabled]="importModule.selectedMissionId==null" kendoButton (click)="importModule.importRows()"> {{ 'OK' | label : uiSrv.langPack}}</button>
    </div>
</kendo-dialog>