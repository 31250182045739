<div class="dialog-content device">

    <kendo-dialog-titlebar (close)="onClose()">
        <div>{{ !parentRow ? (('New' | label: uiSrv.langPack) + ' ') : ''}}{{'Device / IoT' | label: uiSrv.langPack}}</div>
    </kendo-dialog-titlebar>

    <div class="form-container">
        <uc-dropdown lab = "Device Type" [disabled] = "parentRow" [(value)] = "deviceType"  [options] = "dropDownOptions.iotTypes" ></uc-dropdown>
        
        <form  [formGroup]="frmGrp" class="content" *ngIf="deviceType">         

            <ng-container *ngIf="deviceType == 'LIFT'">
                <div class="form-row">
                    <uc-txtbox  lab="Lift Code" [disabled]="parentRow" [frmGrp]="frmGrp"  frmCtrl="liftCode"></uc-txtbox>
                </div>
    
                <div class="form-row">
                    <uc-txtbox lab="Floors" [frmGrp]="frmGrp" frmCtrl="floors"></uc-txtbox>
                </div>                     
            </ng-container>

            <ng-container *ngIf="deviceType == 'DOOR'">
                <div class="form-row">
                    <uc-txtbox  lab="Door Code" [disabled]="parentRow" [frmGrp]="frmGrp"  frmCtrl="doorCode"></uc-txtbox>
                </div>
            </ng-container>

            <ng-container *ngIf="deviceType != 'DOOR' && deviceType != 'LIFT' ">
                <div class="form-row">
                    <uc-txtbox  [lab] = "(deviceType | dropdownDesc : dropDownOptions.iotTypes) + ' Code'" [disabled]="parentRow" [frmGrp]="frmGrp"  frmCtrl="iotCode"></uc-txtbox>
                </div>
            </ng-container>

            <div class="form-row">
                <uc-txtbox lab="Name" [frmGrp]="frmGrp" frmCtrl="name" ></uc-txtbox>
            </div>           
        </form>
    </div>

    <div class="button-container">
        <button kendoButton (click)="onClose()"> {{'Cancel' | label : uiSrv.langPack}}</button>
        <button kendoButton [disabled] = "!deviceType" (click)="saveToDB()">{{'Save' | label : uiSrv.langPack}}</button>
    </div>

</div>