<div class="grid-container"> 
    <!-- (window.resize)="initDone ? refreshTableWidth(): null" -->
    <kendo-grid #kGrid [data]="{data : myData , total : total , first : 1 , last : 2}" kendoGridSelectBy="id" 
                [pageable]="pageable" [groupable]="groupable"
                [pageSize]="state.take" [skip]="state.skip" [sort]="state.sort" [selectable]="true"  [rowSelected]="isRowSelected"
                [filter]="state.filter" [reorderable]="reorderable" [resizable]="resizable" (columnResize) = "refreshColDefsWidth($event)"
                [columnMenu]="filterable ? {filter:true} : false" [sortable]="sortable" class="kendo-grid" [loading]="isLoading"
                (dataStateChange)="state = $event ; serverSidePagination? retrieveData($event) : kendoDataProcessing()"
                >
        <ng-template kendoGridNoRecordsTemplate>
                {{(isLoading ? 'Loading ...' : 'No Records Available')| label : uiSrv.langPack}}
        </ng-template>
                <!-- [data]="{data : myData , total : total , first : 1 , last : 2}"  -->
               <!-- (dataStateChange)="state = $event ; retrieveData($event)" -->
        <ng-template kendoGridToolbarTemplate *ngIf="showToolbar">
            <button *ngIf="toolbarButtons.new && (!functionId || accessObj.add)" kendoButton icon="plus" (click)="createClick.emit()"
                [disabled]="disabledButtons.new">{{'New' | label : uiSrv.langPack}}
            </button>
            <kendo-splitbutton  *ngIf="toolbarButtons.action && actions.length > 0" [data]="actions" (itemClick)="actionClick.emit($event)"
                [disabled]="disabledButtons.action">
                {{'Action' | label : uiSrv.langPack}}
            </kendo-splitbutton >
            <ng-container *ngFor = "let customButton of customButtons">
                <button kendoButton [icon]="customButton.icon" (click)="customButtonClick.emit(customButton.id)"
                        [disabled]="customButton.disabled">{{ customButton.label | label : uiSrv.langPack}}
                </button>
            </ng-container>
        </ng-template>

        <ng-template kendoPagerTemplate let-currentpage="currentPage" let-pagesize="pageSize" let-total="total">
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-numeric-buttons [buttonCount]="10"></kendo-pager-numeric-buttons>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-info></kendo-pager-info>
            <kendo-pager-page-sizes [pageSizes] = "pageSizes"></kendo-pager-page-sizes>
        </ng-template>
        
        <!-- [columnMenu] = "!colDef.fixed && colDef.columnMenu!= true" -->
        <!-- [sortable]="!colDef.fixed && colDef.sortable!=false"     -->
        <kendo-grid-column *ngFor="let colDef of columnDefs" 
            [includeInChooser] =  "!colDef.fixed"
            [width]="colDef.fixed ? fixedColumnWidth + 'px': (colDef.width? colDef.width + 'px' : null )"
            [sortable] = "!colDef.notSortable && !colDef.fixed"
            [columnMenu] = "!colDef.noColumnMenu && !colDef.fixed"
            [resizable] = "!colDef.fixed && colDef.resizable!= false"       
            [reorderable]="!colDef.fixed  && colDef.reorderable != false" 
            [field]="colDef.fixed != false && colDef.type!= 'checkbox'?  colDef.id : null"
            [title]="(colDef.title  | label : uiSrv.langPack)"
            [headerClass]="colDef.id + (colDef.type ?  (' ' + colDef.type) : '')"      
            [class]="colDef.id + (colDef.type ?  (' ' + colDef.type) : '')" >
            <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex"  *ngIf="colDef.type == 'checkbox'">
                <span class="checkbox">
                    <input type="checkbox" kendoCheckBox (change)="toggleAllCheckbox(colDef.id  , $event)" />
                </span>
            </ng-template>

            <ng-container *ngIf="colDef.dropdownOptions || colDef.type == 'date' || colDef.type == 'timestamp'">
                <ng-template kendoGridFilterMenuTemplate let-column="colDef" let-filter="filter" let-filterService="filterService">
                    <ng-container *ngIf="colDef.dropdownOptions">
                        <multicheck-filter  [field]="colDef.id" [filterService]="filterService" [currentFilter]="filter" 
                                            textField="text" valueField="value"  [data]="colDef.dropdownOptions">
                        </multicheck-filter>
                    </ng-container>
                    <ng-container *ngIf=" colDef.type == 'date' || colDef.type == 'timestamp'">               
                        <date-range-filter  [type] = "colDef.type"  [field]="colDef.id" [filter]="filter" [filterService]="filterService"> </date-range-filter>
                    </ng-container>
                </ng-template>
            </ng-container>



            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <ng-container *ngIf="colDef.ngIf === undefined || dataItem[colDef.id] == colDef.ngIf">
                    <span *ngIf="!colDef.type && !colDef.pipe">
                        {{ colDef.dropdownOptions && (dataItem[colDef.id]  | dropdownDesc : colDef.dropdownOptions )? 
                            ((dataItem[colDef.id]  | dropdownDesc : colDef.dropdownOptions ) | label : uiSrv.langPack) : 
                            dataItem[colDef.id] 
                        }}
                    </span>
                    <span *ngIf="(colDef.type == 'date' || colDef.type == 'timestamp') && !colDef.pipe">
                        {{(dataItem[colDef.id] | dateString) ? ((dataItem[colDef.id] | dateString) | date : colDef.pipeArg ? colDef.pipeArg : 'dd/MM/yyyy hh:mm:ss aa') : '' }}
                    </span>
                    <input *ngIf="colDef.type == 'checkbox' && !colDef.pipe" type="checkbox" [(ngModel)]="dataItem[colDef.id]" 
                            (ngModelChange)="refreshSelectedData() ; cellClick.emit({row : dataItem , column : colDef.id , event : $event})"
                            kendoCheckBox />
                    <a *ngIf="colDef.type == 'button' && !colDef.pipe" [matTooltip]="dataItem[colDef.matTooltip]"
                        (click)="cellClick.emit({row : dataItem , column : colDef.id , event : $event})">
                        <span [class]="colDef.icon"></span>
                    </a>
                    <span *ngIf="colDef.pipe">
                        {{colDef.translate ?  ((dataItem[colDef.id] | pipe : colDef.pipe : colDef.pipeArg ) | label : uiSrv.langPack ): (dataItem[colDef.id] | pipe : colDef.pipe : colDef.pipeArg ) }}
                    </span>
                </ng-container>             
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column width = "1px" [locked]="true" headerClass="hidden-column" class="hidden-column"  >
        </kendo-grid-column>
        <kendo-grid-messages [columns]="'Columns' | label : uiSrv.langPack" 
                             [sortAscending] = "'Sort Ascending'|label : uiSrv.langPack"
                             [sortDescending] = "'Sort Descending'|label : uiSrv.langPack"
                             [filter] = "'Filter'|label : uiSrv.langPack"
                             [filterStartsWithOperator]= "'Starts With'|label : uiSrv.langPack"
                             [filterEndsWithOperator]= "'Ends With'|label : uiSrv.langPack"
                             [filterIsEmptyOperator] = "'Is Empty'|label : uiSrv.langPack"
                             [filterNotContainsOperator] =  "'Does Not Contain'|label : uiSrv.langPack"
                             [filterIsNotEmptyOperator] = "'Is Not Empty'|label : uiSrv.langPack"
                             [filterIsNotNullOperator] = "'Is Not Null'|label : uiSrv.langPack"
                             [filterIsNullOperator] = "'Is Null'|label : uiSrv.langPack"
                             [filterNotEqOperator] = "'Is Not Equal'|label : uiSrv.langPack"
                             [filterContainsOperator] = "'Contains'|label : uiSrv.langPack"
                             [filterClearButton]= "'Clear'|label : uiSrv.langPack"
                             [filterFilterButton] = "'Filter'|label : uiSrv.langPack"
                             [filterEqOperator] = "'Is Equal To'|label : uiSrv.langPack"
                             [filterAndLogic] = "'And'|label : uiSrv.langPack"
                             [filterOrLogic] = "'Or'|label : uiSrv.langPack">
        </kendo-grid-messages>
    </kendo-grid>
</div>

<!-- use container if many different types of cells -->
<!-- <button kendoButton (click)="openTaskListCancelPopup(dataItem.taskId);"
                    *ngIf="dataItem.state === 'WAITING'">
                    {{ customMsgService.translate('cancel') }}
                </button> -->