<div class="dialog-content floorplan-3d">
    <kendo-dialog-titlebar [style.display]="uiSrv.isTablet?'none':''" (close) = "onClose()">
        <div>{{'3D Floor Plan' | label: uiSrv.langPack}}</div>
    </kendo-dialog-titlebar>

    <input hidden type="file" #uploader (change)="onfileLoad($event)" accept=".glb" />

    <div class="container"  #container>      
        <ng-container *ngIf="!uiSrv.isTablet">
            <div class="form-container">
                <form [formGroup]="frmGrp" class="content">
                    <uc-txtbox lab="Code" [frmGrp]="frmGrp" frmCtrl="floorPlanCode" [upper] = "true" [disabled] = "true"></uc-txtbox>
                    <uc-txtbox lab="3D Model File" [frmGrp]="frmGrp" frmCtrl="fileName" 
                                [suffixButton]="{icon : 'k-icon mdi mdi-upload'}"
                                (suffixButtonClick)="onUploadClicked()">
                    </uc-txtbox>
                    
                    <!-- <uc-txtbox lab="3D Point Cloud File" [frmGrp]="frmGrp" frmCtrl="pointCloud" 
                                [suffixButton]="{icon : 'k-icon mdi mdi-upload'}">
                    </uc-txtbox> -->

                    <!-- <span>{{'Object Browser' | label : uiSrv.langPack }}</span> -->
                    <div class="object-browser" *ngIf="threeJsElRef && threeJsElRef._initDone.value">         
                        <!-- <div>
                            <a [class]="threeJsElRef?.transformCtrl?.object == threeJsElRef?.pointCloud ? 'selected' : ''" 
                                (click)="selectObject3D(threeJsElRef.pointCloud)" >
                                <span class="mdi mdi-data-matrix"></span>
                                {{'Point Cloud' | label : uiSrv.langPack }}
                            </a>
                        </div>            -->

                        <div>
                            <a [class]="threeJsElRef?.transformCtrl?.object == threeJsElRef?.floorPlanModel ? 'selected' : ''" 
                                (click)="threeJsElRef.use2DFloorPlanModel ? null : selectObject3D(threeJsElRef.floorPlanModel)" >
                                <span class="mdi mdi-cube-outline"></span>
                                {{'Floor Plan' | label : uiSrv.langPack }}
                            </a>
                        </div>           

                        <ng-container *ngFor = "let iotType of iotTypes">
                            <div class="tab-1">
                                <span [class]="iconMap[iotType]"> 
                                    {{iotType | dropdownDesc : threeJsElRef.dropdownOptions.iotTypes | label : uiSrv.langPack }}
                                    <a class="mdi mdi-plus-box" (click)="addIotObj3D(iotType)"></a>
                                </span>                 
                                <ul>
                                    <li *ngFor = "let iot of iots(iotType)" 
                                        [class] = "threeJsElRef?.transformCtrl?.object == iot.objectRef? 'selected' : ''" 
                                        (click) = "selectObject3D(iot.objectRef)">
                                        <a>{{ (iotType == 'LIFT' ? iot.objectRef['liftCode'] : iot.objectRef.iotCode) | dropdownDesc : iotOptions[iotType]  }}</a> 
                                    </li>
                                </ul>              
                            </div>
                        </ng-container>
                     

                        <!-- <div class="tab-1">
                            <span [class]="iconMap['DOOR']"> 
                                {{'Door' | label : uiSrv.langPack }}
                                <a class="mdi mdi-plus-box" (click)="addIotObj3D('DOOR')"></a>
                            </span>                 
                            <ul>
                                <li *ngFor = "let door of iotDoors" 
                                    [class] = "threeJsElRef?.transformCtrl?.object == door.objectRef? 'selected' : ''" 
                                    (click) = "selectObject3D(door.objectRef)">
                                    <a>{{door.objectRef.iotCode | dropdownDesc : threeJsElRef.dropdownOptions.doors}}</a> 
                                </li>
                            </ul>              
                        </div> -->

                        <!-- <div class="tab-1">
                            <span class="iconMap['LIFT']"> 
                                {{'Lift' | label : uiSrv.langPack }}
                                <a class="mdi mdi-plus-box" (click)="addIotObj3D('LIFT')"></a>
                            </span>
                            <ul>
                                <li *ngFor = "let lift of iotLifts" 
                                     [class] = "threeJsElRef?.transformCtrl?.object == lift.objectRef? 'selected' : ''" 
                                     (click) = "selectObject3D(lift.objectRef)">
                                     <a>{{lift['objectRef'].liftCode}}</a>
                                </li>
                            </ul>      
                        </div> -->

     

                        <!-- <div class="tab-1">
                            <span class="mdi mdi-turnstile-outline">
                                 {{'Turnstile' | label : uiSrv.langPack }}
                                 <a class="mdi mdi-plus-box" (click)="addIotObj3D('TURNSTILE')"></a>
                            </span>     
                            <ul>
                                <li *ngFor = "let turnstile of iotTurnstiles" 
                                    [class] = "threeJsElRef?.transformCtrl?.object == turnstile.objectRef? 'selected' : ''" 
                                    (click) = "selectObject3D(turnstile.objectRef)">
                                    <a>{{turnstile.objectRef.turnstileId}}</a>
                                </li>
                            </ul>                 
                        </div>  -->
                    </div> 

                </form>
            </div>
        </ng-container>

        <div  class="three-d-container"> 
            <uc-3d-viewport #threeJs [uiToggles] = "{showFloorPlanImage : false , transformControl : true}" (transformEnd) = "refreshTransformation($event)" 
                                     (objClicked) = "Object3DClicked($event)" (removed) = "Object3DRemoved($event)" [iotOptions] = "iotOptions">
            </uc-3d-viewport>
        </div>        
        <!-- <div *ngIf="!file && floorPlanDataset" class="upload-container">
            <div>
                <span class="no-model-message">{{'Please upload 3D model for the floor plan first.' | label: uiSrv.langPack}}</span>              
            </div>
        </div>  -->
    </div>

    <div class="button-container">
        <button kendoButton (click) = "onClose()"> {{'Cancel' | label : uiSrv.langPack}}</button>
        <button *ngIf="!readonly" kendoButton (click) = "saveToDB()"  [disabled] = "!floorPlanDataset"> {{'Save' | label : uiSrv.langPack}}</button>
    </div>
</div>




