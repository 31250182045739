<div [class]="uiSrv.isTablet? 'control card-container tablet' : 'control card-container'">
    <div class="card-header-wrapper">
        <!-- title -->
        <h3 class="card-title"></h3>
        <!-- tab buttons -->
        <div class="card-buttons">
            <kendo-buttongroup selection="single">
                <button *ngFor="let tab of tabs" kendoButton [toggleable]="true" [selected]="selectedTab == tab.id"  
                         (click)=" tab.id == selectedTab ? null : onTabChange(tab.id) ">
                    {{ tab.label | label : uiSrv.langPack }}
                </button>
            </kendo-buttongroup>
        </div>
        <span></span>
    </div>

    <app-sa-control-buttons *ngIf="selectedTab == 'controls'"  class="controls-container" ></app-sa-control-buttons>
 

    <div *ngIf="selectedTab == 'robot'" class="robot-container">
        <div class="form-container">
            <form [formGroup]="frmGrpRobot" class="content">
                <div class="form-row">
                    <uc-txtbox lab="Robot Code" [frmGrp]="frmGrpRobot" frmCtrl="robotCode"  [disabled]="true"></uc-txtbox>
                </div>
                <div class="form-row">
                    <uc-txtbox lab="Robot Type" [frmGrp]="frmGrpRobot" frmCtrl="robotType"  [disabled]="true"></uc-txtbox>
                    <uc-txtbox lab="Robot Sub Type" [frmGrp]="frmGrpRobot" frmCtrl="robotSubType"  [disabled]="true"></uc-txtbox>
                </div>
                <div class="form-row">
                    <uc-txtbox lab="Network" [value]="dataSrv.robotProfile.ip" [disabled]="true" customClass="col txtbox-container"></uc-txtbox>
                    <uc-txtbox lab="CPU Temp." [value]="robotSrv.STANDALONE.state.cpuTemp.value" [disabled]="true"  customClass="col txtbox-container" [suffixButton] = "{text : ' °C'}"></uc-txtbox>
                </div>
                <div class="form-row">                   
                    <uc-txtbox *ngIf="!configSrv.disabledModule_SA.maxSpeed" lab="Maximum Speed" [frmGrp]="frmGrpRobot" frmCtrl="robotMaxSpeed"  [suffixButton] = "{icon : 'mdi mdi-tools'}" (suffixButtonClick) = "loadSafety(); configObj.type = 'maxSpeed' ;  configObj.title = 'Set Maximum Speed'"></uc-txtbox>
                    <uc-txtbox *ngIf="!configSrv.disabledModule_SA.safetyZone" lab="Safety Zone" [frmGrp]="frmGrpRobot" frmCtrl="robotSafetyZone"  [suffixButton] = "{icon : 'mdi mdi-tools'}" (suffixButtonClick) = "loadSafety(); configObj.type = 'safetyZone' ; configObj.title = 'Set Safety Zone'" ></uc-txtbox>
                </div>
            </form>
        </div>
    </div>


    <div *ngIf="selectedTab == 'action'" class="action table-container">
        <uc-table #table  [toolbarButtons] ="{new : true , action: false}" [columnDefs] = "columnDef" [(data)]="data" 
                          dataSourceApiEndpoint = "api/robot/action/v1" functionId = "ACTION"
                          [disabledButtons] = "tableDisabledButtons" [isLoading] = "isTableLoading"
                          (selectedDataChange)="tableDisabledButtons = {new : false , action : $event.length == 0}"
                          (cellClick) = "$event?.column == 'edit' ? showActionDetail($event) : null" 
                          (createClick) = "showActionDetail()" [useColumnFilter]='true'>
            </uc-table>
    </div>

    <div *ngIf="selectedTab == 'log'" class="table-container">
        <cm-event-log></cm-event-log>
    </div>
</div>

<ng-template #buttons>
    <div class="buttons-container">
        <button kendoButton>{{'Cancel' | label : uiSrv.langPack}}</button>
        <button kendoButton (click)="saveToDB()">{{'Save' | label : uiSrv.langPack}}</button>
    </div>
</ng-template>


<kendo-dialog *ngIf="configObj.type" [title]="(configObj.title | label : uiSrv.langPack)"
               (close)="configObj.type = null" [minWidth]="250" [width]="450">

    <ng-container *ngIf="configObj.type == 'safetyZone'">
        <uc-dropdown  lab="Mode" [(value)] = "configObj.safetyZone.mode" [allowClear] = "false"
                      [options] = "safetyZoneOptions" [translateOption]="true"></uc-dropdown>
    </ng-container>

    
    <ng-container *ngIf="configObj.type == 'maxSpeed'">
        <uc-txtbox  lab = "Maximum Speed" [(value)] = "configObj.maxSpeed.limit"  [numeric]="true" [numMin]="0.3" [numMax] = "1.8" ></uc-txtbox>
    </ng-container>

    <kendo-dialog-actions>
        <button kendoButton (click)="configObj.type == 'safetyZone' ? sendSafetyZoneRequestToRV() : (configObj.type == 'maxSpeed' ?  sendMaxSpeedRequestToRV() : null)">
            {{ 'OK' | label : uiSrv.langPack }}
        </button>
        <button kendoButton (click)="configObj.type = null">{{ 'Cancel' | label : uiSrv.langPack }}</button>
    </kendo-dialog-actions>
</kendo-dialog>
