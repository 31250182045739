import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit, ViewChild, HostBinding, HostListener } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { filter, retry, take } from 'rxjs/operators';
import {  Subject } from 'rxjs';
import { DataService} from 'src/app/services/data.service';
import { DropListBuilding, DropListIot, DropListIotType, DropListLift, DropListMap, JFloorPlan, JLift3DModel, JMap, JRobot3DModel, MapJData, SaveRecordResp, ShapeJData } from 'src/app/services/data.models';
import { RvHttpService } from 'src/app/services/rv-http.service';
import { UiService } from 'src/app/services/ui.service';
import { Map2DViewportComponent, Robot, radRatio } from 'src/app/ui-components/map-2d-viewport/map-2d-viewport.component';
import { GeneralUtil } from 'src/app/utils/general/general.util';
import * as PIXI from 'pixi.js';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { AuthService } from 'src/app/services/auth.service';
import { SaMapComponent } from 'src/app/standalone/sa-map/sa-map.component';
import { toJSON } from '@progress/kendo-angular-grid/dist/es2015/filtering/operators/filter-operator.base';
import { trimAngle } from 'src/app/utils/math/functions';
import { Observable, of } from 'rxjs';
import { PixiGraphicStyle, DRAWING_STYLE } from 'src/app/utils/ng-pixi/ng-pixi-viewport/ng-pixi-styling-util';
import { PixiEditableMapImage, PixiWayPoint } from 'src/app/utils/ng-pixi/ng-pixi-viewport/ng-pixi-map-graphics';
import { HttpEventType } from '@angular/common/http';
import { ThreejsViewportComponent , Object3DCommon, LiftObject3D, RobotObject3D, canDestroy, IotObject3D, NORMAL_ANGLE_ADJUSTMENT, DEFAULT_3D_ROBOT_CONE_SETTING } from 'src/app/ui-components/threejs-viewport/threejs-viewport.component';
import { MapService } from 'src/app/services/map.service';
import { BoxHelper, BufferAttribute, BufferGeometry, CircleGeometry, DoubleSide, GridHelper, Line, LineBasicMaterial, Mesh, MeshBasicMaterial, Object3D, SphereGeometry, Vector3 } from 'three';

@Component({
  selector: 'app-arcs-setup-robot3d',
  templateUrl: './arcs-setup-robot3d.component.html',
  styleUrls: ['./arcs-setup-robot3d.component.scss']
})
export class ArcsSetupRobot3dComponent implements OnInit {
  readonly = false
  robotContainer : Object3DCommon 
  @ViewChild('uploader') public uploader
  @ViewChild('container') mainContainer: ElementRef
  @ViewChild('threeJs') threeJsElRef: ThreejsViewportComponent
  @HostBinding('class') customClass = 'setup-robot3d'
  
  @HostListener('document:keydown.enter',['$event']) 
  logCameraPositions() {
    // Get the current camera position
    const cameraPosition =   this.threeJsElRef.camera.position;
    console.log('Camera Position:', cameraPosition.x, cameraPosition.y, cameraPosition.z);
  
    // Get the current camera look at position
    const targetPosition = this.threeJsElRef.orbitCtrl.target;
    console.log('Look At Position:', targetPosition.x, targetPosition.y, targetPosition.z);
  }


  constructor(public util: GeneralUtil, public uiSrv: UiService, public windowSrv: DialogService, public ngZone: NgZone, public mapSrv : MapService, public changeDetector : ChangeDetectorRef ,
    public httpSrv: RvHttpService, private dataSrv: DataService, public authSrv: AuthService) {
      this.loadingTicket = this.uiSrv.loadAsyncBegin()  
  }

  get robotCode (){
    return this.frmGrp.controls['robotCode'].value
  }

  set robotCode(v){
    this.frmGrp.controls['robotCode'].setValue(v)
  }

  get robotDimension() {
    const dimensions = new Vector3();
    this.robotObj.boxHelper.object.geometry.computeBoundingBox();
    this.robotObj.boxHelper.object.geometry.boundingBox.getSize(dimensions).multiplyScalar( 1 / this.util.config.METER_TO_PIXEL_RATIO  ? 1 / this.util.config.METER_TO_PIXEL_RATIO : 0.05);
    return dimensions
  }

  frmGrp = new FormGroup({
    robotCode: new FormControl('', Validators.compose([Validators.required, Validators.pattern(this.dataSrv.codeRegex)])),
    fileName: new FormControl(null),
    scale: new FormControl(1),
    positionX : new FormControl(0),
    positionY : new FormControl(0),
    positionZ : new FormControl(0),
    rotationX : new FormControl(0),
    rotationY : new FormControl(0),
    rotationZ : new FormControl(0),
    modifiedDateTime: new FormControl(null),
    // pointCloud: new FormControl('17W_1F.pcd'),
  })

  
  // initialDataset = null
  // get initialFloorPlan(){
  //   return this.initialDataset?.['floorPlan']
  // }
  windowRef
  @Input() parent: SaMapComponent
  // selectedMapIds = []
  @Input() set id(v) {
    // this.code = v
  }
  get id() {
    return this.code
  }

  file: Blob = null
  fileType = 'glb'
  loadingTicket
  subscriptions = []
  _robotObj: RobotObject3D
  get robotObj() {
    return this._robotObj
  }

  set robotObj(v) {
    if (this._robotObj && this._robotObj != v) {
      this.threeJsElRef.transformCtrl.detach()
      this._robotObj.destroy()
    }
    this._robotObj = v
    if (this._robotObj?.parent != this.robotContainer) {
      this.robotContainer.add(this._robotObj)
    }
    if(v){
      this.selectObject3D(v)
      this._robotObj?.position.setZ(this._robotObj.getPositionZ( 0 , 0 ))
      this._robotObj.events.manualTransformed.subscribe(()=>{
        this._robotObj.toolTip.refreshPosition()
      })
    }
  }

  @Input() parentRow : {robotCode : string , name : string , robotBase : string , robotType : string , robotSubType : string} = null
  get isCreate() {
    return this.parentRow == null
  }
  get code(): string {
    return this.parentRow?.robotCode
  }

  // dropdown(iotType : string){
  //   const mapping = {
  //     LIFT : this.dropdownOptions.lifts , 
  //     DOOR : this.dropdownOptions.doors
  //   }
  //   return mapping[iotType] ? mapping[iotType] : null
  // }


  ngOnInit(): void {
    this.robotCode = this.parentRow.robotCode
    this.readonly = this.readonly || !this.authSrv.hasRight(this.id ? "ROBOT_EDIT" : "ROBOT_EDIT")
    if (this.readonly) {
      Object.keys(this.frmGrp.controls).forEach(k => this.frmGrp.controls[k].disable())
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubsribe())
  }


  async ngAfterViewInit() {
    this.frmGrp.controls['fileName']['uc'].textbox.input.nativeElement.disabled = true
    await this.threeJsElRef.$initDone.toPromise()
    this.threeJsElRef.uiToggles.showRobotState = true
    this.robotContainer = new Object3DCommon(this.threeJsElRef , 'ROBOT_CONTAINER')
    this.robotContainer.rotateX(NORMAL_ANGLE_ADJUSTMENT)
    this.robotContainer.rotateZ(2 * NORMAL_ANGLE_ADJUSTMENT)
    this.threeJsElRef.scene.add(this.robotContainer)
    const size = 300;
    const divisions = 30;
    const gridHelper = new GridHelper(size, divisions , 0xFF0000);
    this.threeJsElRef.scene.add(gridHelper);

    //v TO BE MOVED TO THREE JS COMPONENT v
    const resolution = 100;
    const amplitude = 30;
    const positions = [];
    const geometry = new BufferGeometry();
    var material = new LineBasicMaterial( { color: 0xFF0000, opacity: 1.0} );

    for (let i = 0; i <= resolution / 2; i++) {
      const segment = (i / (resolution / 2)) * Math.PI;
      const x = Math.cos(segment) * amplitude;
      const y = 0;
      const z = Math.sin(segment) * amplitude;
      positions.push(x, y, z);
    }

    geometry.setAttribute('position', new BufferAttribute(new Float32Array(positions), 3));    
    const halfCircle = new Line( geometry, material );  
    this.threeJsElRef.scene.add(halfCircle);
    //^ TO BE MOVED TO THREE JS COMPONENT ^

    this.threeJsElRef.orbitCtrl.update()
    this.threeJsElRef.orbitCtrl.enabled = true
    
    await this.loadData()
    this.uiSrv.loadAsyncDone(this.loadingTicket);
  }

  onfileLoad(event) {
    const files : FileList = (<HTMLInputElement>event.target).files;
    if (files.length === 0) {
      return;
    }
    
    
    let max_file_size = this.util.config['UPLOAD_MODEL_MAX_SIZE_KB'] ? this.util.config['UPLOAD_MODEL_MAX_SIZE_KB'] : 102400
    this.frmGrp.controls['fileName'].setValue(files[0].name)
    if (files[0].size / 1024 > max_file_size) {
      this.uiSrv.showWarningDialog(this.uiSrv.translate(`The uploaded file exceeds the maximum size`) + `( ${max_file_size > 1024 ? (max_file_size / 1024 + 'MB') : (max_file_size + 'KB')} )`);
      return;
    }
    

    let ticket = this.uiSrv.loadAsyncBegin()
    var reader = new FileReader();
    this.fileType = files[0].name.split(".")[files[0].name.split.length - 1]
    reader.readAsArrayBuffer(files[0])
    reader.onload = async (event) => {
      this.file = new Blob([event.target.result]);
      await this.load3DModel()
      this.uiSrv.loadAsyncDone(ticket)
      // if (this.fileType == 'glb') {
      //   await this.load3DModel({glb : this.file})
      //   this.uiSrv.loadAsyncDone(ticket)
      // } else if (this.fileType == 'zip') {
      //   await this.load3DModel({zip : this.file})
      //   this.uiSrv.loadAsyncDone(ticket)
      // }
      // event.target.value = null
    }
  }


  onUploadClicked(){
    this.uploader.nativeElement.click()
  }

 

  async loadData() {
    this.robotObj = new RobotObject3D(this.threeJsElRef , this.robotCode , this.parentRow.robotBase , this.parentRow.robotType , this.parentRow.robotSubType )

    this.robotObj.visible = true
    this.updateCamera()
    // const geometry = new SphereGeometry( 150, 32, 16 ); 
    // const material = new MeshBasicMaterial( { color: 0xffff00 } ); 
    // const sphere = new Mesh( geometry, material );

    // console.log(sphere)
    // this.threeJsElRef.fitObjectToCamera(robot3D , 30)
  }

  async load3DModel(){
    let tempSetting = new JRobot3DModel()
    tempSetting.scale = 1
    tempSetting.positionX = 0
    tempSetting.positionY = 0
    tempSetting.positionZ = 0
    tempSetting.rotationX = 0
    tempSetting.rotationY = 0
    tempSetting.rotationZ = 0
    tempSetting.coneOffset = DEFAULT_3D_ROBOT_CONE_SETTING.position.y

    this.robotObj = new RobotObject3D(this.threeJsElRef, this.robotCode, this.parentRow.robotBase, this.parentRow.robotType, this.parentRow.robotSubType,
                                      tempSetting, this.file)
    this.robotObj.visible = true
    await this.robotObj.$gltfLoaded.toPromise()
    this.threeJsElRef.fitObjectToCamera(this.robotObj , 3 , 70 , 0)
  }

  async updateCamera(){    
    await this.robotObj.$gltfLoaded.toPromise()
    //this.threeJsElRef.camera.position.set(0, this.robotObj.depth * 3, this.robotObj.height * 1.5)
    // this.threeJsElRef.camera.position.set(this.threeJsElRef.camera.position.x, this.threeJsElRef.camera.position.y + this.robotObj.height * 5, this.threeJsElRef.camera.position.z + this.robotObj.depth * 0.5)
    this.threeJsElRef.fitObjectToCamera(this.robotObj , 3 , 70 , 0)
  }


  Object3DRemoved(obj: RobotObject3D){
    obj?.boxHelper.hide()
    // if(obj == this.threeJsElRef.floorPlanModel){
    //   this.uploader.nativeElement.value = null
    //   this.frmGrp.controls['fileName'].setValue(null)
    //   this.file = null
    //   this.clearIots()
    //   this.threeJsElRef.loadFloorPlanModelFrom2DImage()
    // }  
  }

  async selectObject3D(obj : RobotObject3D){
    if (this.robotObj) {
      await this.robotObj.$gltfLoaded.toPromise()
      this.threeJsElRef.transformCtrl.attach(obj._gltf.scene)
      console.log('select')
      this.robotObj.boxHelper.show()
      // const dimensions = new Vector3();
      // this.robotObj.boxHelper.object.geometry.computeBoundingBox();
      // this.robotObj.boxHelper.object.geometry.boundingBox.getSize(dimensions);
    }
  }

  refreshModelTransformation(){
    this.threeJsElRef.floorPlanModel.scale.set(this.frmGrp.controls['scale'].value , this.frmGrp.controls['scale'].value , this.frmGrp.controls['scale'].value)
    this.threeJsElRef.floorPlanModel.position.set(this.frmGrp.controls['positionX'].value , this.frmGrp.controls['positionY'].value ,this.frmGrp.controls['positionZ'].value)
    this.threeJsElRef.floorPlanModel.rotation.set(this.frmGrp.controls['rotationX'].value / radRatio , this.frmGrp.controls['rotationY'].value / radRatio ,this.frmGrp.controls['rotationZ'].value /radRatio)
  }


  async validate(){   
    return true
  }
  
  getBaseIotDataObject(obj : Object3DCommon){
    let ret =  {
      floorPlanCode : this.robotCode,
      scale : obj.scale.x,
      positionX : obj.position.x,
      positionY : obj.position.y,
      positionZ : obj.position.z,
      rotationX : obj.rotation.x,
      rotationY : obj.rotation.y,
      rotationZ : obj.rotation.z,
    }
    return ret
  }

  async saveToDB() {
    if(!await this.validate()){
      return
    }

    let ticket = this.uiSrv.loadAsyncBegin()
    this.refreshTransformation(null)
    // below to be moved to httpSrv
    const formData = new FormData();

    if (this.file) {
      formData.append('file', this.file, this.robotCode + '.glb');
      formData.set('fileExtension', '.glb');
      formData.set('floorPlan', JSON.stringify(this.frmGrp.value))
    }


    this.httpSrv.http.put(this.util.getRvApiUrl() + `/api/map/3dModel/v1/${this.robotCode}`, formData, { reportProgress: false, observe: 'events'}).subscribe(resp => {
      if (resp.type === HttpEventType.Response) {       
        const respData : SaveRecordResp = resp.body?.['data']
        this.uiSrv.loadAsyncDone(ticket)
        if(resp.status == 200 && respData.result == true){
          this.uiSrv.showNotificationBar('Save Successful', 'success' , undefined , undefined , true)
          this.windowRef.close()
        }else{
          this.uiSrv.showMsgDialog(respData?.msg ? respData?.msg : 'Save Failed')
        }
      }
    },
    error =>{
      console.log(error)
      this.uiSrv.loadAsyncDone(ticket)
      this.uiSrv.showMsgDialog(error?.message ? ` : ${error?.message}`: 'Save Failed')
    });
  }

  async onClose(){    
    if( this.readonly || await this.uiSrv.showConfirmDialog('Do you want to quit without saving ?')){
      this.windowRef.close()
    }
  }

  scaleChange(value:number){
    this.threeJsElRef.floorPlanModel.scale.set(value , value , value)
  }

  positionChange(dim : 'x' | 'y' | 'z' ,  value : number){
    if(dim == 'x'){
      this.threeJsElRef.floorPlanModel.position.setX(value)
    }else if(dim == 'y'){
      this.threeJsElRef.floorPlanModel.position.setY(value)
    }else if(dim == 'z'){
      this.threeJsElRef.floorPlanModel.position.setZ(value)
    }
  }

  rotationChange(dim : 'x' | 'y' | 'z' ,  value : number){
    value = value/radRatio
    const originalRotation = this.threeJsElRef.floorPlanModel.rotation
    if(dim == 'x'){
      this.threeJsElRef.floorPlanModel.rotation.set(value , originalRotation.y , originalRotation.z)
    }else if(dim == 'y'){
      this.threeJsElRef.floorPlanModel.rotation.set(originalRotation.x , value , originalRotation.z)
    }else if(dim == 'z'){
      this.threeJsElRef.floorPlanModel.rotation.set(originalRotation.x ,originalRotation.y , value)
    }
  }

  refreshTransformation(event : Object3D){
    if(this.threeJsElRef.floorPlanModel){
      this.frmGrp.controls['scale'].setValue( this.util.trimNum(this.threeJsElRef.floorPlanModel.scale.x));
      this.frmGrp.controls['positionX'].setValue( this.util.trimNum(this.threeJsElRef.floorPlanModel.position.x));
      this.frmGrp.controls['positionY'].setValue( this.util.trimNum(this.threeJsElRef.floorPlanModel.position.y));
      this.frmGrp.controls['positionZ'].setValue( this.util.trimNum(this.threeJsElRef.floorPlanModel.position.z));
      this.frmGrp.controls['rotationX'].setValue( this.util.trimNum(this.threeJsElRef.floorPlanModel.rotation.x * radRatio));
      this.frmGrp.controls['rotationY'].setValue( this.util.trimNum(this.threeJsElRef.floorPlanModel.rotation.y * radRatio));
      this.frmGrp.controls['rotationZ'].setValue( this.util.trimNum(this.threeJsElRef.floorPlanModel.rotation.z * radRatio));
    }
  }

}
