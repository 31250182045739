<div class="dialog-content change-password">
    <kendo-dialog-titlebar (close)="onClose()">
        <div>{{'Change Password' | label: uiSrv.langPack}}</div>
    </kendo-dialog-titlebar>
    <div *ngIf="msg" class="change-pw-msg">{{msg | label : uiSrv.langPack}}</div>
    <form [formGroup]="frmGrp" class="content">
        <uc-txtbox *ngIf="requireOldPw" lab="Current Password" [frmGrp]="frmGrp" frmCtrl="oldPassword" type = "password" ></uc-txtbox>
        <uc-txtbox lab="New Password" [frmGrp]="frmGrp" frmCtrl="password" type = "password" ></uc-txtbox>
        <uc-txtbox lab="Confirm Password" [frmGrp]="frmGrp" frmCtrl="confirmPassword" type = "password" ></uc-txtbox>
    </form>
    <div class="button-container">
        <button kendoButton (click)="onClose()"> {{'Cancel' | label : uiSrv.langPack}}</button>
        <button kendoButton (click)="saveToDB()"> {{'Save' | label : uiSrv.langPack}}</button>
    </div>
</div>