<div class="dialog-content robot-coop">
    <kendo-dialog-titlebar (close)="onClose()">
        <div>{{'Robot Collaboration' | label: uiSrv.langPack}}</div>
    </kendo-dialog-titlebar>

    <div class="container">
        <div class="node-list" cdkDropList>
            <div *ngFor = "let node of nodes" class="node-box" cdkDrag (cdkDragStarted)="selectedNode = node">
                {{node.name}}
                <div class="node-placeholder" *cdkDragPlaceholder></div> 
            </div>
            <!-- <div class="node-box" cdkDrag>
                Test 1
                <div class="node-placeholder" *cdkDragPlaceholder></div> 
            </div>
            <div class="node-box" cdkDrag>
                Test 2
                <div class="node-placeholder" *cdkDragPlaceholder></div> 
            </div>
            <div class="node-box" cdkDrag>
                Test 3
                <div class="node-placeholder" *cdkDragPlaceholder></div> 
            </div> -->
        </div>
        <div class="flowchart-container" cdkDropList>
            <uc-workflow-designer [(newNode)] = "selectedNode"></uc-workflow-designer>
        </div>
    </div>


    <!-- <div class="form-container">
        <form [formGroup]="frmGrp" class="content">
            <div class="form-row">
                <uc-txtbox lab="Event Trigger" [disabled]="true" [frmGrp]="frmGrp" frmCtrl="eventName"></uc-txtbox>
                <uc-txtbox lab="Operation Name" [frmGrp]="frmGrp" frmCtrl="name"></uc-txtbox>
            </div>
            <div class="form-row">
                <uc-dropdown lab="Robot Type" [frmGrp]="frmGrp" frmCtrl="robotType" [options]="dropdownOptions.types" [disabled]="true">
                </uc-dropdown>
            </div>
        </form>
        <kendo-tabstrip class="rule-tabstrip" #tabstrip (tabSelect)=" onTabChange() ; $event.index == rules.length ? $event.preventDefault() : null; $event.index == rules.length ? addRule() : selectedTabIndex = $event.index">
            <kendo-tabstrip-tab *ngFor="let rule of rules ; let i = index"  [selected] = " i == selectedTabIndex" [title]="rule.name">
                <ng-template kendoTabContent>                  
                    <uc-txtbox lab="Rule Name" [(value)] = "rule.name"></uc-txtbox>
                    <kendo-filter #kFilter [filters]="rule.filters"  [value]="rule.filterValues"  (valueChange)="onFilterChange($event)">
                    </kendo-filter>                       
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title ="+" > 
                <ng-template kendoTabContent></ng-template>
            </kendo-tabstrip-tab>
        </kendo-tabstrip>
        <kendo-tabstrip *ngIf="rules[selectedTabIndex]" class="execute-tabstrip">
            <kendo-tabstrip-tab title = "Execute"  [selected] = "true"> 
                <ng-template kendoTabContent >
                    <uc-dropdown lab = "Task Template" [(value)] = "rules[selectedTabIndex].missionId" [options] = "dropdownOptions.missions" [allowClear]="false" [showValue]="true"></uc-dropdown>                  
                </ng-template>
            </kendo-tabstrip-tab>
        </kendo-tabstrip>
    </div> -->
    
    <div class="button-container">
        <button kendoButton (click)="onClose()"> {{'Cancel' | label : uiSrv.langPack}}</button>
        <button kendoButton (click)="saveToDB()" >{{'Save' | label : uiSrv.langPack}}</button>
    </div>
</div>