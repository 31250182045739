<div *ngFor="let row of layout" [class]="'row' +  ' ' + (row['title'] | cssClassName : 'sc-')">
    <div class="title">
        <span [class]="'icon ' + row['icon']"></span>
        <span class="text">{{row['title'] | label: uiSrv.langPack}}</span>
    </div>
    <div class="button-container">
        <ng-container *ngFor="let button of row['buttons']" >
            <button *ngIf="!configSrv.disabledModule_SA[button['id']]" kendoButton [style.display] = "!button['display'] || button['display'].value ? '' : 'none'"
                    [disabled]= "readonly.controls || (button['disabled'] && button['disabled']['value']) || 
                        (robotSrv.data.navigationDisabled.value && (button['id'] == 'followMe' ||  button['id'] == 'auto' || button['id'] == 'changeMap')) ||
                        (button['id'] == 'changeMap' && robotSrv.data.isMappingMode.value) ||
                        (button['id'] == 'charge' && robotSrv.data.isMappingMode.value) ||
                        ((button['id'] == 'stop' || button['id'] == 'pause' ) && !robotSrv.data.isAutoMode.value) "
                    (click)="triggerControl(button['id'], (button['active']?.value ? button['textActive'] : button['text']) , button['active'])" 
                    [class]="(button['text']  | cssClassName) +  (button['active']?.value ? ' active' : '')">
                <span [class]="'icon ' + button['icon']"></span>
                <span class="text">{{ button['active']?.value &&  button['textActive'] ? button['textActive'] : button['text'] | label: uiSrv.langPack}}</span>
            </button>
        </ng-container>
    </div>
</div>


<div *ngIf="showChangeMapPopup || showLocalizePopup" class="viewport-container">
    <uc-drawing-board #mapContainer [popupScreen]="true" [hideButton]="{all:true}"  [readonly] = "true" (cancelPopupScreen) = "showLocalizePopup = false ; showChangeMapPopup = false;" 
                      (confirmSpawnPick) = "uiSrv.loadAsyncDone(pixiElRef.loadingTicket); showLocalizePopup = false ; showChangeMapPopup = false" 
                      [uitoggle] = " {showRosMap : true, showWaypoint : false}"
                      [showDropdown] = "true"> 
    </uc-drawing-board>
    <!-- [pickSpawnPoint]="true" -->
</div>

<div *ngIf="showManualModePopup" class="viewport-container">
    <uc-drawing-board #mapContainer [popupScreen]="true" [hideButton]="{all:true}"  [showRobot] = "true"  [readonly] = "true"
                      [uitoggle] = " {showRosMap : true, showWaypoint : false}"
                      [remoteControl] = "true" (terminateRemoteControl) = "showManualModePopup = false ">
    </uc-drawing-board>
</div>

<kendo-dialog *ngIf="optionObj.type" [title]="(optionObj.title | label : uiSrv.langPack)"
               (close)="optionObj.type = null" [minWidth]="250" [width]="450">
    <ng-container *ngIf="optionObj.type == 'followMe'">
        <div class="col radio-button">
            <input #withMap class="radio-button withMap" type="radio" [value]="true" [(ngModel)]="optionObj.followMe.withMap" kendoRadioButton />
            <label class="radio-label withMap" [for]="withMap">{{'With Map' | label: uiSrv.langPack}}</label>
        
            <input #withoutMap class="radio-button withoutMap" type="radio" [value]="false" [(ngModel)]="optionObj.followMe.withMap" kendoRadioButton />
            <label class="radio-label withoutMap" [for]="withoutMap">{{'Without Map' | label: uiSrv.langPack}}</label>
        </div>
        <uc-dropdown *ngIf="optionObj.followMe.withMap" lab="Map" [(value)]="optionObj.followMe.map" [options]="dropdownOptions.maps" [allowClear]="false"></uc-dropdown>
    </ng-container>

    <ng-container *ngIf="optionObj.type == 'stop'">
        <input #immediate class="radio-button immediate" type="radio" [value]="false"
            [(ngModel)]="optionObj.stop.finishMovement" kendoRadioButton />
        <label class="radio-label stop-immediate" [for]="immediate">{{'Stop Immediately' | label: uiSrv.langPack}}</label>

        <input #smooth class="radio-button smooth" type="radio" [value]="true"
            [(ngModel)]="optionObj.stop.finishMovement" kendoRadioButton />
        <label class="radio-label stop-smooth" [for]="smooth">{{'Stop at next way point' | label: uiSrv.langPack}}</label>
    </ng-container>

    <ng-container *ngIf="optionObj.type == 'charge'">
        <uc-txtbox lab="Upper Limit (%)" [(value)] = "optionObj.charge.upperLimit" [numeric] = 'true' [numMin] = '0' [numMax] = '100'></uc-txtbox>
        <uc-txtbox lab="Duration (Minute)" [(value)] = "optionObj.charge.duration" [numeric] = 'true' [numMin] = '0' ></uc-txtbox>
    </ng-container>

    <kendo-dialog-actions>
        <button kendoButton [disabled]="optionObj.type == 'followMe' && optionObj.followMe.withMap &&  !optionObj.followMe.map"
            (click)="optionObj.type == 'followMe' ? sendFollowMeRequestToRV() : 
                    (optionObj.type == 'stop' ?  sendStopRequestToRV() : 
                    (optionObj.type == 'charge' ? sendChargingRequestToRV() : null))">
            {{ 'OK' | label : uiSrv.langPack }}
        </button>
        <button kendoButton (click)="optionObj.type = null">{{ 'Cancel' | label : uiSrv.langPack }}</button>
    </kendo-dialog-actions>
</kendo-dialog>
