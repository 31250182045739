
<div *ngIf="robotSrv.data.taskActive.value && robotSrv.data.taskActive.value['taskItemList']" class="task-overlay">
    <div class="task-overlay">
        <div class="header">
         <span >
            {{  robotSrv.data.isMovingInTask.value ? 'Moving To' : 'Arrived' | label : uiSrv.langPack}}
         </span>
    </div> 

    <div class="content-panel" *ngIf="robotSrv.data.taskActive.value['taskItemList'][robotSrv.data.taskItemIndex.value]">
        <div class="progress">
            <div class="outerCircle"></div>
            <div class="innerCircle"></div>
            <div class="icon"></div>
            <div class="percent">
                <span class="value">{{ robotSrv.data.taskActive.value['taskItemList'][robotSrv.data.taskItemIndex.value]['movement']['waypointName'] | waypointName}}</span>
            </div>
        </div>   
    </div>

    
    <!-- THEY SAID : I DONT WANT TO SEE 0% ON 0314 DEMO !!! -->

    <!-- <div class="header">
        <span *ngIf="robotSrv.data.nextTaskAction.value">
            {{actions && actions[robotSrv.data.nextTaskAction.value] ? actions[robotSrv.data.nextTaskAction.value] : robotSrv.data.nextTaskAction.value }}
        </span>
    </div>
    
    <div class="content-panel">
        <kendo-scrollview *ngIf="uiSrv.isTablet" [data]="scrollViewIndices" [arrows]="!uiSrv.isTablet" [pageable]="true">
            <ng-template let-item="item">
                <ng-container *ngIf="item == 2">
                    <app-sa-top-module class="top-module"></app-sa-top-module>
                </ng-container>
                <ng-container *ngIf="item == 1">
                    <ng-container *ngTemplateOutlet="progress"></ng-container>
                </ng-container>
            </ng-template>
        </kendo-scrollview>
        <ng-container *ngIf="!uiSrv.isTablet">
            <ng-container *ngTemplateOutlet="progress"></ng-container>
        </ng-container>
    </div> -->

<div class="line-panel">
    <div *ngIf = "robotSrv.data.taskActive.value['taskItemList'].length > 5" class="steps">
        <span *ngIf="robotSrv.data.taskItemIndex.value > 2" class="first-node" [class]="robotSrv.data.taskItemIndex.value == 0 ? 'active' : ''">
            {{robotSrv.data.taskActive.value['taskItemList'][0]['movement']['waypointName'] | waypointName}}
        </span>
            
        <span *ngIf = "robotSrv.data.taskItemIndex.value - 1 > 1" class="dot-dot-dot">
            <span  class="mdi mdi-chevron-right" ></span>
            <span  class="name"> . . . </span>
        </span>
            
        <span  *ngIf="robotSrv.data.taskItemIndex.value == 2"  class="prev-2-node">
            <span *ngIf="robotSrv.data.taskItemIndex.value > 2"  class="mdi mdi-chevron-right" ></span>
            <span class="name">{{robotSrv.data.taskActive.value['taskItemList'][robotSrv.data.taskItemIndex.value - 2]['movement']['waypointName'] | waypointName}}</span>
        </span>
                
        <span  *ngIf="robotSrv.data.taskItemIndex.value != 0"  class="prev-node">
            <span  *ngIf="robotSrv.data.taskItemIndex.value >= 2"  class="mdi mdi-chevron-right"></span>
            <span class="name">{{robotSrv.data.taskActive.value['taskItemList'][robotSrv.data.taskItemIndex.value - 1]['movement']['waypointName'] | waypointName}} </span>
        </span>
                
        <span class="center-node active">
            <span  *ngIf="robotSrv.data.taskItemIndex.value >= 2 || robotSrv.data.taskItemIndex.value != 0"  class="mdi mdi-chevron-right" [class]="robotSrv.data.isMovingInTask.value ? 'moving' : ''"></span>
             <span class="name">{{robotSrv.data.taskActive.value['taskItemList'][robotSrv.data.taskItemIndex.value]['movement']['waypointName'] | waypointName}}</span>
        </span>
                
        <span *ngIf="robotSrv.data.taskItemIndex.value != robotSrv.data.taskActive.value['taskItemList'].length -  1"  class="next-node">
            <span  class="mdi mdi-chevron-right" ></span>
             <span class="name"> {{robotSrv.data.taskActive.value['taskItemList'][robotSrv.data.taskItemIndex.value + 1]['movement']['waypointName'] | waypointName}}</span>
        </span>
            
        <span *ngIf="robotSrv.data.taskItemIndex.value == robotSrv.data.taskActive.value['taskItemList'].length - 3"  class="next-2-node">
            <span  class="mdi mdi-chevron-right" ></span>
            <span class="name"> {{robotSrv.data.taskActive.value['taskItemList'][robotSrv.data.taskItemIndex.value + 2]['movement']['waypointName'] | waypointName}}</span>
        </span>
                        
        <span *ngIf = "robotSrv.data.taskItemIndex.value + 1 < robotSrv.data.taskActive.value['taskItemList'].length - 2" class="dot-dot-dot">
            <span  class="mdi mdi-chevron-right"></span>
            <span class="name"> . . .</span>
        </span>
            
        <span *ngIf="robotSrv.data.taskItemIndex.value < robotSrv.data.taskActive.value['taskItemList'].length - 3" class="last-node" 
               [class]="robotSrv.data.taskItemIndex.value == robotSrv.data.taskActive.value['taskItemList'].length - 1 ? 'active' : ''">
            <span  class="mdi mdi-chevron-right"></span>
            <span class="name">{{robotSrv.data.taskActive.value['taskItemList'][robotSrv.data.taskActive.value['taskItemList'].length - 1]['movement']['waypointName'] | waypointName}}</span>
        </span>
    </div>
            
    <div *ngIf = "robotSrv.data.taskActive.value['taskItemList'].length <= 5 && robotSrv.data.taskActive.value['taskItemList'].length > 1 " class="steps" >
        <span *ngFor = "let taskItem of robotSrv.data.taskActive.value['taskItemList'] ; let i = index" [class]="robotSrv.data.taskItemIndex.value == i ? 'active' : ''">
            <span *ngIf="i != 0"  class="mdi mdi-chevron-right" [class]="robotSrv.data.taskItemIndex.value == i  && robotSrv.data.isMovingInTask.value ? 'moving' : ''"></span>
               {{taskItem['movement']['waypointName'] | waypointName}}
        </span>
    </div>
</div>

</div>

</div>

<div *ngIf="!robotSrv.data.taskActive.value || !robotSrv.data.taskActive.value['taskItemList']" class="no-task">
    {{'No Executing Task' | label: uiSrv.langPack}}
</div>

<ng-template #progress>
    <div class="progress">
        <div class="outerCircle"></div>
        <div class="innerCircle"></div>
        <div class="icon"></div>
        <div class="percent">
            <span class="value">{{robotSrv.data.taskProgress.value}}</span>
            <span class="suffix"> % </span>
        </div>
    </div>   
</ng-template>