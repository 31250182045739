import { Injectable } from '@angular/core';
import { MqService } from './mq.service'
import { DataService } from './data.service'
import { BehaviorSubject } from 'rxjs';
// @ts-ignore
@Injectable({
    providedIn: 'root'
})
export class IotService {
    iotStore: {
        IEQ: { [key: string]: IeqState },
        LIGHT_CURTAIN: { [key: string]: IotState },
        CAMERA: { [key: string]: IotState },
        DOOR: { [key: string]: IotState },
    } = {
        IEQ: {},
        LIGHT_CURTAIN :{},
        CAMERA :{},
        DOOR : {}
    }
    constructor(public dataSrv: DataService) {

    }

    iotState(iotType: string, iotCode: string): IotState {
        if(!this.iotStore[iotType]?.[iotCode]){
           if(!this.iotStore[iotType]){
            this.iotStore[iotType] = {}
           }
           this.iotStore[iotType][iotCode] = iotType == 'IEQ' ? new IeqState(this.dataSrv , iotType , iotCode ) : new IotState( this.dataSrv , iotType , iotCode )
        } 
        return this.iotStore[iotType][iotCode]
    }
}

export class IotState {
    iotCode: string
    iotType: string
    status = new BehaviorSubject<string>(null)
    dataSrv : DataService
    constructor(dataSrv :DataService , iotType : string , iotCode : string ) {
        this.iotCode = iotCode
        this.iotType =  iotType
        this.dataSrv = dataSrv
    }
}

export class IeqState extends IotState {
    constructor(dataSrv :DataService , iotType : string , iotCode : string ) {
        super(dataSrv , iotType , iotCode )
        this.init_shw_demo()
    }

    airQualityOverall = new BehaviorSubject<any>(null)
    airQualityDetail = new BehaviorSubject<any>(null)
    ieq = new BehaviorSubject<any>(null)

    updateAirQuality(ieq, levels = null, range = null) {
        let overall = null
        let detail = {}
        levels = levels ? levels : ['Inadequate', 'Poor', 'Fair', 'Good', 'Excellent']
        range = range ? range : {
            temp: [[15, 24], [16, 23], [17, 22], [18, 21]],
            rh: [[10, 90], [20, 80], [30, 70], [40, 60]],
            co: [[null, 7000], [null, null], [null, 1000], [null, 0]],
            no2: [[null, 400], [null, null], [null, 200], [null, null]],
            co2: [[null, 1800], [null, 1500], [null, 800], [null, 600]],
            tvoc: [[null, 1000], [null, 500], [null, 300], [null, 100]]
        }
        for (let i = 0; i < levels.length - 1; i++) {
            Object.keys(range).forEach(k => {
                let limits = range[k][i]
                if (limits != undefined && !isNaN(Number(ieq[k])) && ((limits[0] != null && Number(ieq[k]) < limits[0]) || (limits[1] != null && Number(ieq[k]) > limits[1]))) {
                    overall = overall == null ? levels[i] : overall
                    detail[k] = detail[k] == undefined ? levels[i] : detail[k]
                }
            })
        }
        Object.keys(range).filter(k => !Object.keys(detail).includes(k)).forEach(k => detail[k] = levels[levels.length - 1])
        this.airQualityOverall.next(overall == null ? levels[levels.length - 1] : overall)
        this.airQualityDetail.next(detail)
        this.ieq.next(ieq)
    }

    async init_shw_demo(){
        const ieq = await this.dataSrv.httpSrv.fmsRequest('GET', 'iot/v1/ieqSensorEvent' , undefined, false);
        this.updateAirQuality(ieq[0])
    }
}

