<div class="header header-bg">
    <div class="nav-container">
        <a class="logo" (click)="navigateToHome()"></a>

        <div class="title">
            <!-- <h1> {{ uiSrv.isTablet? util.config['STANDALONE_ROBOT_ID'] : ('Agnostic Robotic Control System (ARCS)' | label : uiSrv.langPack ) }} </h1> -->
            <h1> {{ app == 'STANDALONE'? (dataSrv.robotProfile? dataSrv.robotProfile.robotCode : '')   : ('Agnostic Robotic Control System (ARCS)' | label : uiSrv.langPack ) }} </h1>
        </div>

        <div class="report-exporting" *ngIf="dataSrv.generatingReport">
            <a class="k-icon mdi mdi-file-download-outline"  (click)="router.navigate(['/home'] , {queryParams : {selectedTab : 'reportExport'}})" [matTooltip] = "('Generating Report '| label : uiSrv.langPack)  + ( dataSrv.generatingReport.progress!=null ?  ( ' : ' + dataSrv.generatingReport.progress + '%') : '' )"></a>
        </div>

        <div class="alert topnav" *ngIf="mqSrv.data.unreadMsgCnt.value > 0" [matTooltip]="('You have' | label : uiSrv.langPack  ) + ' ' + mqSrv.data.unreadMsgCnt.value + ' ' + ('unread notification' | label : uiSrv.langPack )">
            <a class="k-icon mdi mdi-alert"  (click)="router.navigate([ util.standaloneApp ? '/control' : '/home'] , {queryParams : {selectedTab : 'robotEvent'}})">
                <kendo-badge rounded="medium" themeColor="warning" position="edge" [align] = "{ vertical: 'bottom', horizontal: 'end' }">{{mqSrv.data.unreadMsgCnt.value}}</kendo-badge>
            </a>
        </div>

        
        <div class="bg-job topnav" *ngIf="util.arcsApp && (mqSrv.data.arcsSyncLog.value.length > 0  || mapSrv.outSyncFloorPlans.length > 0) ">
            <kendo-menu #syncMenu [items]="[{id:'jobStatus' , items: syncMenuItems}]" class="job-menu" (mouseover)="syncMenuOpened ? null :  openSyncMenu(); " (close)="onSyncMenuClose()"> 
                 <!-- (open)="updateUnreadCount()" -->
                <ng-template kendoMenuItemTemplate let-item="item">
                    <a *ngIf="item.id == 'jobStatus'" class="k-icon mdi mdi-cached" >
                        <kendo-badge *ngIf="dataSrv.unreadSyncMsgCount.value +  mapSrv.outSyncFloorPlans.length > 0" rounded="medium" themeColor="info" position="edge" 
                                            [align] = "{ vertical: 'bottom', horizontal: 'end' }">
                            {{dataSrv.unreadSyncMsgCount.value + mapSrv.outSyncFloorPlans.length }}
                        </kendo-badge>
                    </a>
                    <button kendoButton *ngIf="item.dataSyncId != null" (click)="router.navigate(['/user'] , {queryParams :  {selectedTab : 'synclog'}})" class="sync"> 
                        <span [class]="item.dataSyncType == 'EXPORT' ? 'k-icon mdi mdi-cloud-download sync' : ( item.dataSyncType == 'IMPORT' ? 'k-icon mdi mdi-cloud-upload' : '')"></span>
                        <span class="object-type">{{item.objectType | enum : true }}</span>
                        <span class="object-code">{{item.objectCode}}</span>
                        <span class="robot-code">{{item.robotCode}}</span>
                        <span [class]="'status ' + (item.dataSyncStatus | cssClassName )">{{item.dataSyncStatus}}</span>
                    </button>
                    <button kendoButton *ngIf="item.type == 'SyncAlert'" (click)="router.navigate(['/setup'] , {queryParams :  {selectedTab : 'floorplan'}})" class="alert">  
                        <span class="mdi mdi-exclamation-thick alert"></span>
                        <span class="text">{{ 'Floor Plan [' + item.floorPlanCode + '] need to be updated' | label : uiSrv.langPack}}</span>
                    </button>
                </ng-template>
            </kendo-menu>
        </div>


        <div class="profile topnav" *ngIf = "authSrv.username">
            <kendo-menu [items]="[{id:'user' , items: util.standaloneApp ? [{id: 'lang'} , {id:'pw'} , {id:'wifi'}] :[{id: 'lang'} , {id:'pw'} ]}] "  #settingMenu (close) = "langMenuOpened ? $event.preventDefault() : null">
                <!-- langMenuOpened ? $event.preventDefault() : null  -->
                <ng-template kendoMenuItemTemplate let-item="item">
                    <ng-container *ngIf="item.id == 'user'">
                        <span class="k-icon k-i-user k-icon-md"></span>
                        <span *ngIf="!authSrv.isGuestMode">{{authSrv.username}}</span>
                        <span *ngIf="authSrv.isGuestMode">{{ 'Guest' |label: uiSrv.langPack}}</span>
                    </ng-container >
                    <div *ngIf="item.id == 'lang'" class ="menu-lang" (click)="$event.stopPropagation()">
                        <uc-dropdown  [options]="uiSrv.langOptions"  [value]="uiSrv.lang.value"  (open)="langMenuOpened = true" prefixIconClass = "mdi mdi-earth"
                                      (change) = " uiSrv.changeLang($event.currentValue) ;" (close)= "closeMenu()" [allowClear]="false"></uc-dropdown>
                                      <!-- (close)="closeMenu()"  -->
                        <!-- <kendo-splitbutton [data]="uiSrv.langOptions" #langButton  (open)="langMenuOpened = true" (close) = "langMenuOpened = false ; settingMenu.toggle(false , '0') "
                                              (itemClick) = "uiSrv.changeLang($event.value);settingMenu.toggle(false , '0')">
                                    <span class="mdi mdi-earth"></span>
                                    <span>{{uiSrv.selectedLangOption['text']}}</span>
                        </kendo-splitbutton> -->
                    </div>
                    <button  kendoButton *ngIf="item.id == 'pw' && !authSrv.isGuestMode" class ="menu-pw" (click)="showDialog('pw')" >
                        <span class="k-icon k-i-lock k-icon-md"></span>
                        <span>{{'Change Password' | label : uiSrv.langPack}}</span>
                    </button>

                    <button kendoButton *ngIf="item.id == 'wifi'&& !authSrv.isGuestMode" class ="menu-wifi" (click)="showDialog('wifi')" >
                        <span class="mdi mdi-wifi"></span>
                        <span>{{'Wifi' | label : uiSrv.langPack}}</span>
                    </button>

                </ng-template>
            </kendo-menu>
        </div>
        
        <div class="settings topnav" *ngIf = "!uiSrv.isTablet && authSrv.username && hasUserManagementRight">
            <a  [class]=" 'k-icon k-i-cog k-icon-md ' + (routeSrv.currentUrl == 'user' ? 'selected' : '')" (click)='router.navigate(["user"])'></a>
        </div>
        
        <div class="logout topnav" *ngIf = "authSrv.username ">
            <a *ngIf=" !authSrv.isGuestMode" class="k-icon k-i-logout k-icon-md" (click) = "logout()"></a>
            <a *ngIf=" authSrv.isGuestMode" class="k-icon k-icon-md mdi mdi-power" (click) = "showDialog('login')"></a>
        </div>
        


    </div>
</div>
<!-- 
<mat-menu #setting="matMenu">
    <button mat-menu-item  [matMenuTriggerFor]="lang">Language</button>
</mat-menu>

<mat-menu #lang = "matMenu">
    <button mat-menu-item (click) = "uiSrv.changeLang('en')">
        English
        <mat-icon *ngIf="uiSrv.lang.value == 'en'">done</mat-icon>
    </button>
    <button mat-menu-item (click) = "uiSrv.changeLang('zh')">
        繁體中文
        <mat-icon *ngIf="uiSrv.lang.value == 'zh'">done</mat-icon>
    </button>
</mat-menu> -->