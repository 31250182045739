<div class="dialog-content map-floorplan">
    <kendo-dialog-titlebar [style.display]="uiSrv.isTablet?'none':''" (close) = "onClose()">
        <div>{{'Floor Plan' | label: uiSrv.langPack}}</div>
    </kendo-dialog-titlebar>


    <kendo-tabstrip #tabStrip (tabSelect)=" selectedTabChanged( $event.index )" [class] = "uiSrv.isTablet? 'vertical' : ''">
        <kendo-tabstrip-tab [title]=" ( util.standaloneApp ? 'Map' : 'Maps') | label : uiSrv.langPack " [selected]="true">
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab  [title]="'Locations' | label : uiSrv.langPack">        
        </kendo-tabstrip-tab>
        <!-- <kendo-tabstrip-tab *ngIf="util.arcsApp" [title]="'Restricted Zone' | label : uiSrv.langPack">        
        </kendo-tabstrip-tab> -->
        <!-- <kendo-tabstrip-tab *ngIf="util.arcsApp && parentRow" [title]="'3D Model' | label : uiSrv.langPack" [disabled] = "frmGrp.controls['fileName'].value == null">        
        </kendo-tabstrip-tab> -->
    </kendo-tabstrip>


    <div class="container"  #container>      
        <ng-container *ngIf="!uiSrv.isTablet && (selectedTab == 'maps' || selectedTab == 'locations' ||  selectedTab == 'zones') ">
            <!-- / -->
            <div class="form-container">
                <ng-container *ngTemplateOutlet="form">
                </ng-container>
            </div>
        </ng-container>
        <!-- [style.minWidth] = "selectedTab == 'locations'? '100%' : ''" -->
        <div class="viewport-container" #pixiContainer [style.display] = "selectedTab == 'maps' || selectedTab == 'locations' || selectedTab == 'zones' ? '' : 'none'"> 
            <uc-drawing-board  #pixi [overlayMsg] = "frmGrp.controls['fileName'].value? null : ('Please upload the floor plan file first' | label : uiSrv.langPack) " 
                              [readonly] = "readonly" [waypointEditable] = "true" [showWaypointType] = "showWaypointType"
                              (graphicsUnselected) = "$event.type == 'waypoint' ? onPointUnselected($event) : null" 
                              [showToggles] = "{ros : util.standaloneApp}" (addLocalWayPointClicked) = "getPoseAndAddWaypoint_SA()"
                              [canUploadMultiImg] = "false" [hideButton] = "{all:true , relocation : true}" [(selectedGraphics)] = "selectedPixiShape"
                              (fileLoad)=" frmGrp.controls['fileName'].setValue($event?.name)"
                              [polygonType] = "'zone'">
            </uc-drawing-board>
        </div>   
        <!-- <div class="three-d-container"  [style.display] = "selectedTab == '3d'? '' : 'none'" > 
            <uc-3d-viewport #threeJs *ngIf="model3dFile" [floorPlanDataset]="floorPlanDsFor3D">
            </uc-3d-viewport>
        </div>          -->
    </div>

    <ng-container *ngIf="uiSrv.isTablet">
        <div class="form-container bottom">
            <ng-container *ngTemplateOutlet="form">
            </ng-container>
        </div>
    </ng-container>

    <!-- <div>{{'Floor Plan' | label: uiSrv.langPack}}</div> -->
    <div class="button-container">
        <button kendoButton (click) = "onClose()"> {{'Cancel' | label : uiSrv.langPack}}</button>
        <button *ngIf="!readonly" kendoButton (click) = "saveToDB()"  [disabled] = "!frmGrp.controls['fileName'].value"> {{'Save' | label : uiSrv.langPack}}</button>
    </div>
</div>

<ng-template #form>
    <form [formGroup]="frmGrp" class="content">
        <uc-txtbox lab="Floor Plan File" [frmGrp]="frmGrp" frmCtrl="fileName"
            [suffixButton]="{icon : 'k-icon mdi mdi-upload'}"
            (suffixButtonClick)="pixiElRef? pixiElRef.uploader.nativeElement.click() : null ; ">
        </uc-txtbox>
        <uc-txtbox lab="Code" [frmGrp]="frmGrp" frmCtrl="floorPlanCode" [upper] = "true" [disabled] = "parentRow"></uc-txtbox>
        <uc-txtbox lab="Floor Plan Name" [frmGrp]="frmGrp" frmCtrl="name" dbTable="floorPlan"></uc-txtbox>
        <uc-dropdown *ngIf="util.arcsApp" lab="Building" [frmGrp]="frmGrp" frmCtrl="buildingCode" [options] = "dropdownOptions.buildings"></uc-dropdown>
        <uc-dropdown *ngIf="util.standaloneApp" lab="Map" [options]="dropdownOptions.maps" [disabled]="readonly || !frmGrp.controls.fileName.value"
            [(value)]="mapCode" (valueChange)="onMapCodeSelected()">
        </uc-dropdown>
        <uc-dropdown *ngIf="util.arcsApp" lab="Floor" [frmGrp]="frmGrp" frmCtrl="floor" [options] = "dropdownOptions.floors"  (valueChange) = "refreshLiftOptions()"></uc-dropdown>

        <div class="col default-checkbox building" *ngIf="util.arcsApp" >
            <input type="checkbox" kendoCheckBox formControlName="defaultPerBuilding" [disabled]="readonly" />
            <span>{{'Default Floor Plan' | label : uiSrv.langPack}}</span>
        </div>
        
        <div *ngIf="util.arcsApp" class="form-group treeview">
            <label class="col-form-label">{{'Map' | label: uiSrv.langPack}}</label>
            <div class="map-tree k-textbox"> 
                <kendo-treeview *ngIf="util.arcsApp"  [nodes]="mapTree.data" [isExpanded] = "mapTree.isExpanded"                                  
                                 (expand)="mapTree.addExpandedKeys($event);" (collapse)="mapTree.removeExpandedKeys($event)"
                                 textField="name" kendoTreeViewHierarchyBinding childrenField="robotBases">
                     <ng-template kendoTreeViewNodeTemplate let-dataItem let-index="index">
                        <div [class]="'item' + (((dataItem.robotBases &&  mapCode == dataItem.mapCode)|| (selectedPixiShape && selectedPixiShape.robotBase == dataItem.robotBase && selectedPixiShape.mapCode == dataItem.mapCode)) ? ' selected' : '')">
                            <input *ngIf="dataItem.robotBases" type="checkbox" kendoCheckBox [ngModelOptions]="{standalone: true}"  [disabled]="readonly"
                            [ngModel] = "mapCode == dataItem.mapCode" (ngModelChange) = "mapCode = $event? dataItem.mapCode : null;
                                                                                         mapTree.refreshExpandedKeys() ;
                                                                                         onMapCodeSelected() "/>
                            <a *ngIf="dataItem.robotBase && mapCode == dataItem.mapCode" [class]="'mdi mdi-eye' + (dataItem['hidden']? ' invisible' : ' visible')"  (click)="toggleMapVisibility(dataItem)"></a>
                            <a *ngIf="dataItem.robotBase && mapCode == dataItem.mapCode" class="name" (click)="refreshselectedGraphics(dataItem)">{{ dataItem.name }}</a>
                            <span *ngIf="!(dataItem.robotBase && mapCode == dataItem.mapCode)" class="name">{{ dataItem.name }}</span>
                            <span *ngIf="dataItem.robotBase && mapCode == dataItem.mapCode && dataItem.alert" class="mdi mdi-exclamation-thick" [matTooltip]="'Please save the floor plan to enable this map'| label: uiSrv.langPack">

                            </span>
                        </div>
                     </ng-template>
                </kendo-treeview>
            </div>
        </div>

        

        <!-- <uc-dropdown  lab = "Building" [frmGrp] = "frmGrp" frmCtrl = "buildingId" [options] = "dropdownOptions.buildings">
                </uc-dropdown>
                 -->
        <!-- [noDataMsg]="frmGrp.controls['siteId'].value ? null : ('Please select the site first' | label : uiSrv.langPack) " -->

        <!-- <div class="map-container col" *ngIf="!util.standaloneApp && frmGrp.controls['fileName'].value"
            [style.display]="selectedTab == 'maps'? '' : 'none'">
            <div class="form-group">
                <label class="col-form-label">{{'Map' | label: uiSrv.langPack}}</label>
                <div class="map-content-container k-textboxmap-content-container k-textbox">
                    <div class="map-content" *ngFor="let map of dropdownData.maps"
                        [class]="selectedMapIds.includes(map['mapCode'])? 'checked' :''">
                        <input type="checkbox" [checked]="selectedMapIds.includes(map['mapCode'])"
                            (change)="toggleMap(map['mapCode'], $event.target.checked , $event.target)" kendoCheckBox />
                        <span (click)="refreshselectedGraphics(map['mapCode'])"
                            [class]="selectedPixiShape?.id == map['mapCode']? 'selected-map-id map-name' :'map-name'">{{map['name']}}</span>
                    </div>
                </div>
            </div>
        </div> -->
        <div *ngIf="util.standaloneApp">
            <button kendoButton class="export-btn" (click) = "exportDatasetAsTextFile()">{{'Export Data' | label: uiSrv.langPack}}</button>
            <button kendoButton class="import-btn" (click) = "onImportClicked()">{{'Import Data' | label: uiSrv.langPack}}</button>
        </div>
    </form>
</ng-template>

<input hidden type="file" #txtUploader (change)="importDatasetFromTextFile($event)" #input accept=".txt"/>

