<div *ngIf="uiSrv.isTablet && (robotSrv.data.estop.value || robotSrv.data.obstacleDetected.value || robotSrv.STANDALONE.state.tiltActive.value)" 
     [class]="'warning background ' +  (expanded ? 'expanded' : 'collasped')">
    <div [class]="'wrapper ' +  (expanded ? 'expanded' : 'collasped')">
        <kendo-expansionpanel [(expanded)] = "expanded">
            <ng-template kendoExpansionPanelTitleDirective>
                <div id="alert">
                    <div class="alertbar"></div>
                    <div class="alertcontent">
                        <div class="alerticon">
                            <i class="icon"> </i>
                        </div>
                        <div class="alerttext" href="#alert">
                    <span class="header">{{'Warning' | label: uiSrv.langPack}}</span>
                            <span class="text " *ngIf="robotSrv.data.estop.value">{{ 'Emergency Stop Switched On' | label: uiSrv.langPack}}</span>
                            <span class="text " *ngIf="robotSrv.data.obstacleDetected.value">{{ 'Obstacle Detected' | label: uiSrv.langPack}}</span>
                            <span class="text" *ngIf="robotSrv.STANDALONE.state.tiltActive.value">{{ 'Excess tilt detected' | label:uiSrv.langPack}}</span>
                        </div>
                    </div>
                    <div class="alertbar"> </div>
                </div>
            </ng-template>           
        </kendo-expansionpanel>
    </div>
</div>

<!-- [title]="!expanded? (('Warning' | label: uiSrv.langPack) + 
                                        (((robotSrv.data.estop.value?  (' : ' + 'Emergency Stop Switched On') : '')| label: uiSrv.langPack) +
                                         ((robotSrv.data.obstacleDetected.value? ( ' : ' + 'Obstacle Detected') : '' )| label: uiSrv.langPack) +
                                         ((robotSrv.data.tiltActive.value? (' : ' + 'Excess tilt detected') : '')| label: uiSrv.langPack)
                                        )  
                                       ):''
                                      " -->