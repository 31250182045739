<ng-template #dashboarodNestedItems let-items="items">
    <div class='bl-box'>
        <ng-container *ngFor="let item of items">
            <ng-container *ngTemplateOutlet="dashboarodItem; context : {item : item}"></ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #dashboarodItem let-item ="item">
    <div class='bl-item' [class]="(item['id'] | cssClassName) + (item['type'] == 'button' ? ' bl-button' : '') + (ds[item['id']]?.cssClass ? (' ' + (ds[item['id']]?.cssClass | cssClassName)): '')">
        <ng-container >
            <div class="bl-icon"><i class="k-icon mdi" [ngClass]="ds[item['id']] && ds[item['id']].icon? ds[item['id']].icon : (item.id | cssClassName : 'ts-')"></i></div>
            <div class="bl-text">
                <label class="bl-label">{{ds[item['id']]?.title | label : uiSrv.langPack}}</label>
                <span class="bl-result">{{(ds[item['id']]?.content ? ds[item['id']].content :  
                                           ( ds[item['id']]?.mqfld!=null &&  ds[item['id']]?.mqfld!=undefined && ds[item['id']].mq?.value? 
                                             ds[item['id']].mq?.value[ds[item['id']].mqfld] : 
                                             ds[item['id']]?.mq?.value ) 
                                          ) | label : uiSrv.langPack }}</span>
                <span class="bl-unit">{{ (ds[item['id']]?.suffixmq && ds[item['id']]?.suffixmq.value ?  ds[item['id']]?.suffixmq.value : ds[item['id']]?.suffix ) | label : uiSrv.langPack }}</span>
            </div>   
        </ng-container>
    </div>
</ng-template>

<!-- ============================================================================================================================================================================================================================== -->
    
    <kendo-dialog-titlebar (close) = "dialogRef.close()" [class]="parent? '' : 'iot-title' ">
        <div>{{robotSrv.ARCS.robotStore[robotId].robotName }}</div>
    </kendo-dialog-titlebar>

    <ng-container  *ngIf="parent">
        <div class="card-header-wrapper" >
            <!-- title -->
            <h3 class="card-title"></h3>
            <!-- tab buttons -->
            <div class="card-buttons">
                <kendo-buttongroup #buttonGroup selection="single">
                    <button *ngFor="let tab of tabs" kendoButton [toggleable]="true" [selected]="selectedTab == tab.id" (click) = "selectedTab = tab.id ; selectedTab == 'camera' ? getStreamingUrl() : null;" [disabled] = "tab.id!='info' && ds.status.content == ARCS_STATUS_MAP.UNKNOWN "> 
                        {{ tab.label | label : uiSrv.langPack }}
                    </button>
                </kendo-buttongroup>
            </div>
            <span></span>
        </div>
        
        <app-sa-top-module *ngIf="robotType && selectedTab == 'topModule'"  [arcsRobotType] = "robotType"  [arcsRobotSubType] = "robotSubType"  [arcsRobotCode] = "robotId"></app-sa-top-module>    
        <ng-container *ngIf="selectedTab == 'info'">
            <kendo-tilelayout [columns]="2" [resizable]="false" [reorderable]="false">
                <ng-container *ngFor = "let row of dashboardLayout ; let ri = index">
                    <kendo-tilelayout-item *ngFor = "let cell of row ; let ci = index" [class]="(cell.id | cssClassName : 'ts-') + ' tile' + (cell.rowSpan ? cell.rowSpan : 1) + '-' + (cell.colSpan ? cell.colSpan : 1)" 
                                               [col]="( cell['col'] ? cell['col'] : ci) " [row]="cell['row'] ? cell['row'] : (ri + 1)" 
                                               [colSpan]="cell['colSpan']? cell['colSpan']: 1"  [rowSpan]="cell['rowSpan']? cell['rowSpan']: 1">
                        <kendo-tilelayout-item-body>
                            <ng-container *ngFor = "let item of (cell['cells'] ? cell['cells'] : [cell])" >
                                <ng-container *ngTemplateOutlet = "item['cells']? dashboarodNestedItems : dashboarodItem; context : {item : item , items : item['cells']}" ></ng-container>
                                <ng-container *ngIf="cell.id == 'status' && ds.status && (ds.status['robotStatus']  == 'HOLD' || ds.status['robotStatus']  == 'IDLE')"> 
                                    <button kendoButton [class]="ds.status['robotStatus']  == 'HOLD' ? 'release' : 'reserve'" (click)="ds.status['robotStatus']  == 'HOLD'? releaseRobot() : reserveRobot() "> 
                                        {{ (ds.status['robotStatus']  == 'HOLD' ? 'Release' : 'Reserve') | label : uiSrv.langPack }}
                                    </button>
                                </ng-container>   

                                <div class="task-summary" *ngIf="(cell.id == 'status' && robotState.executingTask.value?.name?.length > 0) || (cell.id == 'status' && jobRef?.length > 0)" >
                                    <div *ngIf="cell.id == 'status' && robotState.executingTask.value?.name?.length > 0 "> 
                                        {{ 'Current Task : '| label : uiSrv.langPack }} {{robotState.executingTask.value?.name}}
                                    </div>     
                                    <div *ngIf="cell.id == 'status' && jobRef?.length > 0 "> 
                                        {{ 'Current Job Reference : '| label : uiSrv.langPack }} {{jobRef}}
                                    </div>  
                                </div>   
                                
                                <div *ngIf="cell.id == 'status' && alertMsg && alertMsg.length > 0" class="error alert-container">
                                    <span class="mdi mdi-alert"></span>
                                    <span class="alert-msg">{{alertMsg | label : uiSrv.langPack}}</span>
                                </div>                     
                            </ng-container>   
                        </kendo-tilelayout-item-body>      
                    </kendo-tilelayout-item>
                </ng-container>
            </kendo-tilelayout>       
        </ng-container>    
    
      
        <ng-container *ngIf="selectedTab == 'camera'">
            <ng-container *ngIf="util.config.USE_AZURE_MEDIA" >
                <uc-video-player *ngIf="!streamingError && streamingUrl" [isAzure]="true" [src] = "streamingUrl" ></uc-video-player>
                <span *ngIf="streamingError">{{streamingError |  label : uiSrv.langPack}}</span>
            </ng-container>
    
            <kendo-tilelayout *ngIf="!util.config.USE_AZURE_MEDIA" [columns]="3" [resizable]="false" [reorderable]="false">
                <ng-container *ngFor = "let row of cameraLayout ; let ri = index">
                    <kendo-tilelayout-item *ngFor = "let cell of row ; let ci = index" [class]="(cell.id | cssClassName : 'ts-') + ' tile' + (cell.rowSpan ? cell.rowSpan : 1) + '-' + (cell.colSpan ? cell.colSpan : 1)" 
                            [col]="( cell['col'] ? cell['col'] : ci) " [row]="cell['row'] ? cell['row'] : (ri + 1)" 
                            [colSpan]="cell['colSpan']? cell['colSpan']: 1"  [rowSpan]="cell['rowSpan']? cell['rowSpan']: 1">
                            <a *ngIf="cell['colSpan'] == null || cell['colSpan'] == 1" (click)="switchMainCamera(cell)" class="mdi mdi-overscan switch-camera-btn"></a>
                            <uc-video-player #videoPlayer *ngIf="cell.streamingUrl"  [isAzure]="false" [src] = "cell.streamingUrl"></uc-video-player>
                    </kendo-tilelayout-item>
                </ng-container>
            </kendo-tilelayout>     
        </ng-container>
    
    </ng-container>

<!-- ============================================================================================================================================================================================================================== -->

<div class="iot" *ngIf="!parent">
    <span class="iot-id">
       <i class="mdi mdi-robot"></i>
       <span> {{robotId}} </span>
    </span>
    <div *ngIf="robotSubj" class="iot-content">
        <div class="iot-row">
            <span class="status"  *ngIf="ds.status?.content"> 
                <i [class]="'status mdi mdi-circle ' +  ( ds.status?.content ? (ds.status?.content | cssClassName) : '')  " ></i> 
                <span>{{ds.status.content | label : uiSrv.langPack}}</span>
            </span>
    
            <span class="battery" *ngIf="ds.battery.mq?.value">
                <i [class]="'mdi mdi-battery' + ( ds.battery.mq?.value == 100 ? '' : ('-' + ( ds.battery.mq?.value < 10 ? '10' : ((ds.battery.mq?.value / 10) | roundDown) * 10))) "></i> 
                <span>{{ds.battery.mq?.value}}%</span>
            </span>
        </div>

        <div class="iot-row" [style.display] = "robotSubj.obstacleDetected?.value ||  robotSubj.tiltActive?.value || robotSubj.estop?.value ? 'none' : null">
            <app-sa-top-module *ngIf="robotType" [isPartOf3dTooltip] = 'true'  [arcsRobotType] = "robotType"  [arcsRobotSubType] = "robotSubType"  [arcsRobotCode] = "robotId"></app-sa-top-module>   
        </div> 

        <div *ngIf="robotSubj.status?.value != 'UNKNOWN' && robotSubj.destination?.value"  class="iot-row">         
            <span class="loading-dots">
                <i class="loading-dots--dot"></i>
                <i class="loading-dots--dot"></i>
                <i class="loading-dots--dot"></i>
            </span>

            <span class="destination">
                <i class="mdi mdi-map-marker"></i>
                <span>{{robotSubj.destination.value}}</span>
            </span>
        </div> 

        <div class="iot-row" *ngIf="robotSubj.obstacleDetected?.value || robotSubj.tiltActive?.value || robotSubj.estop?.value">
            <span class="warning">
                <i class="mdi mdi-alert"></i>
                <span>{{(robotSubj.estop?.value ? uiSrv.commonAlertMessages.estopped : (
                            robotSubj.tiltActive?.value ?  uiSrv.commonAlertMessages.tiltDetected : uiSrv.commonAlertMessages.obstacleDetected
                            )
                         ) | label : uiSrv.langPack}}
                </span>
            </span>
        </div>
    </div>
</div>
<!-- 
 -->