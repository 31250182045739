<div class="iot-container" (mouseenter)="mouseOver = true" (mouseleave)="mouseOver = false"
    (contextmenu)="showDetail = !showDetail">
    <ng-container *ngIf="!mouseOver && !showDetail">
        <span
            [class]="'icon-circle-bg ' + (iotSrv.iotStore[iotType][iotCode]?.airQualityOverall?.value | cssClassName) + ' ' + (iconMap[iotType]) +  ' ' + (iotType | cssClassName ) + ' ' + (iotSrv.iotStore[iotType][iotCode].status.value | cssClassName)"></span>
    </ng-container>

    <div [class]="'iot label-3js '" *ngIf="mouseOver || showDetail">
        <div [class]="selected ? ' selected' : ''">
            <span class="iot-id">
                <i [class]="iconMap[iotType]"></i>
                <span>{{name}}</span>
            </span>

            <ng-container *ngIf="!showNameOnly">
                <div class="iot-content"
                    *ngIf="iotType == 'IEQ' &&  iotSrv.iotStore[iotType][iotCode] && iotSrv.iotStore[iotType][iotCode]?.ieq?.value">
                    <span> {{'Air Quality' | label : uiSrv.langPack}} : </span>
                    <span class="value">{{iotSrv.iotStore[iotType][iotCode].airQualityOverall.value}}</span>
                </div>
            
                <div class="iot-content"
                    *ngIf="(iotType == 'DOOR' || iotType == 'LIGHT_CURTAIN') && iotSrv.iotStore[iotType][iotCode] && iotSrv.iotStore[iotType][iotCode]?.status?.value">
                    <span *ngIf="iotSrv.iotStore[iotType][iotCode] && iotSrv.iotStore[iotType][iotCode].status.value">
                        - {{iotSrv.iotStore[iotType][iotCode].status.value | enum}}
                    </span>
                </div>
            </ng-container>
        </div>
    </div>
</div>