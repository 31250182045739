<div #mainContainer [class] = " 'card-container ' + (robotType==null ?  '' :  robotType) + ' ' + (selectedTab == 'dashboard' ? 'home' : selectedTab)" >

    <div class="card-header-wrapper" >
        <!-- title -->
        <h3 class="card-title"></h3>
        <!-- tab buttons -->
        <div class="card-buttons">
            <kendo-buttongroup #buttonGroup selection="single" class = "btn-grp-main" >
                <button *ngFor="let tab of tabs" kendoButton [toggleable]="true"
                    [selected]="selectedTab == tab.id" 
                    (click)=" tab.id == selectedTab ? null : (selectedTab = tab.id) ;
                              selectedTab == 'dashboard' ? null : $stopSubscribeRobotStatus.next();
                              selectedTab == 'dashboard' ? initPixi() : (selectedTab == 'task' || selectedTab == 'group' ?  mqSrv.subscribeMQTTUntil('arcsRobotStatusChange' ,  null , $stopSubscribeRobotStatus ) : null) ;">
                    {{ tab.label | label : uiSrv.langPack }}
                </button>
            </kendo-buttongroup>
        </div>
        <span></span>
    </div>





<kendo-tilelayout *ngIf="selectedTab == 'dashboard'" class="first-tile-layout" [columns]="7" [resizable]="false" [reorderable]="false">
    <kendo-tilelayout-item class="ts-job" [title]="'Executing Tasks' | label: uiSrv.langPack" [col]="1" [row]="1">
        <kendo-tilelayout-item-body>
            <label class="ts-value">{{executingTaskCount}}</label>
            <label class="ts-total">/ {{totalTaskCount}}</label>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item class="ts-status" [title]="'Active Robots' | label: uiSrv.langPack" [col]="2" [row]="1">
        <kendo-tilelayout-item-body>
            <label class="ts-value">{{activeRobotCount}}</label>
            <label class="ts-total">/ {{totalRobotCount}}</label>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item *ngIf="!robotType" class="ts-progress" [title]="'Completed Tasks' | label: uiSrv.langPack"  [col]="1" [row]="2" [rowSpan] = "2" [colSpan] = "2" >
        <kendo-tilelayout-item-body >
            <div class="ts-resultbox">
                <div *ngFor="let r of  robotTypeInfos; index as i" class="ts-row bar-{{i+1}}">
                    <!-- {{ r.robotType | cssClassName}} -->
                    <label class="ts-robot-id tooltip"> 
                        <!-- [title]="r.robotType? r.robotType : '' " -->
                        <!-- (click)="openRobotDetailPopup(r.id)" -->
                        <i [class]="'mdi ' + iconMap[r.robotType] "></i>
                        <span>{{ r.name }} </span>
                    </label>
                           
                    <kendo-progressbar class="ts-progress-bar"  [max]="r.completedTaskCount + r.executingTaskCount + r.waitingTaskCount" [value]="r.completedTaskCount">
                    </kendo-progressbar>
                    <label class="ts-total">{{r.completedTaskCount + r.executingTaskCount + r.waitingTaskCount}}</label>
                </div>
            </div>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item  *ngIf="!robotType" class="ts-robottotal" [title]="'Total Robots' | label: uiSrv.langPack" [col]="1" [row]="4" [rowSpan] = "2" [colSpan] = "2" >
        <kendo-tilelayout-item-body id="tsrobottotalpatrol"> 
            <div class="ts-header">
                <label class="ts-robot-id"></label>
                <label class="ts-working">{{'Working' | label: uiSrv.langPack}}</label>
                <label class="ts-charging">{{'Charging' | label: uiSrv.langPack}}</label>
                <label class="ts-idle">{{'Idle' | label: uiSrv.langPack}}</label>
                <label class="ts-offline">{{'Offline' | label: uiSrv.langPack}}</label>
                <label class="ts-reserved">{{'Reserved' | label: uiSrv.langPack}}</label>
                <label class="ts-alert">{{'Alert' | label: uiSrv.langPack}}</label>
            </div>
            <div class="ts-resultbox"> 
                <div  *ngFor="let r of  robotTypeInfos; index as i" class="ts-row bar-{{i+1}} ">
                    <!-- {{ r.robotType | cssClassName }} -->
                    <label class="ts-robot-id" >
                        <!-- [title]="r.robotType? r.robotType : '' " -->
                        <i [class]="'mdi ' + iconMap[r.robotType] "></i>
                        <span>{{ r.name }}</span>
                    </label>
                    <label class="ts-working">{{r.processCount}}</label>
                    <label class="ts-charging">{{r.chargingCount}}</label>
                    <label class="ts-idle">{{r.idleCount}}</label>
                    <label class="ts-offline">{{r.offlineCount}}</label>
                    <label class="ts-reserved">{{r.reservedCount}}</label>
                    <label class="ts-alert">{{r.alertCount}}</label>
                </div>
            </div>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item  *ngIf="robotType" class="ts-robotlist" [title]="'Robots' | label: uiSrv.langPack" [col]="1" [row]="2" [rowSpan] = "4" [colSpan] = "2" >
        <kendo-tilelayout-item-body>
            <div class="ts-header">
                <label class="ts-robot-id"></label>
                <label class="ts-status">{{'Status' | label: uiSrv.langPack}}</label>
                <label class="ts-battery">{{'Battery' | label: uiSrv.langPack}}</label>
                <!-- <label class="ts-location">{{'Location' | label: uiSrv.langPack}}</label> -->
                <label class="ts-pending">{{'Pending' | label: uiSrv.langPack}}</label>
                <label class="ts-completed">{{'Completed' | label: uiSrv.langPack}}</label>
                <label class="ts-alert"></label>
            </div>
            <div class="ts-resultbox"> 
                <div  *ngFor="let r of robotInfos; index as i" class="ts-row bar-{{i+1}} {{ r.code | cssClassName }}">
                    <label class="ts-robot-id" [title]="r.code? r.code : '' ">
                        <a (click) = "selectRobot(r.robotCode)">{{ robotSrv.robotState(r.robotCode)?.robotName }}</a>
                    </label>
                    <label [class]="'ts-status ' + r.robotStatusCssClass" [matTooltip]="r.robotStatusCssClass | label : uiSrv.langPack" matTooltipPosition = "right">
                        <span class="mdi"></span>
                    </label>
                    <label class="ts-battery" [matTooltip] = "robotSrv.robotState(r.robotCode)?.batteryRounded?.value + '%'" matTooltipPosition = "right" >
                        <span [class]="'iot-battery mdi mdi-battery' + (robotSrv.robotState(r.robotCode)?.batteryRounded?.value == 100 ? '' : ('-' + (  robotSrv.robotState(r.robotCode)?.batteryRounded?.value < 10 ? '10' : (( robotSrv.robotState(r.robotCode)?.batteryRounded?.value / 10) | roundDown) * 10))) "></span> 
                    </label>
                    <!-- <label class="ts-location">{{r.floorPlanCode}}</label> -->
                    <label class="ts-pending">{{r.waitingTaskCount + r.executingTaskCount}}</label>
                    <label class="ts-completed">{{r.completedTaskCount}}</label>
                    <label class="ts-alert">
                        <span *ngIf="r.alert && r.alert.length > 0" class="mdi mdi-alert"  [matTooltip] = "r.alert | label : uiSrv.langPack " matTooltipClass="alert">
                        </span>
                    </label>
                </div>
            </div>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item class = "ts-map drag-boundary" [col]="3" [row]="1" [rowSpan] = "5" [colSpan] = "5">
        <div [class] = "use3DMap ? 'three-d-container' : 'viewport-container' " (wheel)="$event.preventDefault()" (contextmenu) = "use3DMap ? btmMapPanel.selectedMapObjChange(null) : null">
            <uc-drawing-board *ngIf="!use3DMap" #pixi [arcsParent] = "me" [showRobot] = "true" [hideButton] = "{ all : true}" [readonly] = "true" 
                                    [arcsRobotType] = "robotType"  [(selectedFloorPlanCode)]="selectedFloorPlanCode" 
                                    [arcsRobotColors]="robotIconColorMap" [arcsLocationTree] = "locationTree"
                                    (onSiteSelected) = "btmMapPanel.selectedMapObjChange(null); loadSite()" 
                                    (robotClicked)="btmMapPanel.selectedMapObjChange($event.robot.pixiGraphics) " 
                                    (selectedGraphicsChange) = "btmMapPanel.selectedMapObjChange($event)"
                                    (to3D)="btmMapPanel.selectedMapObjChange(null); dropdownOptions.floorplans = pixiElRef.module.data.dropdownOptions.floorplans ; locationTree = pixiElRef.module.site.locationTree ; use3DMap = true ;">
            </uc-drawing-board> 
            <uc-3d-viewport *ngIf="use3DMap" #threeJs [parent] = "me"  [arcsRobotType] = "robotType"
                            (to2D)="btmMapPanel.selectedMapObjChange(null); use3DMap = false ; initPixi($event.floorPlanCode , $event.showSite ) ;" 
                            (objClicked) = "btmMapPanel.selectedMapObjChange($event)"
                            [floorPlanOptions] = "dropdownOptions.floorplans" [robotColorMapping] = "robotIconColorMap"
                            [floorPlanDataset] = "currentFloorPlan" [locationTree] = "locationTree">
            </uc-3d-viewport>
            <!-- showRobotDetailDialog($event?.id) -->
        </div>
        <!-- <div *ngIf="use3DMap" class="three-d-container"  (contextmenu) = "btmMapPanel.selectedMapObjChange(null)">
          
        </div> -->
        <app-arcs-dashboard-map-panel [cdkDragBoundary]="threeJsElRef?.fullScreen ? '.full-screen-viewport' : '.drag-boundary'" cdkDrag #btmMapPanel [bottomPanel]="true"  [class] = "'bottom-panel-overlay ' + (threeJsElRef?.fullScreen ? 'full-screen-panel' : '')" [parent]="me" [floorPlanCode] = "selectedFloorPlanCode"> </app-arcs-dashboard-map-panel>
        <!-- loadFloorPlan(currentFloorPlan?.floorPlanCode) -->
        <app-arcs-dashboard-map-panel [cdkDragBoundary]="threeJsElRef?.fullScreen ? '.full-screen-viewport' : '.drag-boundary'" cdkDrag  #rightMapPanel [rightPanel]="true" [class] = "'right-panel-overlay ' + (threeJsElRef?.fullScreen ? 'full-screen-panel' : '')"  [parent]="me"  [floorPlanCode] = "selectedFloorPlanCode"> </app-arcs-dashboard-map-panel>
    </kendo-tilelayout-item>
    
 
</kendo-tilelayout>


<div class="table-container"  *ngIf="selectedTab == 'schedule'  || selectedTab == 'task' || selectedTab == 'completedTask' || selectedTab == 'template' || selectedTab == 'group' || selectedTab == 'robotEvent' || selectedTab == 'broadcast'">
    <uc-table #table  [toolbarButtons] ="gridSettings[selectedTab]['buttons'] ? gridSettings[selectedTab]['buttons']  : tableButtons" [columnDefs] = "gridSettings[selectedTab]['columns']" [(data)]="data"    
                      [functionId] = "gridSettings[selectedTab]['functionId']"
                      [dataSourceApiEndpoint] = "gridSettings[selectedTab]['apiUrl']"
                      [disabledButtons] = "tableDisabledButtons" 
                      (selectedDataChange) = "tableDisabledButtons = {new : false , action : $event.length == 0}"
                      (actionClick) = "$event.actionId == 'delete' ? delete(): null " [serverSidePagination] = "true"
                      (cellClick) = "showDialog($event)"
                      (createClick) = "showDialog($event)" 
                      [useColumnFilter]='true' [defaultState] = "gridSettings[selectedTab]['defaultState'] ? gridSettings[selectedTab]['defaultState'] : null">
    </uc-table>
</div>

<app-arcs-patrol-playback *ngIf="selectedTab == 'patrolPlayback'" ></app-arcs-patrol-playback>


<ng-container class="charts-container" *ngIf="selectedTab == 'usability' || selectedTab == 'utilization' || selectedTab == 'eventAnalysis' ">
    <app-arcs-charts [chartType]="selectedTab" ></app-arcs-charts>
</ng-container>


<kendo-dialog *ngIf="btmMapPanel?.showNewTaskDialog" class="new-task-dialog" [title]="('Send Robot To ' | label: uiSrv.langPack) + btmMapPanel.waypointState.pointCode" (close)="btmMapPanel.showNewTaskDialog = false" [minWidth]="250" [width]="450">
    <app-arcs-dashboard-new-task [dashboardCompRef] = "this" [singleMovementPointCode]="btmMapPanel.waypointState.pointCode" (close) = "btmMapPanel.showNewTaskDialog = false"></app-arcs-dashboard-new-task >
</kendo-dialog>

<app-arcs-report-export  *ngIf="selectedTab == 'reportExport'"></app-arcs-report-export>

<kendo-dialog *ngIf="btmMapPanel?.liveCameraCode"  (close)="btmMapPanel.liveCameraCode = null" [minWidth]="600" [title] = "('Live Camera : ' |  label : uiSrv.langPack ) + btmMapPanel?.iot?.name  ">
    <!-- for shw demo -->
    <div class="dummy-cctv"></div>
</kendo-dialog>
  

<!-- <kendo-dialog *ngIf="robotDetailId!=null" [title]="robotDetailId" (close)="robotDetailId = null" >
    <app-arcs-dashboard-robot-detail #robotDetailComp [robotId] = "robotDetailId"></app-arcs-dashboard-robot-detail>
</kendo-dialog> -->