import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sa-pages-delivery-pickup',
  templateUrl: './sa-pages-delivery-pickup.component.html',
  styleUrls: ['./sa-pages-delivery-pickup.component.scss']
})
export class SaPagesDeliveryPickupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
