import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { ARCS_STATUS_MAP, TaskItem, WaypointState } from 'src/app/services/data.models';
import { FloorPlanState, MapService } from 'src/app/services/map.service';
import { DropdownDescPipe, UiService } from 'src/app/services/ui.service';
import { IotObject3D, LiftObject3D, Object3DCommon, RobotObject3D, WaypointMarkerObject3D, ZoneMesh } from 'src/app/ui-components/threejs-viewport/threejs-viewport.component';
import { PixiIoT, PixiRobotMarker, PixiWayPoint, PixiZonePolygon } from 'src/app/utils/ng-pixi/ng-pixi-viewport/ng-pixi-map-graphics';
import { ArcsDashboardNewTaskComponent } from '../arcs-dashboard-new-task/arcs-dashboard-new-task.component';
import { ArcsDashboardComponent, RobotInfo } from '../arcs-dashboard.component';
import { Robot } from 'src/app/ui-components/map-2d-viewport/map-2d-viewport.component';
import { RobotService, RobotState } from 'src/app/services/robot.service';
import { filter, skip, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ArcsLiftIotComponent } from '../../arcs-iot/arcs-lift-iot/arcs-lift-iot.component';
import { MqService } from 'src/app/services/mq.service';
import { PixiGraphics } from 'src/app/utils/ng-pixi/ng-pixi-viewport/ng-pixi-base-graphics';
import { IOT_ICON_MAP } from 'src/app/utils/graphics/style';
import { DataService } from 'src/app/services/data.service';
import { HttpParams } from '@angular/common/http';
import { IotService } from 'src/app/services/iot.service';


@Component({
  selector: 'app-arcs-dashboard-map-panel',
  templateUrl: './arcs-dashboard-map-panel.component.html',
  styleUrls: ['./arcs-dashboard-map-panel.component.scss']
})
export class ArcsDashboardMapPanelComponent implements OnInit , OnDestroy {
  @Input() waypointState : WaypointState 
  @Input() robotState : RobotState
  @Input() liftCode : string 
  @Input() floorPlanCode : string
  @Input() parent : ArcsDashboardComponent
  @Input() panelMode: 'CREATE-TASK' | 'ROOMS' | 'EQUIPMENTS'
  @ViewChild('newTaskComp') newTaskCompRef : ArcsDashboardNewTaskComponent
  ARCS_STATUS_MAP = ARCS_STATUS_MAP
  pointTypeIconClassMap = {
    LIFT : "mdi mdi-elevator-passenger-outline",
    TURNSTILE : "mdi mdi-turnstile",
    LOBBY : "mdi mdi-human-queue",
    CHARGING_STATION : "mdi mdi-battery-charging"
  }

  iconMap = IOT_ICON_MAP


  get taskComp() {
    return this.parent.rightMapPanel.newTaskCompRef
  }
  get isCreatingTask() {
    return this.parent.rightMapPanel.panelMode == 'CREATE-TASK' 
  }
  showNewTaskDialog = false
  _showNewTaskPanel = false
  robotType = null

  set showNewTaskPanel(v) {
    this._showNewTaskPanel = v
    this.parent.rightMapPanel.robotState = this.robotState
    this.parent.rightMapPanel.panelMode = v ?  'CREATE-TASK' : null 
  }

  get showNewTaskPanel(){
    return this._showNewTaskPanel
  }

  zone : {
    zoneCode : string,
    zoneType : string,
    roomCodes : string[],
    rooms : {
      equipments : {equipmentCode : string , name : string , equipmentType : string}[] ,
      name : string,
      roomCode : string
    }[],
    equipmentCount : number,
    name : string
  }

  iot : {
    iotCode : string,
    iotType : string,
    name : string
  }
  
  @Input() bottomPanel = false
  @Input() rightPanel = false
  $onDestroy = new Subject()
  robotInfo : RobotInfo
  liveCameraCode

  constructor(public iotSrv : IotService, public mapSrv : MapService , public uiSrv : UiService , public robotSrv : RobotService , public mqSrv : MqService , public dataSrv : DataService) { 

  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    this.$onDestroy.next()
  }

  originalToolTipsOn
  selectedObj : Object3DCommon | PixiGraphics
  async selectedMapObjChange(obj) {
    if(this.isCreatingTask){
      if (obj instanceof PixiWayPoint || obj instanceof WaypointMarkerObject3D) {
        const pointCode = obj instanceof PixiWayPoint ? obj.code : obj.pointCode
        this.taskComp.addTaskItem(pointCode)
      }
      return
    }
    if(this.parent.pixiElRef && obj == null && this.selectedObj instanceof PixiGraphics && this.selectedObj.viewport.selectedGraphics == this.selectedObj){
      this.parent.pixiElRef.viewport.selectedGraphics = null
    }

    this.clearSelected()
    if(obj instanceof ZoneMesh || obj instanceof PixiZonePolygon){
      this.zone = {zoneCode : obj.zoneCode , zoneType : obj.zoneType , roomCodes : obj.roomCodes , name : obj.name , rooms : null , equipmentCount : null} 
      this.getEquipments()
    }else if((obj instanceof IotObject3D )|| (obj instanceof PixiIoT && obj.iotCode?.length > 0)){
      this.iot = {iotCode : obj.iotCode , iotType : obj.iotType , name : obj.name} 
    }else if(obj instanceof LiftObject3D || (obj instanceof PixiIoT && obj.iotType == 'LIFT' && obj.iotCode?.length > 0)){
      this.liftCode = obj instanceof LiftObject3D ? obj.liftCode : obj.iotCode
    }else if (obj instanceof PixiWayPoint || obj instanceof WaypointMarkerObject3D) {
        this.setWaypointInfoAtBottomPanel(obj)
    }else if(obj instanceof PixiRobotMarker || obj instanceof RobotObject3D){
      this.setRobotInfoAtBottomPanel(obj)
    }else {
      return
    }

    this.selectedObj = <any>obj
    if(this.selectedObj instanceof Object3DCommon){
      this.selectedObj.selected = true
    }
    if(this.selectedObj instanceof RobotObject3D){
      this.selectedObj.robotIotCompRef.instance.mode = 'STANDARD'
    }
  
    // this.setSelectedObjStyle()
    //set Style
  }

  clearSelected(){
    if(this.selectedObj && this.selectedObj instanceof Object3DCommon){
      this.selectedObj.selected = false
    }
    // if (!this.originalToolTipsOn && this.selectedObj && this.selectedObj instanceof Object3DCommon ) {
    //   if(this.selectedObj instanceof DoorObject3D){
    //     this.selectedObj.toolTipCompRef.instance.showDetail = false
    //     this.selectedObj.toolTipCompRef.instance.selected = false
    //   }else{
    //     this.selectedObj.toolTipAlwaysOn = false
    //   }
    // }
    // if( this.selectedObj instanceof Object3DCommon){
    //     this.selectedObj.toolTipSettings.cssClass = 'label-3js'
    //     this.selectedObj.toolTip.element.className = 'label-3js'
    // } 
    // if(this.selectedObj instanceof RobotObject3D){
    //   this.selectedObj.refreshMiniLabel()
    // }
    this.waypointState  = null
    this.robotState = null
    this.liftCode = null
    this.iot = null
    this.zone = null
    this.selectedObj = null
    this.parent.rightMapPanel.zone = null
    this.parent.rightMapPanel.panelMode = null
  }

  // setSelectedObjStyle(){
  //   let obj = this.selectedObj
  //   this.originalToolTipsOn = (obj instanceof WaypointMarkerObject3D || (obj instanceof RobotObject3D && obj.robotIotCompRef?.instance?.mode != 'MINI') || obj instanceof LiftObject3D) && obj.toolTipAlwaysOn
  //   if (obj instanceof Object3DCommon) {
  //     if (obj instanceof DoorObject3D) {
  //       obj.toolTipCompRef.instance.showDetail = true
  //       obj.toolTipCompRef.instance.selected = true
  //     } else {
  //       obj.toolTipSettings.cssClass = 'label-3js selected'
  //       obj.toolTipAlwaysOn = true
  //     }
  //   }
  // }

  async setWaypointInfoAtBottomPanel(obj){
    let pointCode = obj instanceof PixiWayPoint ? obj.code : obj.pointCode
    this.waypointState = new WaypointState()
    this.waypointState.floorPlanCode = this.floorPlanCode
    this.waypointState.pointCode = pointCode
    this.waypointState.wait = null
    this.waypointState = await this.mapSrv.getWayPointState(this.floorPlanCode, pointCode)
    this.waypointState =  this.waypointState ?  this.waypointState : {
      floorPlanCode : this.floorPlanCode,
      pointCode : pointCode,
      reserve : null ,
      wait : [],
      pointType : obj.pointType
    }
  
  }

  async setRobotInfoAtBottomPanel(obj : PixiRobotMarker | RobotObject3D ){
    const robotCode = obj instanceof PixiRobotMarker  ? obj.robotCode : obj.robotCode
    this.robotType = this.parent.robotInfos.filter(r=>r.robotCode == robotCode)[0]?.robotType
    this.robotState = this.robotSrv.robotState(robotCode)
    this.robotInfo = this.parent.robotInfos.filter(r=>r.robotCode == this.robotState?.robotCode)[0]
  }

  async getEquipments() {
    let equipments: { equipmentCode: string, equipmentType: string, name: string, roomCode: string }[] = await this.dataSrv.httpSrv.fmsRequest('GET', `room/v1/equipment/${this.floorPlanCode}/${this.zone.zoneCode}` , undefined ,  false)
    this.parent.rightMapPanel.zone = this.zone
    this.zone.rooms = []
    this.zone.roomCodes?.forEach(r=>{
      this.zone.rooms.push({
        roomCode : r,
        name : new DropdownDescPipe().transform(r , this.parent.dropdownOptions.rooms),
        equipments : equipments.filter(e=>e.roomCode == r)
      })
    })
    this.zone.equipmentCount = equipments.length
  }

  sendRobotClicked(){
    if(this.parent.robotInfos.filter(r=>r.robotStatus == 'IDLE').length > 0){
        this.showNewTaskDialog = true
    }else{
      this.uiSrv.showNotificationBar("No idle robot available on this floor plan" , 'warning')
    }
  }
  


}
