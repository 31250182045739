<div class="title arcs" *ngIf = "locationTree" >
  <div class="location-tree">
    <a class="site-name" *ngIf = "locationTree?.site?.code"  (click)="to2D.emit({showSite : true })"> {{locationTree.site.name}} </a>  
    <span class="mdi mdi-chevron-right" *ngIf = "locationTree?.site?.code && locationTree.currentLevel == 'floorplan'"></span>
    <span class="building-name" *ngIf = "locationTree?.site?.code && locationTree.currentLevel == 'floorplan'"> {{locationTree.building.name}}</span>
  </div>
  <span class="floorplan" *ngIf = "locationTree?.currentLevel == 'floorplan' && floorPlanDataset"> 
    <uc-dropdown   [options]="floorPlanOptions"   [value] = "floorPlanDataset.floorPlanCode"  
                   [allowClear] = "false"  [customDropItemTemplate] = "true"
                   (valueChange)="parent? parent.loadFloorPlan($event) : null"
                   (open)="parent? parent.setFloorplanRobotCount(floorPlanOptions) : null ;" class = "arcs-floorplan-picker">
    </uc-dropdown>
  </span>
</div>

<div class="buttons-container t1">
  <button  *ngIf="uiToggles.showWaypoint !== undefined" kendoButton class="show-waypoint" [matTooltip]="((uiToggles.showWaypoint && uiToggles.showWaypointName) ? 'Hide Waypoint Name' : ((uiToggles.showWaypoint && !uiToggles.showWaypointName) ? 'Hide Waypoint' : 'Show Waypoint'))| label : uiSrv.langPack"  
                 matTooltipPosition="right" 
                (click)=" uiToggles.showWaypointName = !(uiToggles.showWaypoint && uiToggles.showWaypointName); uiToggles.showWaypoint = !(uiToggles.showWaypoint && uiToggles.showWaypointName) ; uiToggled('waypoint') ; uiToggled('waypointName') "  
                [ngClass]="{'btn-intermediate': uiToggles.showWaypoint && !uiToggles.showWaypointName , 'btn-active': uiToggles.showWaypoint && uiToggles.showWaypointName }">
          <span class="mdi mdi-map-marker"></span>
 </button>
  <button *ngIf="uiToggles.showWall !== undefined && floorplan?.settings?.walls" kendoButton class="wall" [matTooltip]="'Show walls' | label : uiSrv.langPack" (click)="uiToggles.showWall = !uiToggles.showWall ; uiToggled('wall')" [ngClass]="{'btn-active':uiToggles.showWall}" >
    <span class="mdi mdi-wall"></span>
  </button>
  <button *ngIf="uiToggles.to2D !== undefined" kendoButton class="2d-switch" [matTooltip]="'2D Map' | label : uiSrv.langPack" (click)="dataSrv.setLocalStorage('dashboardMapType' , '2D');to2D.emit({floorPlanCode : floorPlanDataset.floorPlanCode })">
    <span class="mdi mdi-video-2d"></span>
  </button>
  <button *ngIf="uiToggles.showRobotState !== undefined"  kendoButton class="robot-status-popup" [matTooltip]="'Show Robot Status Tag' | label : uiSrv.langPack"  (click)="uiToggles.showRobotState = !uiToggles.showRobotState ; uiToggled('robotStatus')" [ngClass]="{'btn-active':uiToggles.showRobotState}" >
    <span class="mdi mdi-message-text"></span>
  </button>
  <button *ngIf="uiToggles.showIot !== undefined"  kendoButton class="show-iot" [matTooltip]=" (uiToggles.showIot ? 'Hide IoT' : 'Show IoT') | label : uiSrv.langPack"  (click)="uiToggles.showIot = !uiToggles.showIot ; uiToggled('iot')" [ngClass]="{'btn-active':uiToggles.showIot}" >
    <span class="mdi mdi-access-point-network"></span>
  </button>

  <button *ngIf="uiToggles.showFloorPlanImage !== undefined" [disabled]="use2DFloorPlanModel" kendoButton class="floor-plan" [matTooltip]="'Show 2D Floor Plan' | label : uiSrv.langPack" (click)="uiToggles.showFloorPlanImage = !uiToggles.showFloorPlanImage ; uiToggled('showFloorPlanImage')" [ngClass]="{'btn-active':uiToggles.showFloorPlanImage}" >
    <span class="mdi mdi-floor-plan"></span>
  </button>

  <button *ngIf="transformCtrl?.object" kendoButton class="translate" [matTooltip]="'Control model translation' | label : uiSrv.langPack" (click)="transformCtrl.setMode('translate')" [ngClass]="{'btn-active': transformCtrl.getMode() == 'translate'}" >
    <span class="mdi mdi-cursor-move"></span>
  </button>
  <button *ngIf="transformCtrl?.object && transformCtrl?.object.type != 'TURNSTILE'" kendoButton class="rotate" [matTooltip]="'Control model rotation' | label : uiSrv.langPack" (click)="transformCtrl.setMode('rotate')" [ngClass]="{'btn-active': transformCtrl.getMode() == 'rotate'}" >
    <span class="mdi mdi-rotate-orbit"></span>
  </button>
  <button *ngIf="transformCtrl?.object && transformCtrl?.object.type != 'ROBOT'" kendoButton class="rotate" [matTooltip]="'Remove' | label : uiSrv.langPack"  
           (click) = " transformCtrl.object['destroy'] != null ? transformCtrl.object['destroy']() : transformCtrl.object.parent.remove(transformCtrl.object)  ;
                       removed.emit(transformCtrl.object);
                       transformCtrl.detach();">
    <span class="mdi mdi-trash-can-outline"></span>
  </button>
  <!-- <button *ngIf="transformCtrl?.object" kendoButton class="scale" [matTooltip]="'Control model scale' | label : uiSrv.langPack" (click)="transformCtrl.setMode('scale')" [ngClass]="{'btn-active': transformCtrl.getMode() == 'scale'}" >
    <span class="mdi mdi-resize"></span>
  </button> -->
  <!-- <button *ngIf="uiToggles.transformControl !== undefined" kendoButton class="wall" [matTooltip]="'Show Transform Controller' | label : uiSrv.langPack" (click)="uiToggles.transformControl = !uiToggles.transformControl ; uiToggled('transformControl')" [ngClass]="{'btn-active':uiToggles.transformControl}" >
    <span class="mdi mdi-resize"></span>
  </button> -->
  <button  *ngIf="uiToggles.alert !== undefined" kendoButton class="show-alert" [matTooltip]="(uiToggles.alert ? 'Hide Event Alerts' : 'Show Event Alerts') | label : uiSrv.langPack"  
            (click)="uiToggles.alert = !uiToggles.alert ; uiToggled('alert')" [ngClass]="{'btn-active': uiToggles.alert}">
    <span [class]="'mdi mdi-alert'"></span>
  </button>

  <button  *ngIf="uiToggles.showZone !== undefined" kendoButton class="show-zone" [matTooltip]="((uiToggles.showZone && uiToggles.showZoneName) ? 'Hide Zone Name' : ((uiToggles.showZone && !uiToggles.showZoneName) ? 'Hide Zone' : 'Show Zone'))| label : uiSrv.langPack"  
                 matTooltipPosition="right" 
                (click)=" uiToggles.showZoneName = !(uiToggles.showZone && uiToggles.showZoneName); uiToggles.showZone = !(uiToggles.showZone && uiToggles.showZoneName) ; uiToggled('zone') ; uiToggled('zoneName') "  
                [ngClass]="{'btn-intermediate': uiToggles.showZone && !uiToggles.showZoneName , 'btn-active': uiToggles.showZone && uiToggles.showZoneName }">
          <span class="mdi mdi-texture-box"></span>
  </button>

  <button *ngIf="uiToggles.fullScreen !== undefined"  kendoButton class="full-screen" [matTooltip]="( fullScreen ? 'Cancel Full Screen' : 'Full Screen'  )| label : uiSrv.langPack" (click)="toggleFullScreen()"  [ngClass]="{'btn-active':fullScreen}" >
    <span [class]="'mdi mdi-' + (fullScreen ? 'fullscreen-exit' : 'fullscreen')"></span>
  </button>
</div>
<div #canvas class="canvas-container"></div>

<div *ngIf="loadingPercent!=null" class="three-js-viewport-overlay" [style.width]="width + 'px'" [style.height]="height + 'px'">
  <div>
    <span>{{ "Loading 3D Model" | label : uiSrv.langPack }}</span>
    <kendo-progressbar [max]="100" [value]="loadingPercent" [label]='{visible: false, format: "percent", position: "center"}'>
    </kendo-progressbar>
  </div>
</div>

<div class="bottom-panel">
  <ng-container #bottomPanelContainer *ngTemplateOutlet="transformCtrl?.object ? transformCtrlBtmPanelTpl : null"></ng-container>
</div>

<ng-template #transformCtrlBtmPanelTpl>
  <div class="flex-row std-container">
    <a class="close mdi mdi-close-circle" (click)=" transformCtrl?.object.type == 'ROBOT' ? transformCtrl.object.parent.parent['boxHelper']?.hide() : null ; 
                                                    transformCtrl.detach(); ">
    </a>
    <div class="col-60">
      <div class="flex-row panel-header">
        <span [class] = " transformCtrl.object.type == 'ROBOT' ? 'mdi mdi-robot' : (transformCtrl.object == floorPlanModel ? 'mdi mdi-cube-outline' : (
                            transformCtrl.object.type == 'IOT' ||  transformCtrl.object.type == 'LIFT'? iotIconMap[transformCtrl.object.type == 'LIFT' ? 'LIFT' : transformCtrl.object['iotType']] : (
                              transformCtrl.object == pointCloud ? 'mdi mdi-data-matrix': ''
                          )
                         ))"></span>
        <span>{{  ( transformCtrl.object.type == 'ROBOT' ? transformCtrl.object.parent?.parent['robotCode'] : (transformCtrl.object == floorPlanModel ? 'Floor Plan' : (
                      transformCtrl.object.type == 'IOT' ||  transformCtrl.object.type == 'LIFT' ?  (( transformCtrl.object.type == 'LIFT' ?  'LIFT' : transformCtrl.object['iotType']) | dropdownDesc : dropdownOptions.iotTypes) : (
                        transformCtrl.object == pointCloud ? 'Point Cloud': ''
                   )))) | label : uiSrv.langPack }}
        </span>
        
        <uc-dropdown *ngIf="transformCtrl.object.type == 'LIFT'" class="transform-id col-4 " lab = "Name" [(value)] = "transformCtrl.object['liftCode']" [options]="iotOptions.LIFT"  (valueChange)="transformCtrl.object['toolTipCompRef'].instance.name =  getIotName($event , 'LIFT')"></uc-dropdown>
        <uc-dropdown *ngIf=" transformCtrl.object.type == 'IOT'" class="transform-id col-4 " lab = "Name" [(value)] = "transformCtrl.object['iotCode']" [options]="iotOptions[transformCtrl.object['iotType']]" 
                     (valueChange)="transformCtrl.object['toolTipCompRef'].instance.iotCode = $event ; transformCtrl.object['toolTipCompRef'].instance.name =  getIotName($event , transformCtrl.object['iotType']) " ></uc-dropdown>
        <!-- <uc-txtbox *ngIf="transformCtrl.object.type == 'TURNSTILE'" class="transform-id " lab = "ID" [(value)] = "transformCtrl.object['turnstileId']"  (valueChange) = "transformCtrl.object['toolTipCompRef'].instance.turnstileId = $event"></uc-txtbox>
        -->
        <!-- <button kendoButton *ngIf="transformCtrl.object != floorPlanModel" 
                             (click) = "transformCtrl.object['destroy'] != null ? transformCtrl.object['destroy']() : transformCtrl.object.parent.remove(transformCtrl.object)  ;  
                                        transformCtrl.detach();" >
          <span class="mdi mdi-close"></span>
          {{'Remove' | label : uiSrv.langPack}}
        </button> -->
      </div>
      <div class="flex-row" *ngIf="transformCtrl.object.type != 'IOT'" >
        <uc-txtbox  class="transform-scale" lab = "Scale" [value] = "transformCtrl.object.scale.x"  (valueChange) = "transformCtrl.object.scale.set( $event , $event , $event) ;events.objTransformed.next(transformCtrl.object)" [numeric]="true"  [numMin]="0"  [numDecimals] = "2"></uc-txtbox>
        <ng-container *ngIf="transformCtrl.object.type == 'LIFT'">
          <uc-txtbox  class="width" lab = "Width" [(value)] = "transformCtrl.object['boxMesh'].scale.x" (valueChange) = "transformCtrl.object['onBoxSizeChanged']()" [numeric]="true" ></uc-txtbox>
          <uc-txtbox  class="height" lab = "Height" [(value)] = "transformCtrl.object['boxMesh'].scale.y" (valueChange) = "transformCtrl.object['onBoxSizeChanged']()"  [numeric]="true" ></uc-txtbox>
          <uc-txtbox  class="depth" lab = "Depth" [(value)] = "transformCtrl.object['boxMesh'].scale.z" (valueChange) = "transformCtrl.object['onBoxSizeChanged']()"  [numeric]="true" ></uc-txtbox>
        </ng-container>
        <ng-container *ngIf="transformCtrl.object.type == 'ROBOT'">          
          <uc-txtbox  class="cone-offset" lab = "Cone Offset" [(value)] = "transformCtrl.object.parent.parent['frontFaceCone'].position.y"   [numeric]="true" ></uc-txtbox>
          <uc-txtbox  class="tag-offset" lab = "Tag Offset" [(value)] = "transformCtrl.object.parent.parent['robotModelSetting'].toolTipPositionZ " (valueChange) = "transformCtrl.object.parent.parent['toolTip']?.refreshPosition()"  [numeric]="true" ></uc-txtbox>
        </ng-container>
      </div>     
    </div>   

    <div class="col-40 flex-col justify-end">
      <div class="flex-row">
        <a [class] = "'mdi mdi-cursor-move ' + (transformCtrl.getMode() == 'translate' ? 'selected' : '')" (click)="transformCtrl.setMode('translate')" ></a>
        <uc-txtbox  class="translate-x" lab = "Translation X" [(value)] = "transformCtrl.object.position.x"  [numeric]="true" [numDecimals] = "2" (valueChange)="events.objTransformed.next(transformCtrl.object)" ></uc-txtbox>
        <uc-txtbox  class="translate-y" lab = "Translation Y" [(value)] = "transformCtrl.object.position.y"  [numeric]="true" [numDecimals] = "2" (valueChange)="events.objTransformed.next(transformCtrl.object)" ></uc-txtbox>
        <uc-txtbox  class="translate-z" lab = "Translation Z" [(value)] = "transformCtrl.object.position.z"  [numeric]="true"  [numDecimals] = "2" (valueChange)="events.objTransformed.next(transformCtrl.object)" ></uc-txtbox>
      </div>
      <div class="flex-row" *ngIf="transformCtrl.object.type == 'ROBOT' || transformCtrl.object.type == 'LIFT' ||  transformCtrl.object == floorPlanModel">
        <a [class] = "'mdi mdi-rotate-orbit ' + (transformCtrl.getMode() == 'rotate' ? 'selected' : '')" (click)="transformCtrl.setMode('rotate')" ></a>
          <uc-txtbox  class="rotate-x" lab = "Rotation X" [value] = "transformCtrl.object.rotation.x * radRatio"  [numeric]="true"  [numDecimals] = "2"
                    (valueChange) = "transformCtrl.object.rotation.set($event / radRatio , transformCtrl.object.rotation.y , transformCtrl.object.rotation.z) ; events.objTransformed.next(transformCtrl.object)">
          </uc-txtbox>
          <uc-txtbox  class="rotate-y" lab = "Rotation Y" [value] = "transformCtrl.object.rotation.y * radRatio"  [numeric]="true"  [numDecimals] = "2"
                    (valueChange) = "transformCtrl.object.rotation.set(transformCtrl.object.rotation.x, $event / radRatio , transformCtrl.object.rotation.z) ; events.objTransformed.next(transformCtrl.object)">
          </uc-txtbox>
          <uc-txtbox  class="rotate-z" lab = "Rotation Z" [value] = "transformCtrl.object.rotation.z * radRatio"  [numeric]="true"  [numDecimals] = "2"
                    (valueChange) = "transformCtrl.object.rotation.set(transformCtrl.object.rotation.x , transformCtrl.object.rotation.y , $event / radRatio) ; events.objTransformed.next(transformCtrl.object)">
          </uc-txtbox>
      </div>    
    </div>
  </div>
</ng-template>

<!-- <th-canvas #canvas (onRender)="animate()" >
  <th-scene #scene>
    <th-ambientLight [intensity]="0.1" intensity_x="0.7"> </th-ambientLight>
    <th-pointLight [position]="[floorplan?.width / 2 ,  floorplan?.height  ,  floorplan?.height / 2]" [intensity]="0.1" intensity_x = "0.4" [decay]="10"> 
    </th-pointLight>
    <th-perspectiveCamera #camera [args]="[45, 1, 1 , 10000000]">
    </th-perspectiveCamera>
  </th-scene>
</th-canvas> -->