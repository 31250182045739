import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { RvHttpService } from 'src/app/services/rv-http.service';
import { UiService } from 'src/app/services/ui.service';
import { GeneralUtil } from 'src/app/utils/general/general.util';

const PK_MAP = {
  LIFT : 'liftCode',
  DOOR : 'doorCode'
}

@Component({
  selector: 'app-arcs-setup-device',
  templateUrl: './arcs-setup-device.component.html',
  styleUrls: ['./arcs-setup-device.component.scss']
})

export class ArcsSetupDeviceComponent implements OnInit {
  windowRef
  parent
  parentRow : {type : string , code : string , name : string}
  deviceType : string
  dropDownOptions = {
    iotTypes : []
  }
  constructor(public util : GeneralUtil , public dataSrv : DataService , public uiSrv : UiService , public httpSrv : RvHttpService) { }

  frmGrp = new FormGroup({
    liftCode: new FormControl(null),
    doorCode: new FormControl(null),
    iotCode: new FormControl(null),
    floors: new FormControl(''),
    name: new FormControl(''),
    modifiedDateTime: new FormControl(null),
  })

  get devicePrimaryKeyColumn(){
      return PK_MAP[this.deviceType] ?  PK_MAP[this.deviceType] : 'iotCode'
  }

  async ngOnInit() {
    let ticket = this.uiSrv.loadAsyncBegin()
    await this.initDropdown()
    if (this.parentRow) {
      this.loadData()
    }
    this.uiSrv.loadAsyncDone(ticket)
  }

  async initDropdown(){
     this.dropDownOptions.iotTypes = (await this.dataSrv.getDropList('iotTypes')).options
  }

  async loadData() {
    let ticket = this.uiSrv.loadAsyncBegin()
    //TBR
    this.deviceType = <any>this.parentRow.type
    this.frmGrp.controls[this.devicePrimaryKeyColumn].setValue(this.parentRow.code)
    this.frmGrp.controls['name'].setValue(this.parentRow.name)
    
    this.uiSrv.loadAsyncDone(ticket)
  }


  async onClose() {
    if ( await this.uiSrv.showConfirmDialog('Do you want to quit without saving ?')) {
      this.windowRef.close()
    }
  }

  async getSubmitDataset(){
    let ticket = this.uiSrv.loadAsyncBegin()
    let ret = {}
    Object.keys(this.frmGrp.controls).forEach(k=> ret[k] = this.frmGrp.controls[k].value)
    this.uiSrv.loadAsyncDone(ticket)
    return ret
  }

  async validate() {
    if(this.deviceType == null){
      await this.uiSrv.showConfirmDialog('Please select device type')
      return false
    }else {
      this.frmGrp.controls[this.devicePrimaryKeyColumn].setValidators(Validators.required)
    }
    return this.util.validateFrmGrp(this.frmGrp)
  }

  async saveToDB(){
    if(!await this.validate()){
      return
    }
    
    if((await this.dataSrv.saveRecord(`api/device/${this.deviceType.toLowerCase()}/v1`  , await this.getSubmitDataset(), this.frmGrp , false)).result){      
      this.windowRef.close()
    }
  }
}
