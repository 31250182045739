<div class="container">
    <div class="report-type-row">
        <uc-dropdown [disabled] = "dataSrv.generatingReport" lab="Report Type" [options] = "dropdownOptions.reportType" [(value)] = "reportType" [allowClear] = "false"></uc-dropdown>
    </div>
  
    
    <ng-container *ngIf="cfg[reportType]?.layout">
        <ng-container *ngFor = "let item of cfg[reportType]?.layout">
            <div class="filter-row">
                <uc-dropdown [disabled] = "dataSrv.generatingReport" *ngIf="item.uc == 'dropdown'"  [lab]="item.text"  [options] = "dropdownOptions[item.optionType]" [(value)] = "cfg[reportType].filters[item.key]"></uc-dropdown>
                <uc-date-input  [disabled] = "dataSrv.generatingReport" *ngIf="item.uc == 'datetime'" [lab]="item.text"  [(value)] = "cfg[reportType].filters[item.key]"></uc-date-input>      
            </div>
        </ng-container>
    </ng-container>


    <div class="button-container" >
        <button kendoButton [disabled] = "dataSrv.generatingReport" (click)="dataSrv.exportReport(reportType , cfg[reportType].filters)">{{'Export' | label : uiSrv.langPack}}</button>
    </div>

    <div *ngIf="dataSrv.generatingReport"  class="in-progress-container" >        
        <div *ngIf="dataSrv.generatingReport?.progress == null" class="indeterminate-progress-bar" >
            <div class="indeterminate-progress-bar-value"></div>
        </div>    

        <div *ngIf="dataSrv.generatingReport?.progress!= null" >
            <kendo-progressbar  class="progress-bar" [label]="progressBarLabelSetting" [value] = "dataSrv.generatingReport?.progress"></kendo-progressbar>
            <!-- {{'Request Id : ' |  label : uiSrv.langPack}} {{dataSrv.generatingReport.requestId}} -->
        </div>

        <div class="in-progress-text" > 
            <span> {{'Report Generation In Progress ...' |  label : uiSrv.langPack}}</span>
            <button *ngIf="dataSrv.generatingReport?.requestId" kendoButton (click)="dataSrv.cancelReportGeneration(dataSrv.generatingReport.requestId)"> {{'Cancel' | label : uiSrv.langPack}}</button>
         </div>
    </div>
</div>


