<div class="dialog-content robot-event">
    <kendo-dialog-titlebar (close)="dialogRef.close()">
        <div>{{title  | label: uiSrv.langPack}}</div>
    </kendo-dialog-titlebar>
    <kendo-tabstrip #tabstrip (tabSelect)=" tabChanged() ;" class="tabs">
        <kendo-tabstrip-tab [title]="'Log' | label : uiSrv.langPack " [selected]="true">
            <ng-template kendoTabContent>       
                <div class="log-container">
                    <div class="message">{{message}}</div>
                    <div class="confidence" *ngIf="data.confidence">{{'Confidence' |  label : uiSrv.langPack}} : {{data.confidence * 100 | number : '1.0-0'}}%</div>
                    <div class="image-container">
                        <img class="image" *ngIf="data?.base64Image" [src]="'data:image/jpeg;base64,' + data.base64Image" />
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        
        <kendo-tabstrip-tab *ngIf="data?.floorPlanCode && parentRow" [title]="'Location' | label : uiSrv.langPack">
            <ng-template kendoTabContent>
                <div [class]="uiSrv.isTablet? 'viewport-container-tablet' : 'viewport-container'">
                    <uc-drawing-board #pixi [hideButton]="{all:true}" [readonly] = "true" [loadMapContainers] = "true">
                    </uc-drawing-board>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [title]="'Metadata' | label : uiSrv.langPack " >
            <ng-template kendoTabContent>       
                <div *ngIf="data.metadata?.length > 0" class="metadata">{{data.metadata}}</div>
                <div *ngIf="!data.metadata || data.metadata.length == 0" class="no-metadata">
                    {{'No metadata found' | label : uiSrv.langPack}}
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</div>