<div *ngIf="bottomPanel" class="bottom-panel">
    <div class="waypoint-state-container" *ngIf="waypointState">
        <div class="header">
            <span [class]="pointTypeIconClassMap[waypointState.pointType]  ?  pointTypeIconClassMap[waypointState.pointType] : 'mdi mdi-map-marker-outline' " [matTooltip] = "'Waypoint' | label: uiSrv.langPack" matTooltipPosition = "above">  
                {{waypointState.pointCode}} 
            </span>
            <!-- <span class="point-code"> {{waypointState.pointCode}} </span> -->
            <a class="close mdi mdi-close-circle" (click)="selectedMapObjChange(null)"></a>
        </div>
        <div class="bottom-content" >            
            <div *ngIf="waypointState.reserve" class="reserve">
                <span >{{'Reserved By : ' |  label: uiSrv.langPack}} {{waypointState.reserve}}</span>
                <!-- <button kendoButton class="button" *ngIf="!waypointState.reserve" > {{'Reserve' |  label: uiSrv.langPack}}</button> -->
            </div>
            
            <div class="wait">
                <ng-container *ngIf="waypointState.wait!=null">
                    <span *ngIf="waypointState.wait.length == 0">{{'No Robots Waiting' |  label: uiSrv.langPack}} </span>
                    <span *ngIf="waypointState.wait.length == 1">{{'Waiting : ' |  label: uiSrv.langPack}} {{waypointState.wait[0]}}</span>
                    <span *ngIf="waypointState.wait.length > 1">
                        <a class="underline" [matTooltip]="(waypointState.wait | join : ' , ')">{{waypointState.wait.length}}</a>
                        {{'Robots Waiting' |  label: uiSrv.langPack}}
                    </span>
                </ng-container>
            </div>

            <div class="send-robot">         
                <button kendoButton class="button" (click)="sendRobotClicked()" > {{'Send Robot' |  label: uiSrv.langPack}}</button>
            </div>
        </div>
    </div>   


    <div class="robot-state-container" *ngIf="robotState && parent.rightMapPanel?.panelMode != 'CREATE-TASK'">
        <div class="header">
            <span class="mdi mdi-robot" [matTooltip] = "'Robot' | label: uiSrv.langPack" matTooltipPosition = "above">  {{robotState.robotName}} </span>
            <a class="close mdi mdi-close-circle" (click)="selectedMapObjChange(null)"></a>
        </div>

        <div *ngIf="robotState.obstacleDetected?.value || robotState.tiltActive?.value || robotState.estop?.value" class="bottom-content alert">
            <i  class="icon mdi mdi-alert"></i>
            <span class="message">{{ robotState.estop?.value ? uiSrv.commonAlertMessages.estopped : ( robotState.tiltActive?.value ?  uiSrv.commonAlertMessages.tiltDetected : uiSrv.commonAlertMessages.obstacleDetected) | label : uiSrv.langPack}}</span> 
        </div>
      
        <div *ngIf="robotState.status?.value != 'UNKNOWN' && robotState.status?.value != 'CHARGING'  && robotState.destination?.value"  class="bottom-content">         
            <span class="destination">
                <span>{{'Going to : ' | label: uiSrv.langPack}}</span>
                <i class="mdi mdi-map-marker"></i>
                <span>{{robotState.destination.value}}</span>
            </span>
        </div> 

        <div class="bottom-content" >
            <!-- <div class="robot-type" *ngIf="parent.iconMap[robotType] ">         
                <span [class] ="  parent.iconMap[robotType]">  {{robotType}} </span>
            </div> -->
            <span class="robot-type"  *ngIf="robotState.status?.value"> 
                <i [class]="uiSrv.robotTypeIconMap[robotState.robotType] ? uiSrv.robotTypeIconMap[robotState.robotType] : 'mdi mdi-robot' "></i> 
                <span>{{robotState.robotType | enum}}</span>
            </span>
    
            <span class="status"  *ngIf="robotState.status?.value"> 
                <i [class]="'iot-status status mdi mdi-circle ' +  ( robotState.status?.value ? (ARCS_STATUS_MAP[robotState.status?.value ]| cssClassName) : '')  " ></i> 
                <span>{{ARCS_STATUS_MAP[robotState.status.value] | label : uiSrv.langPack}}</span>
            </span>
    
            <!-- <span class="battery" *ngIf="robotState.batteryRounded?.value">
                <i [class]="'iot-battery mdi mdi-battery' + ( robotState.batteryRounded?.value == 100 ? '' : ('-' + ( robotState.batteryRounded?.value < 10 ? '10' : ((robotState.batteryRounded?.value / 10) | roundDown) * 10))) "></i> 
                <span>{{robotState.batteryRounded?.value}}%</span>
            </span> -->

            <div class="details">
                 <button kendoButton class="button" (click) = "parent.showRobotDetailDialog(robotState.robotCode)"> {{'Details' |  label: uiSrv.langPack}}</button>
            </div>            

            <div class="create-task" *ngIf="robotInfo?.robotStatus != 'UNKNOWN' && !robotState.executingTask.value">         
                <button kendoButton class="button" (click)="showNewTaskPanel = true ; " > {{'New Task' |  label: uiSrv.langPack}}</button>
            </div>
            
            <div class="task-count" *ngIf="robotState.executingTask.value">
                <button kendoButton class="button" (click) = "parent.cancelTask({ row : robotState.executingTask.value})"> {{'Cancel Task' |  label: uiSrv.langPack}}</button>
            </div>

            <!-- <div class="task-count" *ngIf="robotInfo">
                <span *ngIf="robotInfo.waitingTaskCount == 0">{{'No Pending Task' | label: uiSrv.langPack}}</span>
                <span *ngIf="robotInfo.waitingTaskCount > 0">{{robotInfo.waitingTaskCount }} {{' Pending Task' | label: uiSrv.langPack}}</span>
            </div> -->
        </div>
    </div>   


    <div class="lift-state-container" *ngIf="liftCode">
        <div class="header">
            <span class="mdi mdi-elevator-passenger-outline" [matTooltip] = "'Lift' | label: uiSrv.langPack" matTooltipPosition = "above"> {{liftCode | dropdownDesc : parent.dropdownOptions.lifts}}  </span>
            <a class="close mdi mdi-close-circle" (click)="selectedMapObjChange(null)"></a>
        </div>


        <div class="bottom-content" >
            <div class="robot">
                <ng-container  *ngIf="!mqSrv.data.arcsLift.value[liftCode]?.robotCode">
                    <span>{{'No robot inside' | label : uiSrv.langPack}}</span>
                </ng-container>
                <ng-container  *ngIf="mqSrv.data.arcsLift.value[liftCode]?.robotCode">
                    <span>{{'Robot loaded' | label : uiSrv.langPack}} : </span>
                    <span>{{ robotSrv.ARCS.robotStore[mqSrv.data.arcsLift.value[liftCode].robotCode]?.robotName }}</span>
                </ng-container>
            </div>

            <div class="floor">
                <span>{{'Floor' | label : uiSrv.langPack}} : </span>
                <span>{{mqSrv.data.arcsLift.value[liftCode]?.floor ? mqSrv.data.arcsLift.value[liftCode].floor  : ' - '}}</span>            
            </div>


            <div class="door">
                <span>{{'Door' | label : uiSrv.langPack}} : </span>
                <span>{{(mqSrv.data.arcsLift.value[liftCode]?.doorStatus ? mqSrv.data.arcsLift.value[liftCode].doorStatus  : ' - ') | enum}}</span>            
            </div>
        </div>
    </div>   

    <div class="door-state-container" *ngIf="iot">
        <div class="header">
            <span [class]="iconMap[iot.iotType]" [matTooltip] = "iot.iotType | dropdownDesc : parent.dropdownOptions.iotTypes" matTooltipPosition = "above"> {{iot.name}}  </span>
            <a class="close mdi mdi-close-circle" (click)="selectedMapObjChange(null)"></a>
        </div>
        
        <div class="bottom-content" *ngIf="iot.iotType == 'IEQ' &&  iotSrv.iotStore[iot.iotType][iot.iotCode] && iotSrv.iotStore[iot.iotType][iot.iotCode]?.ieq?.value" >
            <div class="ieq-item"> 
                <span> {{'Air Quality' | label : uiSrv.langPack}} : </span>
                <span class="value">{{iotSrv.iotStore[iot.iotType][iot.iotCode].airQualityOverall.value}}</span>
            </div>
        </div>

        <div class="bottom-content" >
            <div *ngIf="iot.iotType == 'DOOR' || iot.iotType == 'LIGHT_CURTAIN' ">
                <span>{{'Status' | label : uiSrv.langPack}} : </span>
                <span *ngIf="iotSrv.iotStore[iot.iotType][iot.iotCode] && iotSrv.iotStore[iot.iotType][iot.iotCode].status.value">
                    {{iotSrv.iotStore[iot.iotType][iot.iotCode].status.value | enum}} 
                </span>
                <span *ngIf="!(iotSrv.iotStore[iot.iotType][iot.iotCode] && iotSrv.iotStore[iot.iotType][iot.iotCode].status.value)"> - </span>
            </div>


            <div class="spacer" *ngIf="iot.iotType == 'CAMERA'"> </div>
            <div *ngIf="iot.iotType == 'CAMERA'">
                <button class="button"  kendoButton (click) = "liveCameraCode = iot.iotCode">{{'Live' | label : uiSrv.langPack}}</button> 
            </div>

            <ng-container *ngIf="iot.iotType == 'IEQ' &&  iotSrv.iotStore[iot.iotType][iot.iotCode] && iotSrv.iotStore[iot.iotType][iot.iotCode]?.ieq?.value">
                <div class="ieq-item"> 
                    <span> {{'Temperature' | label : uiSrv.langPack}} : </span>
                    <span class="value">{{iotSrv.iotStore[iot.iotType][iot.iotCode].ieq.value['temp'] | number : '1.0-2'}} °C</span>
                </div>
                <div class="ieq-item"> 
                    <span> {{'Humidity' | label : uiSrv.langPack}} : </span>
                    <span class="value">{{iotSrv.iotStore[iot.iotType][iot.iotCode].ieq.value['rh'] | number : '1.0-2' }} % </span>
                </div>
                <div class="ieq-item"> 
                    <span> {{'PM2.5' | label : uiSrv.langPack}} : </span>
                    <span class="value">{{iotSrv.iotStore[iot.iotType][iot.iotCode].ieq.value['pm2'] | number : '1.0-2' }} μg/m³ </span>
                </div>
            </ng-container >

        </div>

    </div>   

    <div class="zone-container" *ngIf="zone">
        <div class="header">
            <span class="mdi mdi-texture-box" [matTooltip] = "'Zone' | label: uiSrv.langPack" matTooltipPosition = "above"> {{zone.zoneCode}}  </span>
            <a class="close mdi mdi-close-circle" (click)="selectedMapObjChange(null)"></a>
        </div>


        <div class="bottom-content" >
            <div class="zone type"  *ngIf="zone.zoneType == 'RESTRICTED'">
                <ng-container >
                    <span><i class="mdi mdi-cancel"></i> {{'Restricted Area' | label : uiSrv.langPack}}</span>
                </ng-container>
            </div>

            <div class="room">
                <ng-container  *ngIf="zone.roomCodes?.length == 1 && (zone.roomCodes[0] | dropdownDesc : parent.dropdownOptions.rooms)">
                    <span>{{'Room' | label : uiSrv.langPack}} : {{zone.roomCodes[0] | dropdownDesc : parent.dropdownOptions.rooms}}</span> 
                </ng-container>

                <ng-container  *ngIf="zone.roomCodes?.length > 1">
                    <button class="button"  kendoButton (click) = "parent.rightMapPanel.panelMode = 'ROOMS'">{{zone.roomCodes.length }} {{' rooms' | label : uiSrv.langPack}}</button> 
                </ng-container>
            </div>

            <div class="equipment">
                <ng-container  *ngIf="zone.equipmentCount">
                    <button class="button"  kendoButton (click) = "parent.rightMapPanel.panelMode = 'EQUIPMENTS'">{{zone.equipmentCount}} {{' equipments' | label : uiSrv.langPack}}</button>
                </ng-container>
            </div>
        </div>
    </div>   
</div>




<!-- <kendo-dialog *ngIf="showNewTaskDialog" class="new-task-dialog" [title]="('Send Robot To ' | label: uiSrv.langPack) + waypointState.pointCode" (close)="showNewTaskDialog = false" [minWidth]="250" [width]="450">
    <app-arcs-dashboard-new-task [dashboardCompRef] = "parent" [singleMovementPointCode]="waypointState.pointCode" (close) = "showNewTaskDialog = false"></app-arcs-dashboard-new-task >
</kendo-dialog> -->


<div *ngIf="rightPanel" [class]="'right-panel ' + (panelMode | cssClassName)">
    <div [class]="'task-container'" *ngIf="panelMode == 'CREATE-TASK'">
        <app-arcs-dashboard-new-task #newTaskComp [dashboardCompRef] = "parent" [multiMovement]="true" [selectedRobotCode] = "robotState.robotCode" (close) = "showNewTaskPanel = false"></app-arcs-dashboard-new-task>
    </div>
    
    <div [class]="'rooms-container'" *ngIf="panelMode == 'ROOMS' || panelMode == 'EQUIPMENTS'">
        <div class="rooms-header">
            <span class="mdi mdi-format-list-bulleted"> {{'Rooms & Equipments' | label : uiSrv.langPack }} </span>
            <a class="close mdi mdi-close-circle" (click)="panelMode = null"></a>
        </div>
        <div class="room-list">
            <ng-container  *ngIf="zone?.rooms">
                <ng-container *ngFor="let room of zone.rooms">    
                    <kendo-expansionpanel class="room-panel"  [expanded]="panelMode == 'EQUIPMENTS'">
                        <ng-template kendoExpansionPanelTitleDirective>
                           <span class="expansion-header">
                              <i class="room-icon mdi mdi-floor-plan"></i>
                              <span class="room-name"> {{room.name |  label : uiSrv.langPack }} </span>
                            </span>
                        </ng-template>
                        <div class="equipment-list">   
                            <ng-container  *ngFor="let equipment of room.equipments">
                                <div class="equipment">
                                    <span class="equipment-type"> {{equipment.equipmentType | uppercase}}</span>
                                    <span class="equipment-name">{{equipment.name}}</span>
                                </div>
                            </ng-container>    
                        </div>
                    </kendo-expansionpanel>
               </ng-container>
            </ng-container>
        </div>
    </div>
</div>



