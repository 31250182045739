<ng-template #frDateTpl>{{dateRangeToolTip?.from?.content}}</ng-template>
<ng-template #toDateTpl>{{dateRangeToolTip?.to?.content}}</ng-template>
<ng-template #filterTemplate>
    <div class="form-row">
        <uc-dropdown  *ngIf="_chartType=='eventAnalysis'" lab = "Floor Plan" [(value)] = 'event.floorplan.selected' (valueChange)=" refreshChart() " [options] = "dropdownOptions.floorplans"  nullValueDesc="All"></uc-dropdown>
        <uc-dropdown  *ngIf="_chartType=='eventAnalysis'" lab = "Event Type" [(value)] = 'event.eventType.selected' (valueChange)="refreshChart()" [options] = "dropdownOptions.robotEventTypes"  nullValueDesc="All"></uc-dropdown>
        <uc-dropdown  *ngIf="_chartType!='eventAnalysis'" lab = "Robot Type" [(value)] = 'robotType' (valueChange)="refreshRobotOptions() ; robotCodeFilter = null ; refreshChart()" [options] = "dropdownOptions.types"  [translateOption] = 'true' nullValueDesc="All"></uc-dropdown>
        <uc-dropdown  *ngIf="_chartType!='eventAnalysis'" lab = "Robot" [(value)] = 'robotCodeFilter' (valueChange)="refreshRobotTypeFilter();refreshChart()" [options] = "dropdownOptions.robots"  nullValueDesc="All"></uc-dropdown>
        <uc-dropdown  lab = "Year" [(value)] = 'year' (valueChange)="setDateRange();refreshChart()" [options] = "dropdownOptions.years" [allowClear]="false" ></uc-dropdown>
        <div class="date-range-label col form-group">
            <div class="col-form-label">{{'Date Range' | label: uiSrv.langPack}}</div>
            <div *ngIf="_chartType == 'usability' && usability?.daily?.max " class="label">
                <span >{{usability.daily.min | date : 'dd/MM'}}</span>
                <span> - </span>
                <span>{{(usability.daily.max.getTime() - 86400000) | date : 'dd/MM'}}</span>
            </div>
            <div *ngIf="_chartType == 'utilization' && utilization?.daily?.max" class="label">
                <span >{{utilization.daily.min | date : 'dd/MM'}}</span>
                <span> - </span>
                <span>{{(utilization.daily.max.getTime() - 86400000) | date : 'dd/MM'}}</span>
            </div>
            <div *ngIf="_chartType == 'eventAnalysis' && event?.daily?.max" class="label">
                <span >{{event.daily.min | date : 'dd/MM'}}</span>
                <span> - </span>
                <span>{{(event.daily.max.getTime() - 86400000) | date : 'dd/MM'}}</span>
            </div>
        </div>
    </div>      
</ng-template>


<kendo-tilelayout *ngIf="_chartType == 'usability' && usability"  kendoTooltip #tooltipTo="kendoTooltip" filter=".k-right-handle" showOn="none"  class="chart" [columns]="12"  [resizable]="false" [reorderable]="false">
    <kendo-tilelayout-item class = "tile select-year" [col]="1" [row]="1"  [colSpan] = "4">
        <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>
    </kendo-tilelayout-item>
    <kendo-tilelayout-item class = "tile completed-jobs fixed-header" [title]="'Completed Tasks'| label: uiSrv.langPack" [col]="5" [row]="1" [colSpan] = "3">
        <kendo-tilelayout-item-body class="pad-top-24">
            <div class="job-no">
                <label class="ts-value ">{{usability.completed}}</label>
                <label class="ts-total">/ {{usability.total}}</label>
            </div>
            <label class="ts-percent">{{usability.completed * 100 / usability.total | number: '1.0-2'}}%</label>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
    <kendo-tilelayout-item class = "tile incomplete-jobs fixed-header" [title]="'Incomplete Tasks'| label: uiSrv.langPack"  [col]="8" [row]="1" [colSpan] = "3">
        <kendo-tilelayout-item-body class="pad-top-24">
            <div class="job-no">
                <a *ngIf="usability.incomplete != 0" class="ts-value" (click)="showAbnormalTasks('failed')">{{usability.incomplete}}</a>
                <label *ngIf="usability.incomplete == 0" class="ts-value">{{usability.incomplete}}</label>
                <label class="ts-total">/ {{usability.total}}</label>
            </div>
            <label class="ts-percent">{{usability.incomplete * 100 / usability.total | number: '1.0-2'}}%</label>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
    <kendo-tilelayout-item class ="tile canceled-jobs fixed-header"  [title]="'Cancelled Tasks'| label: uiSrv.langPack"  [col]="11" [row]="1" [colSpan] = "2">
        <kendo-tilelayout-item-body class="pad-top-24">
            <div class="job-no">
                <!-- <label class="ts-value">{{usability.canceled}}</label> -->
                <!-- <label class="ts-total">/ {{usability.total}}</label> -->
            </div>
            <a *ngIf="usability.canceled != 0" class="ts-percent" (click)="usability.canceled ?  showAbnormalTasks('canceled') : null">{{usability.canceled ? usability.canceled : 0}}</a> 
            <label *ngIf="usability.canceled == 0" class="ts-percent">{{usability.canceled}}</label> 
            <!-- <label class="ts-percent">{{usability.canceled * 100 / usability.total | number: '1.0-2'}}%</label> -->
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item kendoTooltip #tooltipFr="kendoTooltip" filter=".k-left-handle" showOn="none" class = "tile daily" [col]="1" [row]="2" [rowSpan] = "4" [colSpan] = "robotType ? 9 : 12" [title]="'Task / Job Count per Day' | label: uiSrv.langPack"  >
        <a class="mdi mdi-download export-chart"  [matTooltip] = "'Download as image' | label : uiSrv.langPack" (click) = "exportChart(navigatorChart , 'Task / Job Count per Day')"></a>
        <kendo-chart  #navigatorChart (selectStart)="onSelectStart()" (select)="onSelect($event)" (selectEnd)="onSelectEnd($event);"   
                                      [transitions]="usability.daily.transitions"  class="kendo-chart-time-series" (seriesClick) = "onSeriesClick($event)"
                      [seriesColors]="[style.seriesColors[0], style.seriesColors[0]]"
                      [categoryAxis]="[
                      {
                        color : style.textColor,
                        categories: usability.daily.categories,
                        min: usability.daily.min,
                        max: usability.daily.max,
                        labels: { format:'d/M'},
                        baseUnitStep : 1,
                        maxDivisions: robotType ? 16 : 32,
                        axisCrossingValue : [0 , 1000]
                      },
                      {
                        categories: usability.daily.categories,
                        name: 'navigatorAxis',
                        labels: { step: usability.daily.navigatorStep , format:'MMM' , skip: 15 , color:style.textColor},
                        majorGridLines: { visible: false},
                        pane: 'navigator',
                        select: { from: usability.daily.min, to: usability.daily.max }
                      }]"
                      [valueAxis]="usability.daily.withJobs ? 
                                    [{title: { text: 'Task Count' } , name : 'task' , color:style.textColor}, {title: { text: 'Job Count' } , name : 'job' , color:style.textColor  }, {name: 'valueNavigatorAxis', pane: 'navigator' , visible:false , majorGridLines :{visible:false } }] : 
                                    [{title: { text: 'Task Count' } , name : 'task' , color:style.textColor}, {name: 'valueNavigatorAxis', pane: 'navigator' , visible:false , majorGridLines :{visible:false } }]"
                      [panes]="[{color:style.textColor }, { name: 'navigator', height: 100 }]">
            <kendo-chart-area background="transparent"></kendo-chart-area>
            <kendo-chart-tooltip format="{0}"></kendo-chart-tooltip>
            <!-- <kendo-chart-title  [align]="'left'" [text]="'Job Order Quantity per Day' | label: uiSrv.langPack"  [color]="style.textColor"></kendo-chart-title> -->
            <kendo-chart-series>
                <kendo-chart-series-item type="line" [data]="usability.daily.data" [axis]="'task'" [color]="style.seriesColors[0]" [name]="'Task' | label : uiSrv.langPack" [markers]="{ visible: false }"></kendo-chart-series-item>
                <kendo-chart-series-item type="line" *ngIf="usability.daily.withJobs" [data]="usability.daily.jobData"  [axis]="'job'" [color]="style.seriesColors[1]" [name]="'Job (Grouped Task)' | label : uiSrv.langPack" [markers]="{ visible: false }"></kendo-chart-series-item>
                <kendo-chart-series-item type="area" [data]="usability.daily.data"  axis="valueNavigatorAxis" categoryAxis="navigatorAxis"> </kendo-chart-series-item>
            </kendo-chart-series>
            <kendo-chart-legend position="bottom" orientation="horizontal" [markers]="{type:'circle' , height : 10}" [labels] = "{color : '#FFFFFF'}">
            </kendo-chart-legend>
        </kendo-chart>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item  *ngIf="robotType" class = "tile robot" [col]="10" [row]="2" [rowSpan] = "4" [colSpan] = "3" [title]="'Task Count By Robot' | label: uiSrv.langPack">
        <a class="mdi mdi-download export-chart"  [matTooltip] = "'Download as image' | label : uiSrv.langPack" (click) = "exportChart(usabilityByRobotChart , 'Task Count By Robot')"></a>
        <kendo-chart #usabilityByRobotChart  [seriesColors]="style.seriesColors"  (seriesClick) = "onSeriesClick($event)">
            <kendo-chart-area background="transparent"></kendo-chart-area>
            <kendo-chart-category-axis>
              <kendo-chart-category-axis-item  [categories]="usability.robot.categories" [labels]="{visual : style.labelVisual}" 
                                               [majorGridLines]="{ visible : false}"  [color]="style.textColor">
              </kendo-chart-category-axis-item>         
            </kendo-chart-category-axis>   
            <kendo-chart-value-axis>
                <kendo-chart-value-axis-item  [majorGridLines]="{ visible : false}" [min]="0" [color]="'transparent'" >
                </kendo-chart-value-axis-item>
            </kendo-chart-value-axis>
            <kendo-chart-series>
              <kendo-chart-series-item type="bar" [data]="usability.robot.data"   [visual]="style.seriesVisual"
                                                  [labels]="{visible:true , visual : style.seriesVisual , position:'outsideEnd' , color:'#FFFFFF' , background:'transparent'}">
              </kendo-chart-series-item>      
            </kendo-chart-series>
          </kendo-chart>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item class="weekly" [col]="1" [row]="6" [colSpan] = "4" [rowSpan]="2" [title]="'Average Task Count per Weekday' | label: uiSrv.langPack" >
        <a class="mdi mdi-download export-chart"  [matTooltip] = "'Download as image' | label : uiSrv.langPack" (click) = "exportChart(avgTaskCountPerWeekdayChart , 'Average Task Count per Weekday')"></a>        
        <kendo-chart #avgTaskCountPerWeekdayChart  [seriesColors]="style.seriesColors"  (seriesClick) = "onSeriesClick($event)">  
                    <kendo-chart-area background="transparent"></kendo-chart-area>   
                    <kendo-chart-tooltip format="{0}"></kendo-chart-tooltip>
                    <!-- <kendo-chart-title  [align]="'left'" [text]="'Average Job Order per Weekday' | label: uiSrv.langPack" [color]="style.textColor" ></kendo-chart-title>                    -->
                    <kendo-chart-series>
                      <kendo-chart-series-item type="column" [data]="usability.weeklyAvg.data">
                      </kendo-chart-series-item>
                    </kendo-chart-series>
                    <kendo-chart-category-axis>
                        <kendo-chart-category-axis-item  [categories]="usability.weeklyAvg.categories" [color]="style.textColor" 
                                                         [majorGridLines]="{ visible : false}">
                        </kendo-chart-category-axis-item>         
                    </kendo-chart-category-axis>   
                    <kendo-chart-value-axis>
                        <kendo-chart-value-axis-item [color]="style.textColor">
                        </kendo-chart-value-axis-item>
                    </kendo-chart-value-axis>
                </kendo-chart>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item  class="hourly" [col]="5" [row]="6" [colSpan] = "4" [rowSpan]="2" [title]="'Average Task Count By Hour' | label: uiSrv.langPack">
        <a class="mdi mdi-download export-chart"  [matTooltip] = "'Download as image' | label : uiSrv.langPack" (click) = "exportChart(avgTaskCountByHourChart , 'Average Task Count By Hour')"></a>             
        <kendo-chart #avgTaskCountByHourChart [seriesColors]="style.seriesColors"  (seriesClick) = "onSeriesClick($event)">   
                    <kendo-chart-area background="transparent"></kendo-chart-area>  
                    <!-- <kendo-chart-title  [align]="'left'" [text]="'Average Job Order By Hour' | label: uiSrv.langPack" [color]="style.textColor" ></kendo-chart-title>                    -->
                    <kendo-chart-series>
                    <kendo-chart-tooltip>
                        <ng-template kendoChartSeriesTooltipTemplate let-category="category" let-value="value" let-series="series" let-idx = "categoryIndex"> 
                             <div> {{category | number : '2.2' | replace : '.' : ':'}} - {{usability.hourlyAvg.categories[idx == usability.hourlyAvg.categories.length - 1 ? 0 : idx + 1 ]  | number : '2.2' | replace : '.' : ':'}} ({{ value }}) </div>
                        </ng-template>                    
                    </kendo-chart-tooltip>  
                      <kendo-chart-series-item type="line" [data]="usability.hourlyAvg.data"  [markers]="{ visible: false }">
                      </kendo-chart-series-item>
                    </kendo-chart-series>
                    <kendo-chart-category-axis>
                        <kendo-chart-category-axis-item  [categories]="usability.hourlyAvg.categories"
                                                         [majorGridLines]="{ visible : false}" [color]="style.textColor" >
                        </kendo-chart-category-axis-item>         
                    </kendo-chart-category-axis>   
                    <kendo-chart-value-axis>
                        <kendo-chart-value-axis-item [color]="style.textColor">
                        </kendo-chart-value-axis-item>
                    </kendo-chart-value-axis>
                </kendo-chart>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item  class="type"  [col]="9" [row]="6" [colSpan] = "4"  [rowSpan]="2" [title]="'Task Count by Type' | label: uiSrv.langPack">
        <a class="mdi mdi-download export-chart"  [matTooltip] = "'Download as image' | label : uiSrv.langPack" (click) = "exportChart(usabilityByRobotTypeChart , 'Task Count by Type')"></a>              
        <kendo-chart #usabilityByRobotTypeChart name="robotType" [seriesColors] = "style.seriesColors"  (seriesClick) = "onSeriesClick($event)"   [transitions]="!robotType" >
                    <kendo-chart-area background="transparent"></kendo-chart-area>
                    <!-- <kendo-chart-title [align]="'left'" [text]="'Job Order by Type' | label: uiSrv.langPack"  [color]="style.textColor" ></kendo-chart-title>     -->
                    <ng-template kendoChartDonutCenterTemplate>
                        <div [style.color]="style.textColor">{{usability.robotType.centerText}}</div>
                    </ng-template>
                    <kendo-chart-legend [visible]="false" ></kendo-chart-legend>
                    <kendo-chart-series>
                      <kendo-chart-tooltip>
                            <ng-template kendoChartSeriesTooltipTemplate  let-value="value" let-category="category" let-series="series" >
                              {{ category | enum |  label: uiSrv.langPack}} : {{ value}}
                            </ng-template>
                      </kendo-chart-tooltip>
                      <kendo-chart-series-item type="donut" [data]="usability.robotType.data" field="value" categoryField="category" [holeSize] = "30" [padding] = "35"
                                               [visual]="style.seriesVisual" [labels]="{ visible: true, visual: style.labelVisual, position : 'outsideEnd' , distance:15 , color:style.textColor }"  
                      >
                      </kendo-chart-series-item>
                    </kendo-chart-series>
                  </kendo-chart>
    </kendo-tilelayout-item>
</kendo-tilelayout>


<!-- ^ Usability ^ -->


<!-- v Utilization v -->


<kendo-tilelayout *ngIf="_chartType == 'utilization' && utilization" kendoTooltip #tooltipTo="kendoTooltip" filter=".k-right-handle" showOn="none" [columns]="12" [resizable]="false" [reorderable]="false">
    <kendo-tilelayout-item class="tile select-year" [col]="1" [row]="1" [colSpan] = "4">
            <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>
        </kendo-tilelayout-item>
        <kendo-tilelayout-item class = "tile total-util fixed-header" [title]="'Average Online Time'| label: uiSrv.langPack" [col]="5" [row]="1" [colSpan] = "8 - (!utilization.total.type1.robotType ? 0 : (!utilization.total.type2.robotType ? 2 : (!utilization.total.type3.robotType ? 4 : 6 )))">
            <kendo-tilelayout-item-body class="pad-top-24">
                <div class="util">
                    <!-- <label class="ts-value">{{utilization.totalExecutingHours}}</label> -->
                    <!-- <label class="ts-total">/ {{usability.total}}</label> -->
                </div>
                <label class="ts-percent">{{(utilization.totalRobotHours - utilization.totalOfflineHours) * 100 / utilization.totalRobotHours | number: '1.0-2'}}%</label>
            </kendo-tilelayout-item-body>
        </kendo-tilelayout-item>
        <kendo-tilelayout-item *ngIf = "utilization.total.type1.robotType" class = "tile type-1-util fixed-header" [title] = "('Top'| label: uiSrv.langPack) + ' 1 ' + (' active '| label: uiSrv.langPack) + ' - ' + (utilization.total.type1.robotType | enum | label: uiSrv.langPack)" [col]="!utilization.total.type2.robotType ? 11 : (!utilization.total.type3.robotType ? 9 : 7 )" [row]="1" [colSpan] = "2">
            <kendo-tilelayout-item-body class="pad-top-24">
                <div class="util">
                    <!-- <label class="ts-value">{{utilization.total.type1.executing}}</label> -->
                    <!-- <label class="ts-total">/ {{utilization.total.type1.total}}</label> -->
                </div>
                <label class="ts-percent">{{(utilization.total.type1.total - utilization.total.type1.unknown)  * 100 / utilization.total.type1.total | number: '1.0-2'}}%</label>
            </kendo-tilelayout-item-body>
        </kendo-tilelayout-item>
        <kendo-tilelayout-item *ngIf = "utilization.total.type2.robotType" class = "tile type-2-util fixed-header" [title] = "('Top'| label: uiSrv.langPack) + ' 2 ' + (' active '| label: uiSrv.langPack) + ' - ' + (utilization.total.type2.robotType | enum | label: uiSrv.langPack) "  [col]="!utilization.total.type3.robotType ? 11 : 9" [row]="1" [colSpan] = "2" >
            <kendo-tilelayout-item-body class="pad-top-24">
                <div class="util">
                    <!-- <label class="ts-value">{{utilization.total.type2.executing}}</label> -->
                    <!-- <label class="ts-total">/ {{utilization.total.type2.total}}</label> -->
                </div>
                <label class="ts-percent">{{(utilization.total.type2.total - utilization.total.type2.unknown)  * 100 / utilization.total.type2.total | number: '1.0-2'}}%</label>
            </kendo-tilelayout-item-body>
        </kendo-tilelayout-item>
        <kendo-tilelayout-item *ngIf = "utilization.total.type3.robotType" class = "tile type-3-util fixed-header"  [title]="('Top'| label: uiSrv.langPack) + ' 3 ' + (' active '| label: uiSrv.langPack) + ' - ' + (utilization.total.type3.robotType | enum | label: uiSrv.langPack) "  [col]="11" [row]="1" [colSpan] = "2">
            <kendo-tilelayout-item-body class="pad-top-24">
                <div class="util">
                    <!-- <label class="ts-value">{{utilization.total.type3.executing}}</label> -->
                    <!-- <label class="ts-total">/ {{utilization.total.type3.total}}</label> -->
                </div>
                <label class="ts-percent">{{(utilization.total.type3.total - utilization.total.type3.unknown)  * 100 / utilization.total.type3.total | number: '1.0-2'}}%</label>
            </kendo-tilelayout-item-body>
        </kendo-tilelayout-item>   
        <kendo-tilelayout-item kendoTooltip #tooltipFr="kendoTooltip" filter=".k-left-handle" showOn="none" class = "tile daily" class="tile" [col]="1" [row]="2" [rowSpan]="4" [colSpan]="robotType ? 9 : 12" [title]="'Average Robot Utilization' | label: uiSrv.langPack">
            <a class="mdi mdi-download export-chart"  [matTooltip] = "'Download as image' | label : uiSrv.langPack" (click) = "exportChart(navigatorChart , 'Average Robot Utilization')"></a>  
            <kendo-chart #navigatorChart (mouseleave)="setHighlightedSeriesIndex(null)" (legendItemLeave)="setHighlightedSeriesIndex(null)" 
                                         (legendItemHover)="setHighlightedSeriesIndex(utilization.statusVisible[utilization.statuss[$event.series.index ]] ? $event.series.index : null)" 
                                         (legendItemClick)="onLegendClick($event)" (seriesClick) = "onSeriesClick($event)" (selectStart)="onSelectStart()" 
                                         (select)="onSelect($event)"  (selectEnd)="onSelectEnd($event)"  [seriesColors]="style.seriesColors" [transitions]="false" [categoryAxis]="[
                        {
                          color : style.textColor,
                          categories: utilization.daily.categories,
                          min: utilization.daily.min,
                          max: utilization.daily.max,
                          labels: { format:'d/M'},
                          baseUnitStep : 1,
                          maxDivisions: robotType ? 16 : 32
                        },
                        {
                          categories: utilization.daily.categories,
                          name: 'navigatorAxis',
                          labels: { step: utilization.daily.navigatorStep , format:'MMM' , skip: 15 , color:style.textColor},
                          majorGridLines: { visible: false},
                          pane: 'navigator',
                          select: { from: utilization.daily.min, to: utilization.daily.max }
                        }]"
                        [valueAxis]="[{color:style.textColor}, {name: 'valueNavigatorAxis', pane: 'navigator' , visible:false , majorGridLines :{visible:false } }]"
                        [panes]="[{color:style.textColor }, { name: 'navigator', height: 70 }]">
                <kendo-chart-area background="transparent"></kendo-chart-area>
                <kendo-chart-legend position="bottom" orientation="horizontal" [markers]="{type:'circle' , height : 10}" [labels] = "{color : '#FFFFFF'}">
                </kendo-chart-legend>
                <kendo-chart-tooltip>
                    <ng-template kendoChartSeriesTooltipTemplate let-category="category" let-value="value" let-series="series" let-idx = "categoryIndex"> 
                     <div> {{category | date : 'd MMM'}}  ({{ value }} {{'Hours' | label: uiSrv.langPack }}  {{series.name | label: uiSrv.langPack }}) </div>
                     <!-- {{category | date : 'd/M'}} - {{ series.name }} : - {{ series.name | label: uiSrv.langPack }}  -->
                    </ng-template>                    
                </kendo-chart-tooltip>                  
                <kendo-chart-series >
                  <ng-container *ngFor="let status of utilization.statuss; let i = index">
                    <kendo-chart-series-item type="line" [name] = "utilization.statusLabel[status] | label : uiSrv.langPack"  [data]="utilization.daily.data[status]" [markers]="{ visible: false }" [color]="style.seriesColors[i]"></kendo-chart-series-item>                
                  </ng-container>           
                  <ng-container *ngFor="let status of utilization.statuss; let i = index">
                    <kendo-chart-series-item type="area" [data]="utilization.daily.data[status]" [visible]="utilization.statusVisible[status]"  axis="valueNavigatorAxis" categoryAxis="navigatorAxis" [color]="style.seriesColors[i]"> </kendo-chart-series-item>      
                  </ng-container>                
                </kendo-chart-series>
            </kendo-chart>
        </kendo-tilelayout-item>
        <kendo-tilelayout-item *ngIf="robotType" class="tile-robot" [col]="10" [row]="2" [rowSpan]="4" [colSpan]="3" [title]="'Utilization Percentage By Robot' | label: uiSrv.langPack">
            <kendo-chart  #utilizationByRobotChart (seriesClick) = "onSeriesClick($event)" [seriesColors]="style.seriesColors" [transitions]="false">     
                <kendo-chart-area background="transparent"></kendo-chart-area>
                <kendo-chart-legend position="bottom" orientation="horizontal" [markers]="{type:'circle'}" [labels] = "{color : '#FFFFFF'}">
                </kendo-chart-legend>
                <kendo-chart-title  [align]="'left'" [color]="style.textColor"></kendo-chart-title>                   
                <kendo-chart-series>
                    <ng-container *ngFor="let status of utilization.statuss; let i = index">
                        <kendo-chart-series-item type="bar"  [stack]="{ group: '' }" [data]="utilization.robot.data[status]" [color]="style.seriesColors[i]"
                                                 [visible]="utilization.statusVisible[status]"   [visual]="style.seriesVisual">
                        </kendo-chart-series-item>
                        <!-- [labels]="{visible:true , visual : style.seriesVisual , position:'center' , color:'#FFFFFF' , background:'transparent' , content:utilization.robotType.labelContent}" -->
                    </ng-container>
                </kendo-chart-series>
                <kendo-chart-tooltip>
                    <ng-template kendoChartSeriesTooltipTemplate let-category="category" let-value="value" let-series="series" > 
                     <div>  {{ value }} {{'% ' }} {{utilization.statusLabel[utilization.statuss[series.index]] | label: uiSrv.langPack }} </div>
                     <!-- utilization.statusLabel[utilization.statuss[idx]] | label: uiSrv.langPack  -->
                    </ng-template>                    
                </kendo-chart-tooltip>  
                <kendo-chart-category-axis>
                    <kendo-chart-category-axis-item  [categories]="utilization.robot.categories" [color]="style.textColor"
                                                     [majorGridLines]="{ visible : false}" [labels]="{visual : style.labelVisual}" >
                    </kendo-chart-category-axis-item>         
                </kendo-chart-category-axis>           
                <kendo-chart-value-axis>
                    <kendo-chart-value-axis-item [labels]="{format:'{0}%'}"   [majorGridLines]="{ visible : false}" [min]="0" [max]="100" [color]="style.textColor">
                    </kendo-chart-value-axis-item>         
                </kendo-chart-value-axis>   
            </kendo-chart>
        </kendo-tilelayout-item>
        <kendo-tilelayout-item class="tile" [col]="1" [row]="6" [rowSpan]="2" [colSpan]="5" [title]="'Average Robot Utilization by Robot Type' | label: uiSrv.langPack">
            <a class="mdi mdi-download export-chart"  [matTooltip] = "'Download as image' | label : uiSrv.langPack" (click) = "exportChart(utilizationByRobotTypeChart , 'Average Robot Utilization by Robot Type')"></a>              
            <kendo-chart  #utilizationByRobotTypeChart (seriesClick) = "onSeriesClick($event)" [seriesColors]="style.seriesColors" [transitions]="false">     
                <kendo-chart-area background="transparent"></kendo-chart-area>
                <kendo-chart-legend position="bottom" orientation="horizontal" [markers]="{type:'circle'}" [labels] = "{color : '#FFFFFF'}">
                </kendo-chart-legend>
                <kendo-chart-title  [align]="'left'" [color]="style.textColor"></kendo-chart-title>                   
                <kendo-chart-series>
                    <ng-container *ngFor="let status of utilization.statuss; let i = index">
                        <kendo-chart-series-item type="bar"  [stack]="{ group: '' }" [data]="utilization.robotType.data[status]" [color]="style.seriesColors[i]"
                                                 [visible]="utilization.statusVisible[status]"   [visual]="style.seriesVisual">
                        </kendo-chart-series-item>
                        <!-- [labels]="{visible:true , visual : style.seriesVisual , position:'center' , color:'#FFFFFF' , background:'transparent' , content:utilization.robotType.labelContent}" -->
                    </ng-container>
                </kendo-chart-series>
                <kendo-chart-tooltip>
                    <ng-template kendoChartSeriesTooltipTemplate let-category="category" let-value="value" let-series="series" > 
                     <div>  {{ value }} {{'% ' }} {{utilization.statusLabel[utilization.statuss[series.index]] | label: uiSrv.langPack }} </div>
                     <!-- utilization.statusLabel[utilization.statuss[idx]] | label: uiSrv.langPack  -->
                    </ng-template>                    
                </kendo-chart-tooltip>  
                <kendo-chart-category-axis>
                    <kendo-chart-category-axis-item  [categories]="utilization.robotType.categories" [color]="style.textColor"
                                                     [majorGridLines]="{ visible : false}" [labels]="{visual : style.labelVisual}" >
                    </kendo-chart-category-axis-item>         
                </kendo-chart-category-axis>           
                <kendo-chart-value-axis>
                    <kendo-chart-value-axis-item [labels]="{format:'{0}%'}"   [majorGridLines]="{ visible : false}" [min]="0" [max]="100" [color]="style.textColor">
                    </kendo-chart-value-axis-item>         
                </kendo-chart-value-axis>   
            </kendo-chart>
        </kendo-tilelayout-item>
        <kendo-tilelayout-item class="tile" [col]="6" [row]="6" [rowSpan]="2" [colSpan]="7" [title]="'Average Robot Utilization Percentage By Hour' | label: uiSrv.langPack">
            <a class="mdi mdi-download export-chart"  [matTooltip] = "'Download as image' | label : uiSrv.langPack" (click) = "exportChart(utilizationByHourChart , 'Average Robot Utilization Percentage By Hour')"></a>
            <kendo-chart #utilizationByHourChart [seriesColors]="style.seriesColors"  (seriesClick) = "onSeriesClick($event)" [transitions]="false">   
                <kendo-chart-area background="transparent"></kendo-chart-area>  
                <kendo-chart-tooltip>
                    <ng-template kendoChartSeriesTooltipTemplate let-category="category" let-value="value" let-series="series" let-idx = "categoryIndex"> 
                     <div> {{category | number : '2.2' | replace : '.' : ':'}} - {{utilization.hourly.categories[idx == utilization.hourly.categories.length - 1 ? 0 : idx + 1 ]  | number : '2.2' | replace : '.' : ':'}}  ({{ value * 60 / 100 | roundDown }} {{'minutes' | label : uiSrv.langPack }}  {{utilization.statusLabel[utilization.statuss[series.index]] | label: uiSrv.langPack }}) </div>
                    </ng-template>                    
                </kendo-chart-tooltip>                     
                <kendo-chart-series>
                    <ng-container *ngFor="let status of utilization.statuss; let i = index">
                        <kendo-chart-series-item type="line" [visible]="utilization.statusVisible[status]" [data]="utilization.hourly.data[status]" [markers]="{ visible: false }" [color]="style.seriesColors[i]"  [visual]="style.seriesVisual"></kendo-chart-series-item>
                    </ng-container>                
                </kendo-chart-series>
                <kendo-chart-category-axis>
                    <kendo-chart-category-axis-item  [categories]="utilization.hourly.categories"
                                                     [majorGridLines]="{ visible : false}" [color]="style.textColor" >
                    </kendo-chart-category-axis-item>         
                </kendo-chart-category-axis>   
                <kendo-chart-value-axis>
                    <kendo-chart-value-axis-item [color]="style.textColor">
                    </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
            </kendo-chart>
            <!-- <kendo-grid class="robot-hour" [data]="utilization.robot" >
                <kendo-grid-column [headerStyle]="{'width': '20%'}" [style]="{'width': '20%'}" field="category" [title]="'Robot' | label: uiSrv.langPack"> </kendo-grid-column>
                <kendo-grid-column field="executing" [title]="'Working' | label: uiSrv.langPack"> </kendo-grid-column>
                <kendo-grid-column field="idle"  [title]="'Idle' | label: uiSrv.langPack"></kendo-grid-column>
                <kendo-grid-column field="charging"  [title]="'Charging' | label: uiSrv.langPack"></kendo-grid-column>
                <kendo-grid-column field="hold"  [title]="'Reserved' | label: uiSrv.langPack"></kendo-grid-column>
                <kendo-grid-column field="unknown"  [title]="'Offline' | label: uiSrv.langPack"></kendo-grid-column>
                <kendo-grid-column field="utilPercent"  [title]="'Utilization' | label: uiSrv.langPack"></kendo-grid-column>
            </kendo-grid> -->
        </kendo-tilelayout-item>
</kendo-tilelayout>






<kendo-tilelayout *ngIf="_chartType == 'eventAnalysis' && event"  kendoTooltip #tooltipTo="kendoTooltip" filter=".k-right-handle" showOn="none"  class="chart" [columns]="12"  [resizable]="false" [reorderable]="false">
    <kendo-tilelayout-item class = "tile select-year" [col]="1" [row]="1"  [colSpan] = "4">
        <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item class="fixed-header" [title]="((event.eventType.selected ? (event.eventType.selected | dropdownDesc : dropdownOptions.robotEventTypes) + ' Count - ' : 'Event Count - ') | label: uiSrv.langPack) + (event.floorplan.selected ? (event.floorplan.selected | dropdownDesc : dropdownOptions.floorplans) : '') "  [col]="5" [row]="1"   [colSpan] = "8 - (!event.total.eventType1 ? 0 : (!event.total.eventType2 ? 2 : (!event.total.eventType3 ? 4 : 6 )))">
        <kendo-tilelayout-item-body class="pad-top-24">
            <div class="event-count-wrapper">
                <label class="event-count">{{event.total.count}}</label>
            </div>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item  *ngIf = "event.total.eventType1" class = "tile eventType1 fixed-header" [title] = "('Top'| label: uiSrv.langPack) + ' 1 ' + ('Event Count'| label: uiSrv.langPack) + ' - ' + (event.total.eventType1.name  | label: uiSrv.langPack)" [col]="!event.total.eventType2 ? 11 : (!event.total.eventType3 ? 9 : 7 )" [row]="1" [colSpan] = "2">
        <kendo-tilelayout-item-body class="pad-top-24">
            <div class="event-count-wrapper">
                <label class="event-count ">{{event.total.eventType1.count}}</label>
            </div>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item *ngIf = "event.total.eventType2" class = "tile eventType2 fixed-header" [title] = "('Top'| label: uiSrv.langPack) + ' 2 ' + ('Event Count'| label: uiSrv.langPack) + ' - ' + (event.total.eventType2.name  | label: uiSrv.langPack) "  [col]="!event.total.eventType3 ? 11 : 9" [row]="1" [colSpan] = "2" >
        <kendo-tilelayout-item-body class="pad-top-24">
            <div class="event-count-wrapper">
                <label class="event-count ">{{event.total.eventType2.count}}</label>
            </div>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item   *ngIf = "event.total.eventType3" class = "tile eventType3 fixed-header"  [title]="('Top'| label: uiSrv.langPack) + ' 3 ' + ('Event Count'| label: uiSrv.langPack) + ' - ' + (event.total.eventType3.name  | label: uiSrv.langPack) "  [col]="11" [row]="1" [colSpan] = "2">
        <kendo-tilelayout-item-body class="pad-top-24">
            <div class="event-count-wrapper">
                <label class="event-count ">{{event.total.eventType3.count}}</label>
            </div>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>   

    <kendo-tilelayout-item  *ngIf="event.floorplan.selected" [col]="1" [row]="2" [rowSpan]="6" [colSpan] = "7" class="no-blur">
        <div  class = "viewport-container" (wheel)="$event.preventDefault()">
            <uc-drawing-board  #pixi [readonly] = "true"  [hideButton] = "{all:true , fullScreen : true , relocation : true , export : true}" [showToggles] = "{darkMode : true}">
            </uc-drawing-board>
        </div>

        <!-- <div class="floorplan">            
            <img class="background" [src]="event.background.path">
        </div>
        <kendo-chart  class="waypoint-scatter-chart" #obstacleWaypointScatter name="obstacleWaypointHour" [seriesColors] = "style.seriesColors" [resizeRateLimit]="0"
                      [style.width]="event.background.width + 'px'" [style.height]="event.background.height + 'px'"  
                      (seriesClick) = " $event.category ? selectWaypoint($event) : null ;  ">
            <ng-template kendoChartDonutCenterTemplate>
                <div [style.color]="style.textColor">{{usability.robotType.centerText}}</div>
            </ng-template>
            <kendo-chart-area background="transparent"></kendo-chart-area>  
            <kendo-chart-legend [visible]="false" ></kendo-chart-legend>
            <kendo-chart-series>
            <kendo-chart-x-axis>
                <kendo-chart-x-axis-item [visible] = "false" [color]="style.textColor" [majorGridLines]="{ visible : false}" [majorTicks] = "{step : 100}" 
                                         [min] = "event.waypoint.minX" 
                                         [max] = "event.waypoint.maxX">
                </kendo-chart-x-axis-item>
            </kendo-chart-x-axis>
            <kendo-chart-y-axis>
                <kendo-chart-y-axis-item  [visible] = "false" [color]="style.textColor" [majorGridLines]="{ visible : false}" [majorTicks] = "{step : 100}" 
                                          [min] = "event.waypoint.minY" 
                                          [max] = "event.waypoint.maxY" >
                </kendo-chart-y-axis-item>
            </kendo-chart-y-axis>
                 <kendo-chart-series-item
                    type="scatter"
                    [data]="event.obstacle.data"
                    [color] = "style.seriesColors[2]"
                    xField="x"
                    yField="y"
                    [zIndex] = "5"
                    [markers]="{type : 'cross' , size : 5}"
                >
                <kendo-chart-tooltip>
                    <ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem" > 
                     <div>{{dataItem['date'] | date : 'dd-MMM-yyyy'}} {{(dataItem['hour'] > 9 ? '' :  '0') + dataItem['hour']  }}:{{(dataItem['minute'] > 9 ? '' :  '0') + dataItem['minute']}} </div>
                    </ng-template>                    
                </kendo-chart-tooltip> 
                </kendo-chart-series-item>
            <kendo-chart-series-item  [color] = "style.seriesColors[0]"
                 type="bubble"
                 [negativeValues] = "{color:style.seriesColors[1] , visible :  true}"
                 [data]="event.waypoint.data"
                 xField="x"
                 yField="y"
                 sizeField="count"
                 [labels]="{visible:true , content : event.waypoint.labelContent , color:style.textColor ,  opactiy : 1}" 
             >       
             </kendo-chart-series-item>
            </kendo-chart-series>
          </kendo-chart> -->
    </kendo-tilelayout-item>

    
    <kendo-tilelayout-item  [title]="'Daily Event Count'| label: uiSrv.langPack" kendoTooltip #tooltipFr="kendoTooltip" filter=".k-left-handle" showOn="none" class = "tile daily" class="tile" [col]="8" [row]="2" [rowSpan]="2" [colSpan] = "5">
        <a class="mdi mdi-download export-chart"  [matTooltip] = "'Download as image' | label : uiSrv.langPack" (click) = "exportChart(navigatorChart , 'Daily Event Count')"></a>     
        <kendo-chart  #navigatorChart [seriesColors]="style.seriesColors"   (selectStart)="onSelectStart()" (select)="onSelect($event)" (selectEnd)="onSelectEnd($event);"   >   
            <kendo-chart-area background="transparent"></kendo-chart-area>  
            <!-- <kendo-chart-title  [align]="'left'" [text]="'Average Job Order By Hour' | label: uiSrv.langPack" [color]="style.textColor" ></kendo-chart-title>                    -->
            <kendo-chart-series>
              <kendo-chart-series-item type="area" [data]="event.daily.data"  [markers]="{ visible: false }">
              </kendo-chart-series-item>
            </kendo-chart-series>
            <kendo-chart-category-axis>
                <kendo-chart-category-axis-item  [categories]="event.daily.categories"  [color]="style.textColor" [majorTicks] = "{visible : false}"
                                                 [labels] = "{ step: event.daily.navigatorStep , format:'MMM' , skip: 15 , color:style.textColor}"
                                                 [majorGridLines]="{ visible : false}"  [select]=" { from: event.daily.min, to: event.daily.max }" >
                </kendo-chart-category-axis-item>         
            </kendo-chart-category-axis>   
            <kendo-chart-value-axis>
                <kendo-chart-value-axis-item [color]="style.textColor">
                </kendo-chart-value-axis-item>
            </kendo-chart-value-axis>
        </kendo-chart>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item [title]="'Event Count By Type'| label: uiSrv.langPack"   [col]="8" [row]="4" [rowSpan]="2" [colSpan] = "3 + (event.floorplan.selected ? 0 : 2)">
        <a class="mdi mdi-download export-chart"  [matTooltip] = "'Download as image' | label : uiSrv.langPack" (click) = "exportChart(eventByTypeChart , 'Event Count By Type')"></a>     
        <kendo-chart #eventByTypeChart name="eventType" [seriesColors] = "style.seriesColors"  (seriesClick) = "onSeriesClick($event)"  >
            <kendo-chart-area background="transparent"></kendo-chart-area>
            <!-- <kendo-chart-title [align]="'left'" [text]="'Job Order by Type' | label: uiSrv.langPack"  [color]="style.textColor" ></kendo-chart-title>     -->
            <ng-template kendoChartDonutCenterTemplate>
                <div [style.color]="style.textColor">{{event.floorplan.centerText}}</div>
            </ng-template>
            <kendo-chart-legend [visible]="false" ></kendo-chart-legend>
            <kendo-chart-series>
              <kendo-chart-tooltip>
                    <ng-template kendoChartSeriesTooltipTemplate  let-value="value" let-category="category" let-series="series" >
                      {{ category | dropdownDesc : dropdownOptions.robotEventTypes |  label: uiSrv.langPack}} : {{ value }}
                    </ng-template>
              </kendo-chart-tooltip>
              <kendo-chart-series-item type="donut" [data]="event.eventType.data" field="value" categoryField="category" [holeSize] = "30" [padding] = "35"
                                        [visual]="style.seriesVisual" [labels]="{visible: true, margin : 0 , padding : 0 , visual: style.labelVisual, position : 'outsideEnd' , distance:15 , color:style.textColor }"  
              >                         
              </kendo-chart-series-item>
            </kendo-chart-series>
        </kendo-chart>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item [title]="'Event Count By Floor Plan'| label: uiSrv.langPack"   [col]="event.floorplan.selected ? 11 : 1" [row]="event.floorplan.selected ? 4 : 2" [rowSpan]="event.floorplan.selected ? 2 : 6 " [colSpan] = "event.floorplan.selected ? 2 : 7 ">
        <a class="mdi mdi-download export-chart"  [matTooltip] = "'Download as image' | label : uiSrv.langPack" (click) = "exportChart(eventByFloorplanChart , 'Event Count By Floor Plan')"></a>     
        <kendo-chart #eventByFloorplanChart name="floorplan" [seriesColors] = "style.seriesColors"  (seriesClick) = "onSeriesClick($event)"  >
            <kendo-chart-area background="transparent"></kendo-chart-area>
            <!-- <kendo-chart-title [align]="'left'" [text]="'Job Order by Type' | label: uiSrv.langPack"  [color]="style.textColor" ></kendo-chart-title>     -->
            <ng-template kendoChartDonutCenterTemplate>
                <div [style.color]="style.textColor">{{event.floorplan.centerText}}</div>
            </ng-template>
            <kendo-chart-legend [visible]="false" ></kendo-chart-legend>
            <kendo-chart-series>
              <kendo-chart-tooltip>
                    <ng-template kendoChartSeriesTooltipTemplate  let-value="value" let-category="category" let-series="series" >
                      {{ category |  label: uiSrv.langPack}} : {{ value }}
                    </ng-template>
              </kendo-chart-tooltip>
              <kendo-chart-series-item type="donut" [data]="event.floorplan.data" field="value" categoryField="category" [holeSize] = "event.floorplan.selected ? 30 : 0" [padding] = "35"
                                       [visual]="style.seriesVisual" [labels]="{ visible: true, visual: style.labelVisual, position : 'outsideEnd' , distance:15 , color:style.textColor }"  
              >
              </kendo-chart-series-item>
            </kendo-chart-series>
        </kendo-chart>
        
        <!-- <kendo-chart #floorplanEvent [seriesColors]="style.seriesColors"  (seriesClick) = "onSeriesClick($event)">  
            <kendo-chart-area background="transparent"></kendo-chart-area>   
            <kendo-chart-tooltip format="{0}"></kendo-chart-tooltip>      
            <kendo-chart-series>
              <kendo-chart-series-item type="column" [data]="event.floorplan.data" [visual]="style.seriesVisual">
              </kendo-chart-series-item>
            </kendo-chart-series>
            <kendo-chart-category-axis>
                <kendo-chart-category-axis-item  [categories]="event.floorplan.categories" [color]="style.textColor" 
                                                 [majorGridLines]="{ visible : false}">
                </kendo-chart-category-axis-item>         
            </kendo-chart-category-axis>   
            <kendo-chart-value-axis>
                <kendo-chart-value-axis-item [color]="style.textColor">
                </kendo-chart-value-axis-item>
            </kendo-chart-value-axis>
        </kendo-chart>                 -->
    </kendo-tilelayout-item>

    <kendo-tilelayout-item [title]="'Event Count By Hour'| label: uiSrv.langPack"  [col]="8" [row]="6" [rowSpan]="2" [colSpan] = "5">
        <a class="mdi mdi-download export-chart"  [matTooltip] = "'Download as image' | label : uiSrv.langPack" (click) = "exportChart(eventCountByHour , 'Event Count By Hour')"></a>     
        <kendo-chart #eventCountByHour [seriesColors]="style.seriesColors"  (seriesClick) = "onSeriesClick($event)">   
            <kendo-chart-area background="transparent"></kendo-chart-area>                    
            <kendo-chart-series>
            <kendo-chart-tooltip>
  
            </kendo-chart-tooltip>  
              <kendo-chart-series-item type="line" [data]="event.hourly.data"  [markers]="{ visible: false }">
              </kendo-chart-series-item>
            </kendo-chart-series>
            <kendo-chart-category-axis>
                <kendo-chart-category-axis-item  [categories]="event.hourly.categories"
                                                 [majorGridLines]="{ visible : false}" [color]="style.textColor" >
                </kendo-chart-category-axis-item>         
            </kendo-chart-category-axis>   
            <kendo-chart-value-axis>
                <kendo-chart-value-axis-item [color]="style.textColor">
                </kendo-chart-value-axis-item>
            </kendo-chart-value-axis>
        </kendo-chart>
    </kendo-tilelayout-item>

</kendo-tilelayout>