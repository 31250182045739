<div [class] = "'dialog-content map-detail '  + (!parentRow && uiSrv.isTablet ? 'scan' : '')">
    <kendo-dialog-titlebar [style.display]="uiSrv.isTablet?'none':''" (close) = "onClose()">
        <div>{{'Map' | label: uiSrv.langPack}}</div>
    </kendo-dialog-titlebar>
    <div class="container">
        <div *ngIf="!uiSrv.isTablet" class="form-container">
            <ng-container *ngTemplateOutlet="form"></ng-container>
        </div>
        <div class="viewport-container">
            <uc-drawing-board #pixi
                [overlayMsg]="!isCreate || startedScanning? null : ( 'Start Scanning' | label : uiSrv.langPack) " 
                [remoteControl] = "isCreate" 
                [canUploadMultiImg]="false" [uploadMustMatchOriginalSize]="true"
                [hideButton]="readonly ? {all : true} : { arrow: true, upload: true, point: true , manual : true , relocation : true}"
                (fileLoad)=" frmGrp.controls['fileName'].setValue($event?.name)"
                [showScanButton]="isCreate && !startedScanning" (scanClicked)="scanMapClicked()"
                (imageUploaded) = "imageUploaded($event)">
            </uc-drawing-board>
        </div>
    </div>
    <div *ngIf="uiSrv.isTablet && !isCreate" class="form-container bottom">
        <ng-container *ngTemplateOutlet="form"></ng-container>
    </div>
    <div class="button-container">
        <button kendoButton (click) = "onClose()" > {{'Cancel' | label : uiSrv.langPack}}</button>
        <button *ngIf="!readonly" kendoButton (click) = "saveToDB()" [disabled] = "!occupancyGridReceived && !frmGrp.controls['fileName'].value"> {{'Save' | label : uiSrv.langPack}}</button>
    </div>
</div>

<kendo-dialog *ngIf="showTabletSaveDialog" [title]="'Save New Map' | label : uiSrv.langPack"
               (close)="showTabletSaveDialog = false" [minWidth]="250" [width]="450">
            <ng-container *ngTemplateOutlet="form"></ng-container>
            <div class="button-container">
                <button kendoButton (click) = "saveToDB()" > {{'Save' | label : uiSrv.langPack}}</button>
            </div>
</kendo-dialog>


<ng-template #form>
        <form [formGroup]="frmGrp" class="content">
            <uc-txtbox lab = "Code" [frmGrp] = "frmGrp" frmCtrl = "mapCode" [disabled]="parentRow" [upper] = "true" ></uc-txtbox>
            <uc-txtbox *ngIf="util.arcsApp" lab = "Robot Base" [frmGrp] = "frmGrp" frmCtrl = "robotBase" [disabled]="parentRow" [upper] = "true"></uc-txtbox>
            <uc-txtbox lab = "Map Name" [frmGrp] = "frmGrp" frmCtrl = "name"></uc-txtbox>
            <uc-txtbox *ngIf = "parentRow" 
                        lab = "Map Image File Name" [frmGrp] = "frmGrp" frmCtrl = "fileName"  
                        [suffixButton] = "{icon : 'k-icon mdi mdi-upload'}" 
                        (suffixButtonClick) = "pixiElRef? pixiElRef.uploader.nativeElement.click() : null">
            </uc-txtbox>
            
            <div *ngIf="!parentRow"  class="form-row col default-checkbox-create-floorplan ">
                <input type="checkbox" kendoCheckBox [ngModelOptions]="{standalone: true}"   [(ngModel)] = "createFloorPlan" (ngModelChange) = "!createFloorPlan ? frmGrp.controls['floorPlanCode'].setValue(null) : null"/>                    
                <span >{{ 'Create Floor Plan' | label: uiSrv.langPack}}</span>
            </div>              
            <uc-txtbox *ngIf="!parentRow && createFloorPlan" lab = "Floor Plan Code" [frmGrp] = "frmGrp" frmCtrl = "floorPlanCode" [upper] = "true" ></uc-txtbox>
        </form>    
</ng-template>


<kendo-dialog *ngIf="scanOption.show" [title]="('Start Scanning' | label : uiSrv.langPack)"
               (close)="scanOption.show = false" [minWidth]="250" >

    <input #immediate class="radio-button extend" type="radio" [value]="true"
            [(ngModel)]="scanOption.extend" kendoRadioButton />
    <label class="radio-label extend" [for]="immediate">{{'Extend From Current Map : ' | label: uiSrv.langPack}} {{scanOption.activeMapCode}} </label>

    <input #smooth class="radio-button new" type="radio" [value]="false"
            [(ngModel)]="scanOption.extend" kendoRadioButton />
    <label class="radio-label new" [for]="smooth">{{'Scan a new map' | label: uiSrv.langPack}}</label>

    <kendo-dialog-actions>
        <button kendoButton  (click)="startScan(scanOption.extend)">
            {{ 'OK' | label : uiSrv.langPack }}
        </button>
        <button kendoButton (click)="scanOption.show = false">{{ 'Cancel' | label : uiSrv.langPack }}</button>
    </kendo-dialog-actions>
</kendo-dialog>
