
    <div class="form-container">
        <form [formGroup]="frmGrp" class="content">
            <div class="form-row">
                <uc-txtbox lab="Minimum length" [frmGrp]="frmGrp" frmCtrl="minLength" [numeric]="true" [numDecimals]="0" [numMax]="99" [numMin]="0" numFormat = 'n0' ></uc-txtbox>
            </div>
            <div class="form-row">
                <uc-txtbox lab="Minimum count of upper case letter" [frmGrp]="frmGrp" frmCtrl="minUpperCase" [numeric]="true" [numDecimals]="0" [numMax]="99" [numMin]="0" numFormat = 'n0'></uc-txtbox>
                <uc-txtbox lab="Minimum count of lower case letter" [frmGrp]="frmGrp" frmCtrl="minLowerCase" [numeric]="true" [numDecimals]="0" [numMax]="99" [numMin]="0" numFormat = 'n0'></uc-txtbox>
            </div>
            <div class="form-row">
                <uc-txtbox lab="Minimum count of digits" [frmGrp]="frmGrp" frmCtrl="minDigit" [numeric]="true" [numDecimals]="0" [numMax]="99" [numMin]="0" numFormat = 'n0'></uc-txtbox>
                <uc-txtbox lab="Minimum count of symbol" [frmGrp]="frmGrp" frmCtrl="minSymbol" [numeric]="true" [numDecimals]="0" [numMax]="99" [numMin]="0" numFormat = 'n0'></uc-txtbox>
            </div>
            <div class="form-row">
                <uc-txtbox lab="Retrict the resue of recent passwords (times)" [frmGrp]="frmGrp" frmCtrl="reusableAfter" [numeric]="true" [numDecimals]="0" [numMax]="99" [numMin]="0" numFormat = 'n0'></uc-txtbox>
                <uc-txtbox lab="Expire after (days)" [frmGrp]="frmGrp" frmCtrl="expireAfterDays" [numeric]="true" [numDecimals]="0" [numMax]="1000000" [numMin]="7" numFormat = 'n0'></uc-txtbox>
                <uc-txtbox lab="Notify user password expiring in (days)" [frmGrp]="frmGrp" frmCtrl="notifyExpiryBeforeDays" [numeric]="true" [numDecimals]="0" [numMax]="frmGrp.controls['expireAfterDays'].value" [numMin]="1" numFormat = 'n0'></uc-txtbox>
            </div>
        </form>
        
        <div class="button-container" *ngIf="!readonly">
            <button kendoButton (click)="saveToDB()" >{{'Save' | label : uiSrv.langPack}}</button>
        </div>
    </div>
