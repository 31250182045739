<div class="dialog-content forget-password">
    <form *ngIf="!successMsg" [formGroup]="frmGrp" class="content" (keydown.enter)="confirm()">
        <uc-txtbox lab = "Username" [frmGrp] = "frmGrp" frmCtrl = "userCode"></uc-txtbox>
        <uc-txtbox lab = "Email" [frmGrp] = "frmGrp" frmCtrl = "email"></uc-txtbox>
        <span class="error">{{errMsg | label : uiSrv.langPack}}</span>
    </form>
    

    <div class="button-container">
        <button  *ngIf="!successMsg" class="confirm-button" kendoButton (click)="confirm()">{{'Confirm' | label : uiSrv.langPack}}</button>
        <ng-container class="result" *ngIf="successMsg">
            <span class="msg">{{successMsg | label : uiSrv.langPack}}</span>
            <button class="confirm-button" kendoButton (click)="parent.showForgetPasswordDialog = false">{{'OK' | label : uiSrv.langPack}}</button>
        </ng-container>    
    </div>
</div>


