export function ConvertColorToDecimal(color: string | number) {
    if((<any>color) instanceof String || typeof color === 'string'){
        return Number(color.toString().replace("#", '0x'))
    }else{
        return Number(color)
    }
}

export function ConvertColorToHexadecimal(color: number | string) {
    if(color.toString().startsWith('#') && color.toString()?.length == 7){
        return color.toString()
    }else{
        return "#" + color.toString(16).padStart(6, '0')
    }
}

export const IOT_ICON_MAP = {
    LIFT: 'mdi mdi-elevator-passenger-outline',
    DOOR: 'mdi mdi-door',
    CAMERA: 'mdi mdi-cctv',
    LIGHT_CURTAIN: 'mdi mdi-motion-sensor',
    IEQ: 'mdi mdi-thermometer-water'
}