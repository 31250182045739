<div class="dialog-content robot-3d">
    <kendo-dialog-titlebar [style.display]="uiSrv.isTablet?'none':''" (close) = "onClose()">
        <div>{{'3D Robot' | label: uiSrv.langPack}}</div>
    </kendo-dialog-titlebar>

    <input hidden type="file" #uploader (change)="onfileLoad($event)" accept=".glb" />

    <div class="container"  #container>      
        <ng-container *ngIf="!uiSrv.isTablet">
            <div class="form-container">
                <form [formGroup]="frmGrp" class="content">
                    <uc-txtbox lab="Robot Code" [frmGrp]="frmGrp" frmCtrl="robotCode" [upper] = "true" [disabled] = "true"></uc-txtbox>
                    <uc-txtbox lab="Robot Name" [disabled] = "true" [value]="parentRow.name"></uc-txtbox>
                    <uc-txtbox lab="3D Model File" [frmGrp]="frmGrp" frmCtrl="fileName" 
                                [suffixButton]="{icon : 'k-icon mdi mdi-upload'}"
                                (suffixButtonClick)="onUploadClicked()">
                    </uc-txtbox>
                    

                </form>
            </div>
        </ng-container>

        <div  class="three-d-container"> 
            <div class="box-dimension" *ngIf="threeJsElRef?.transformCtrl?.object?.parent?.parent?.boxHelper?.object">
                 {{robotDimension.x | number: '1.0-2'}}m (W) x {{robotDimension.z  | number: '1.0-2'}}m (D) x {{robotDimension.y | number: '1.0-2'}}m (H)
            </div>
            <uc-3d-viewport #threeJs [uiToggles] = "{transformControl : true}" (objClicked) = "selectObject3D($event)" (removed) = "Object3DRemoved($event)" >
            </uc-3d-viewport>
        </div>        

    </div>

    <div class="button-container">
        <button kendoButton (click) = "onClose()"> {{'Cancel' | label : uiSrv.langPack}}</button>
        <!-- <button *ngIf="!readonly" kendoButton (click) = "saveToDB()"> {{'Save' | label : uiSrv.langPack}}</button> -->
    </div>
</div>




